<template>
  <div
    ref="timeline"
    class="player-progress-timeline vod-timeline"
    :class="[{ disabled: isDisabled }, $store.theme]"
  >
    <div
      class="played"
      data-cy="vod-timeline-played"
      :style="{ width: playedWidth ? playedWidth[1] : '' }"
    />
    <div
      class="transparent-overlay"
      @mouseenter="timelineHovered = true"
      @mouseleave="(timelineHovered = false), (timelineHoverX = null)"
      @mousemove="hoverOnTimeline"
      @click="clickOnTimeline"
    />

    <div class="current-time-control" :style="{ left: playedWidth ? playedWidth[0] : '' }" />

    <TimelineBalloon
      v-if="timelineHovered"
      ref="timelineBallon"
      :balloon-position="balloonPosition"
      :balloon-time="balloonTime"
    />
  </div>
</template>

<script lang="ts">
import TimelineBalloon from 'src/components/player/parts/common/TimelineBalloon.vue';
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { DateTime } from 'src/utils/time/date-time';
import { boundMinMax } from 'src/utils/number';
import { SequoiaComponent } from 'src/mixins';
import { videoDataSelector } from 'src/store/vod/selectors';

@Component({
  components: { TimelineBalloon },
})
export default class PlayerProgressTimelineVod extends SequoiaComponent {
  timelineHovered = false;
  timelineHoverX: number | null = null;
  balloonWidthPx = 70;
  balloonTime = '';
  isMounted = false;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Ref('timeline')
  readonly timelineRef?: HTMLDivElement;

  @Ref('timelineBallon')
  readonly refTimelineBallon?: Vue;

  get currentTime() {
    return selectors.vod.playingCurrentTimeSelector(this.$store);
  }

  get duration() {
    return selectors.vod.durationSelector(this.$store);
  }

  get playedWidth() {
    if (!this.isMounted) {
      return 0;
    }

    const { currentTime, duration } = this;

    if (!currentTime || !duration || currentTime > duration || duration === 1) {
      return 0;
    }

    if (this.timelineRef) {
      return [
        ((currentTime / duration) * this.timelineRef.clientWidth - 15).toString(10) + 'px', // current time control position
        ((currentTime / duration) * this.timelineRef.clientWidth).toString(10) + 'px', // played block width
      ];
    }
  }

  get balloonPosition() {
    if (!this.timelineHovered || !this.timelineRef) {
      return 0;
    }
    const balloonWidthPercent = this.balloonWidthPx / this.timelineRef.clientWidth;
    const rightBound = 1 - balloonWidthPercent;
    const widthHalf = balloonWidthPercent / 2;
    const balloonCenterPosition = this.timelineHoverX || 0;
    return boundMinMax(0, balloonCenterPosition - widthHalf, rightBound);
  }

  mounted() {
    this.isMounted = true;
  }

  clickOnTimeline(event: { layerX: number }) {
    if (!this.timelineRef) {
      return;
    }
    const newCurrentTime = (event.layerX / this.timelineRef.clientWidth) * this.duration;
    if (!isNaN(newCurrentTime)) {
      actions.vod.setIsSavedPause(this.$store, false);
      actions.player.setHasVideoEnded(this.$store, false);
      this.$emit('setCurrentTime', newCurrentTime);
    }

    this.gaEvent({
      category: 'player_controls',
      action: 'Клик по таймлайну (навигация в DVR)',
      control_type: 'mouse',
      vod_name: videoDataSelector(this.$store).sourceId,
      title_id: videoDataSelector(this.$store).titleId,
    });
  }

  hoverOnTimeline(event: { offsetX: number; target: { offsetWidth: number } }) {
    this.timelineHoverX = event.offsetX / event.target.offsetWidth;
    this.balloonTime = DateTime.prettyTimeHMS(this.timelineHoverX * this.duration);
  }
}
</script>

<style lang="scss">
@import 'src/components/player/parts/player-timeline';
</style>
