var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"program-info scroll-wrap"},[_c('div',{domProps:{"textContent":_vm._s(_vm.startEndText)}}),(
      _vm.selectedProgram &&
      (_vm.selectedProgram.title || (_vm.selectedProgram.metaInfo && _vm.selectedProgram.metaInfo.title))
    )?_c('h5',{domProps:{"textContent":_vm._s(_vm.selectedProgram.title || _vm.selectedProgram.metaInfo.title)}}):_vm._e(),_c('div',{staticClass:"thumbnail button-with-hover-overlay",class:{
      'pointer-events-none':
        _vm.isDvrDisabledForChannel(_vm.epgChannel) ||
        _vm.isChannelLocked(_vm.epgChannel) ||
        _vm.selectedProgram.startMs > new Date().getTime(),
    },on:{"click":function($event){return _vm.switchProgram(_vm.selectedProgram)}}},[_c('img',{attrs:{"src":_vm.getProgramThumbnailUrl(_vm.selectedProgram),"alt":_vm.selectedProgram.title}}),(_vm.selectedProgram.age)?_c('div',{staticClass:"age body2"},[_c('span',{domProps:{"textContent":_vm._s(_vm.selectedProgram.age)}}),_vm._v("+")]):_vm._e(),(
        !_vm.isDvrDisabledForChannel(_vm.epgChannel) &&
        !_vm.isChannelLocked(_vm.epgChannel) &&
        _vm.selectedProgram.startMs < new Date().getTime()
      )?_c('span',{staticClass:"overlay-with-icon"},[_c('IconSVG',{attrs:{"svg":_vm.IconPlay}})],1):_vm._e()]),(
      _vm.selectedProgram &&
      (_vm.selectedProgram.description ||
        (_vm.selectedProgram.metaInfo && _vm.selectedProgram.metaInfo.description))
    )?_c('div',{staticClass:"description body2",domProps:{"textContent":_vm._s(_vm.selectedProgram.description || _vm.selectedProgram.metaInfo.description)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }