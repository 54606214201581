<template>
  <div id="fullscreen-menu" class="fullscreen-menu dark" @mousedown="clickOnSideMenu">
    <PlayerButton class="button-close" @click="closeFullscreenMenu">
      <IconSVG :svg="IconCross" />
    </PlayerButton>

    <InputText
      v-model="$store.tvChannels.searchQuery"
      input-category="default"
      class="input-search"
      theme-forced="dark"
      size="small"
      icon="search"
      :placeholder="getTranslation('channel_name')"
    />

    <PlayerFullscreenGenres
      @setIsEpgOpen="setIsEpgOpen"
      @setIsProgramInfoOpen="setIsProgramInfoOpen"
      @setIsChannelInfoVisible="setIsChannelInfoVisible"
    />

    <PlayerFullscreenChannels
      :is-epg-open="isEpgOpen"
      :selected-channel-id="selectedChannelId || ''"
      :is-channel-info-visible="isChannelInfoVisible"
      @setIsEpgOpen="setIsEpgOpen"
      @setIsProgramInfoOpen="setIsProgramInfoOpen"
      @setIsChannelInfoVisible="setIsChannelInfoVisible"
      @setSelectedChannelId="setSelectedChannelId"
      @setSelectedChannel="setSelectedChannel"
      @setEpgChannel="setEpgChannel"
    />

    <PlayerFullscreenEpg
      :is-epg-open="isEpgOpen"
      :is-channel-info-visible="isChannelInfoVisible"
      :epg-channel="epgChannel || undefined"
      :selected-channel="selectedChannel || undefined"
      :selected-program="selectedProgram || undefined"
      @setIsEpgOpen="setIsEpgOpen"
      @setIsProgramInfoOpen="setIsProgramInfoOpen"
      @setIsChannelInfoVisible="setIsChannelInfoVisible"
      @setSelectedChannelId="setSelectedChannelId"
      @selectChannel="selectChannel"
      @switchProgram="switchProgram"
      @setSelectedProgram="setSelectedProgram"
    />

    <PlayerFullscreenProgramInfo
      v-if="isProgramInfoOpen"
      :epg-channel="epgChannel || undefined"
      :selected-program="selectedProgram || undefined"
      @isChannelLocked="isChannelLocked"
      @switchProgram="switchProgram"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import { selectChannel } from 'src/store/tv-channels/actions';
import { DateTime } from 'src/utils/time/date-time';
import { getChannelTitle } from 'src/utils/channel';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import PlayerFullscreenGenres from 'src/components/player/parts/tv/fullscreen/PlayerFullscreenGenres.vue';
import PlayerFullscreenChannels from 'src/components/player/parts/tv/fullscreen/PlayerFullscreenChannels.vue';
import PlayerFullscreenEpg from 'src/components/player/parts/tv/fullscreen/PlayerFullscreenEpg.vue';
import PlayerFullscreenProgramInfo from 'src/components/player/parts/tv/fullscreen/PlayerFullscreenProgramInfo.vue';
import logger from 'src/utils/logger';
import { TChannelEnhanced, TProgramEnhanced } from 'src/api/channels/types';
import { Watch } from 'vue-property-decorator';
import { EVENTS } from 'src/constants';

const log = logger('player-fullscreen-menu');

@Component({
  components: {
    PlayerFullscreenProgramInfo,
    PlayerFullscreenChannels,
    PlayerFullscreenGenres,
    InputText,
    PlayerFullscreenEpg,
    PlayerButton,
    IconSVG,
  },
})
export default class PlayerFullscreenMenu extends SequoiaComponent {
  IconCross = IconCross;

  isEpgOpen = false;
  isProgramInfoOpen = false;
  isChannelInfoVisible = true;

  epgChannel: TChannelEnhanced | null = null;
  selectedChannelId: string | null = null;
  selectedChannel: TChannelEnhanced | null = null;
  selectedProgram: TProgramEnhanced | null = null;

  gaEventTimeout?: NodeJS.Timeout;

  @Watch('$store.tvChannels.searchQuery')
  onSearchQueryChange() {
    if (!this.gaEventTimeout) {
      this.gaEventTimeout = setTimeout(() => {
        this.gaEvent({
          category: 'player_controls',
          action: 'Поиск по каналам (полноэкранный плеер)',
          query: this.$store.tvChannels.searchQuery,
        });
        this.gaEventTimeout = undefined;
      }, 3000);
    }
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  mounted() {
    this.$store.tvChannels.searchQuery = '';
  }

  beforeDestroy() {
    this.$store.tvChannels.searchQuery = '';
    if (this.gaEventTimeout) {
      clearTimeout(this.gaEventTimeout);
    }
  }

  setIsEpgOpen(val: boolean) {
    this.isEpgOpen = val;
  }

  setIsProgramInfoOpen(val: boolean) {
    this.isProgramInfoOpen = val;
  }

  setIsChannelInfoVisible(val: boolean) {
    this.isChannelInfoVisible = val;
  }

  setSelectedChannelId(id: string) {
    this.selectedChannelId = id;
  }

  setSelectedChannel(channel: TChannelEnhanced) {
    this.selectedChannel = channel;
  }

  setSelectedProgram(program: TProgramEnhanced) {
    this.selectedProgram = program;
  }

  setEpgChannel(channel: TChannelEnhanced) {
    this.epgChannel = channel;
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  clickOnSideMenu($event: { target: { id: string }; stopPropagation: () => void }) {
    if ($event.target.id !== 'fullscreen-menu') {
      return;
    }
    $event.stopPropagation();
    this.closeFullscreenMenu();
  }

  closeFullscreenMenu() {
    this.isEpgOpen = false;
    this.isProgramInfoOpen = false;
    this.$store.tvChannels.searchQuery = '';
    this.selectedProgram = null;
    actions.player.hideFullscreenMenu(this.$store);
  }

  isChannelLocked(channel: TChannelEnhanced) {
    return selectors.tvChannels.isChannelLockedSelector(this.$store, channel);
  }

  isDvrDisabledForChannel(channel: TChannelEnhanced) {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store, channel);
  }

  setPickedEpochDay(day: number) {
    actions.player.setPickedEpochDay(this.$store, day);
  }

  async selectChannel(channelId: string) {
    this.isEpgOpen = false;
    actions.player.hideFullscreenMenu(this.$store);
    if (this.currentChannelId !== channelId) {
      await selectChannel(this.$store, channelId, this.$events);
    } else {
      actions.player.goLive(this.$store, this.$events);
    }
  }

  async switchProgram(program: TProgramEnhanced) {
    try {
      const dvrAvailability = await api.channels.getDvrAvailability(
        this.selectedChannelId || this.currentChannelId || ''
      );
      if (dvrAvailability.restrictionReason !== 'Allowed') {
        actions.player.setDvrWarningOverlayData(
          this.$store,
          this.selectedChannelId || this.currentChannelId || '',
          dvrAvailability.msg
        );
        actions.player.hideFullscreenMenu(this.$store);
        actions.player.exitFullscreen(this.$store);
        actions.player.showDvrWarningOverlay(this.$store);
      } else {
        await this.startPlayingProgram(program);
      }
    } catch (err) {
      await this.startPlayingProgram(program);
      log.error(err);
    }
  }

  async startPlayingProgram(program: TProgramEnhanced) {
    this.selectedChannelId = null;

    if (!this.epgChannel) {
      throw new Error(`can't pick a program with an unknown channel`);
    }

    actions.player.hideFullscreenMenu(this.$store);

    if (this.isDvrDisabledForChannel(this.epgChannel)) {
      log.info('cant start playing a program in a dvrRestricted channel');
      return (selectors.player.dvrWarningOverlaySelector(this.$store).isVisible = true);
    }

    if (this.currentChannelId !== this.epgChannel.id) {
      log.info('switching channel and program in fullscreen', program);

      await actions.tvChannels.selectChannel(
        this.$store,
        this.epgChannel.id,
        this.$events,
        true,
        false,
        program.startMs
      );
      this.setPickedEpochDay(DateTime.toEpochDay(new Date(program.startMs || Date.now())));
    } else {
      log.info('switching program in fullscreen', program);
      actions.tvCurrentChannel.selectProgram(this.$store, program);
      this.$events.emit(EVENTS.player.play);
      this.$events.emit(EVENTS.player.reloadStream);
    }
  }

  submitSearch() {
    this.$store.tvChannels.searchQuery = '';
  }
}
</script>

<style lang="scss">
@import 'src/components/ui/input/index';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.fullscreen-menu {
  position: absolute;
  z-index: var(--z-2);
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 112px;
  color: var(--c-dark-font-primary);
  background-color: var(--c-overlay-lvl-80);

  .scroll-wrap,
  &::v-deep .scroll-wrap {
    padding-right: 8px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  button {
    color: var(--c-dark-font-primary);
  }

  .button-close {
    position: absolute;
    top: 48px;
    left: 64px;
    width: 40px;
    height: 40px;
    opacity: 1;
  }

  .input-search {
    position: absolute;
    top: 48px;
    left: 300px;
    z-index: var(--z-2);
    width: 100%;
    max-width: 480px;

    @media (max-width: #{$desktop-s-max}) {
      left: 134px;
    }
  }

  .scroll-wrap,
  &::v-deep .scroll-wrap {
    &::-webkit-scrollbar-track {
      background-color: var(--alpha-dark-3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--alpha-dark-7);
    }
  }
}
</style>
