<template>
  <transition name="fade-ease-out-slow">
    <div v-show="isDateTimeVisible" class="player-date-time color-dark-font-secondary">
      <div class="time h2 mb-0" v-text="time" />
      <div class="date body1" v-text="date" />
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { DateTime } from 'src/utils/time/date-time';
import { selectors } from 'src/store/selectors';

@Component
export default class PlayerDateTime extends Vue {
  get isDateTimeVisible() {
    return selectors.player.isDateTimeVisibleSelector(this.$store);
  }

  get serverTimeMs() {
    return selectors.appInfo.serverTimeMsSelector(this.$store);
  }

  get time() {
    return DateTime.getHM(new Date(this.serverTimeMs));
  }

  get date() {
    return DateTime.getDFullMonth(new Date(this.serverTimeMs));
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.player-date-time {
  z-index: var(--z-2);
  cursor: default;

  .time {
    text-align: right;

    @include mobile-and-tablet {
      @include heading5;
    }
  }

  .date {
    text-align: right;

    @include mobile-and-tablet {
      @include body2;
    }
  }

  @include mobile {
    display: none;
  }
}
</style>
