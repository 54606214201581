// ported from hls.js mp4-inspect.js
// see https://github.com/video-dev/hls.js/blob/90c0ee189e44c2287fe838e91617e0c25ca0cc1e/tools/mp4-inspect.js

const parseType = (buffer: Uint8Array) => {
  let result = '';
  result += String.fromCharCode(buffer[0]);
  result += String.fromCharCode(buffer[1]);
  result += String.fromCharCode(buffer[2]);
  result += String.fromCharCode(buffer[3]);
  return result;
};

interface AvcStream {
  [key: number]: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subarray: any;
  buffer: Uint8Array;
  byteOffset: number;
  byteLength: number;
  length: number;
}

const nalParse = (avcStream: AvcStream) => {
  const avcView = new DataView(avcStream.buffer, avcStream.byteOffset, avcStream.byteLength),
    result = [];
  let length;
  for (let i = 0; i < avcStream.length; i += length) {
    length = avcView.getUint32(i);
    i += 4;
    switch (avcStream[i] & 0x1f) {
      case 0x01:
        result.push('NDR');
        break;
      case 0x05:
        result.push('IDR');
        break;
      case 0x06:
        result.push('SEI');
        break;
      case 0x07:
        result.push('SPS');
        break;
      case 0x08:
        result.push('PPS');
        break;
      case 0x09:
        result.push('AUD');
        break;
      default:
        result.push(avcStream[i] & 0x1f);
        break;
    }
  }
  return result;
};
// registry of handlers for individual mp4 box types
// noinspection JSUnusedGlobalSymbols
const parse = {
  mdat(data: AvcStream) {
    return {
      byteLength: data.byteLength,
      nals: nalParse(data),
    };
  },
};

const mp4toJSON = (data: AvcStream) => {
  const result = [],
    view = new DataView(data.buffer, data.byteOffset, data.byteLength);

  let i = 0,
    size: number,
    type: string,
    end: number,
    box: { byteLength: number; nals: (string | number)[]; size?: number; type?: string };

  while (i < data.byteLength) {
    // parse box data
    size = view.getUint32(i);
    type = parseType(data.subarray(i + 4, i + 8));
    end = size > 1 ? i + size : data.byteLength;

    // parse type-specific data
    box = (parse[type as keyof typeof parse] || ((data) => ({ data })))(data.subarray(i + 8, end));
    box.size = size;
    box.type = type;

    // store this box and move to the next
    result.push(box);
    i = end;
  }
  return result;
};

export { mp4toJSON };
