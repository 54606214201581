<template>
  <div
    class="player-archive player-tv-vod-archive dark"
    :class="{
      mobile: isMobile,
      fullscreen: isFullscreen,
      minimized: isPlayerMinimized && !isFullscreen,
    }"
    data-cy="player-archive"
  >
    <div ref="player" class="player" @click="tryFocusOnPlayerContainer" @mousemove="showOverlay()">
      <div class="video-wrap" data-cy="video-wrap">
        <video
          ref="video"
          class="video"
          :class="{ scaled: isVideoScaled }"
          :muted="isVideoMuted"
          :controls="false"
          playsinline
        />
        <div
          v-if="hasPlayerError"
          class="poster overlay-accent-after overlay-default-before"
          :style="{ backgroundImage: `url(${backgroundPoster})` }"
        />
      </div>

      <SmokingWarning v-if="isShowSmokingWarning" />

      <div v-if="isPlayerLoading && !isPlayerMinimized && !hasPlayerError" class="loader">
        <LoaderSpinner />
      </div>

      <div ref="overlay" class="overlay" :class="{ hidden: shouldHideOverlay }">
        <PlayerHead
          :is-disabled="isOffline"
          :ios-fullscreen-available="iosFullscreenAvailable"
          @closePlayer="closePlayer"
          @toggleFullscreen="toggleFullscreen"
        />

        <PlayerErrors v-if="hasPlayerError" />

        <PlayerControlsMobile
          :has-error="hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline || hasPlayerError"
          @play="play"
          @pause="pause"
          @actualizeVolume="actualizeVolume"
          @togglePlayPause="togglePlayPause"
          @toggleFullscreen="toggleFullscreen"
          @onScroll="$emit('onScroll', $event)"
        />

        <div class="timing-and-episode" :class="{ 'mt-0': hasPlayerError }">
          <TimingBlock
            v-if="!hasPlayerError && duration > 1"
            class="visible-mobile pl-24"
            :current-time="currentTime"
            :duration="duration"
          />

          <div class="episode marquee-wrap hidden-mobile">
            <MarqueeText
              v-if="playingEpisodeId && playingEpisodeTitle"
              class="body1 color-dark-font-primary"
              :text="playingEpisodeTitle"
            />
          </div>

          <div class="mobile-episode body2 color-dark-font-primary" v-text="playingEpisodeTitle" />
        </div>

        <PlayerControls
          class="hidden-mobile"
          :has-error="hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline || hasPlayerError"
          @actualizeVolume="actualizeVolume"
          @togglePlayPause="togglePlayPause"
          @toggleFullscreen="toggleFullscreen"
          @onScroll="$emit('onScroll', $event)"
        />

        <PlayerControlsMinimized
          :has-error="hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline"
          @actualizeVolume="actualizeVolume"
          @togglePlayPause="togglePlayPause"
          @closePlayer="closePlayer"
        />

        <PlayerProgressTimelineArchive
          v-if="!hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline"
          @setCurrentTime="setCurrentTime"
        />

        <PlayerProgressPrograms :is-disabled="isPlayerLoading || isOffline || hasPlayerError" />

        <ClickableUnderlay
          v-if="!hasPlayerError"
          @play="play"
          @pause="pause"
          @toggleFullscreen="toggleFullscreen"
        />
      </div>
    </div>

    <div class="notifications-container">
      <NotificationWithDetails
        v-if="showNotification"
        :title="getTranslation('archive_unavailable_title')"
        :message="playerErrorMessage"
        :icon="IconInfo"
        @hide="closeNotificationWithDetails"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';

// Mixins
import AddResizeListener from 'src/mixins/AddResizeListener';
import PlayerVodArchive from 'src/components/player/PlayerVodArchive';
import { SequoiaComponent } from 'src/mixins';

// Types
import type THls from 'hls.js/dist/hls.js';
import { LoaderResponseFixed } from 'src/components/player/types';

// Components
import LoaderSpinner from '../ui/loader/LoaderSpinner.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerProgressTimelineArchive from 'src/components/player/parts/vod-archive/PlayerProgressTimelineArchive.vue';
import PlayerProgressPrograms from 'src/components/player/parts/vod-archive/PlayerProgressPrograms.vue';
import PlayPauseStop from 'src/components/player/parts/common/player-controls/PlayPauseStop.vue';
import PlayerControls from 'src/components/player/parts/common/player-controls/PlayerControls.vue';
import PlayerControlsMinimized from 'src/components/player/parts/common/player-controls/PlayerControlsMinimized.vue';
import PlayerErrors from 'src/components/player/parts/common/PlayerErrors.vue';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import IconSVG from 'src/components/IconSVG.vue';
import SmokingWarning from 'src/components/player/parts/common/SmokingWarning.vue';

// utils & other
import { EVENTS, HTTP_PLAYER_BAD_RESPONSE_CODES } from 'src/constants';
import { storage } from 'src/utils';
import logger from 'src/utils/logger';
import ClickableUnderlay from 'src/components/player/parts/common/ClickableUnderlay.vue';
import PlayerHead from 'src/components/player/parts/common/PlayerHead.vue';
import { videoDataSelector } from 'src/store/vod/selectors';
import { BROWSERS } from 'src/utils/platform-detector';
import { mp4toJSON } from 'src/utils/hls/mp4';
import TimingBlock from 'src/components/player/parts/common/TimingBlock.vue';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import PlayerControlsMobile from 'src/components/player/parts/common/player-controls/PlayerControlsMobile.vue';
import { ErrorData, Events } from 'hls.js';

const log = logger('player-archive');

let HLS: typeof THls; // async import in mounted()

@Component({
  components: {
    SmokingWarning,
    MarqueeText,
    TimingBlock,
    PlayerControlsMobile,
    PlayerErrors,
    PlayerControls,
    PlayerControlsMinimized,
    PlayPauseStop,
    PlayerProgressTimelineArchive,
    LoaderSpinner,
    PlayerButton,
    PlayerProgressPrograms,
    NotificationWithDetails,
    IconSVG,
    ClickableUnderlay,
    PlayerHead,
  },
})
export default class PlayerArchive extends mixins(
  PlayerVodArchive,
  SequoiaComponent,
  AddResizeListener
) {
  isVideoActuallyPlaying = false;

  @Watch('videoUrl')
  async onVideoUrlChange(videoUrl: string) {
    if (this.isPlayerLoading) {
      log.warning('videoUrl has been changed, though player is still loading -> skip init');
      return;
    }

    actions.player.startLoading(this.$store);
    actions.player.setLoaded(this.$store, false);
    log.info('onVideoDataUrlChange', { hasVideoEnded: this.hasVideoEnded });
    actions.player.setIsPlaying(this.$store, false);
    this.isVideoActuallyPlaying = false;

    this.clearAllErrors();

    if (!this.hasVideoEnded) {
      if (videoUrl) {
        await this.play();
      }
    } else {
      actions.player.minimizePlayer(this.$store);
      actions.player.unlockScroll(this.$store);
      await this.pause();
    }
    actions.player.stopLoading(this.$store);
    actions.player.setLoaded(this.$store, true);
    actions.player.setHasVideoEnded(this.$store, false);
  }

  @Watch('playingCurrentTime')
  onPlayingCurrentTimeChange(val: number) {
    if (val != 0 && val >= this.timelineDurationMs) {
      // when current episode ends or
      // when fastforwarding beyond the video duration,
      // play the next episode if there are any
      if (this.playingEpisodeId && !this.isItTheLasEpisode && this.nextEpisodeNum !== undefined) {
        if (this.isShortJumping) {
          this.isShortJumpDisabled = true;
        }
        this.playNextEpisode();
      }
    }
  }

  @Watch('$store.player.visible')
  onPlayerVisibilityChange() {
    if (this.refVideo) {
      this.videoWidth = this.refVideo.offsetWidth;
    }
  }

  // it is needed only for episodes with seasons
  @Watch('$store.vod.videoData.episodeId')
  onEpisodeIdChange(newValue: string, oldValue: string) {
    if (this.isPlayerLoading) {
      return;
    }
    log.info(`episodeId '${oldValue}' has changed to '${newValue}'`);
    if (!this.seasonsLength) {
      return;
    }

    const seasonNum = this.seasons.findIndex((s) => s.id === this.currentSeasonId);

    actions.vod.setCurrentSeasonNum(this.$store, seasonNum >= 0 ? seasonNum : 0);
    actions.vod.setCurrentAndNextSeasonsAndEpisodes(
      this.$store,
      this.sourceId,
      newValue,
      this.isSavedPause
    );
  }

  get showNotification() {
    return (
      !this.isAnonymous &&
      this.hasPlayerError &&
      this.showNotificationWithDetails &&
      (this.isPlayerMinimized || this.isMobile)
    );
  }

  get isItTheLasEpisode() {
    return selectors.archive.isItTheLasEpisodeSelector(this.$store);
  }

  get backgroundPoster() {
    return this.title?.preview?.posters?.[0]?.path || '';
  }

  get titleId() {
    return this.titleIdFromParams || this.playingTitleId;
  }

  serverPrefetch() {
    actions.player.setPlayerType(this.$store, 'archive');
  }

  async mounted() {
    log.info('Archive player was mounted');
    actions.player.setPlayerType(this.$store, 'archive');
    actions.player.setHasSubtitles(this.$store, false);

    if (this.isFullscreen) {
      this.toggleFullscreen();
    }

    document.addEventListener('MSFullscreenChange', this.actualizeFullscreen.bind(this));
    document.onfullscreenchange =
      document.onwebkitfullscreenchange =
      document.onmozfullscreenchange =
        this.actualizeFullscreen.bind(this);

    // bind events
    window.addEventListener('online', this.onGoOnline);
    window.addEventListener('offline', this.onGoOffline);
    // TODO waiting for mobile player's design update
    // window.addEventListener('orientationchange', this.onOrientationchange);
    window.addEventListener('keydown', this.onKeydown);
    window.addEventListener('keyup', this.onKeyup);
    window.addEventListener('visibilitychange', this.sendStatOnSessionEnd); // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session

    this.refVideo?.addEventListener('waiting', this.onWaiting);
    this.refVideo?.addEventListener('loadedmetadata', this.onLoadedMetadata);
    this.refVideo?.addEventListener('play', this.onPlaying);
    this.refVideo?.addEventListener('canplay', this.onCanplay);

    // used for triggering savePause,
    // particularly on mobile native players
    this.refVideo?.addEventListener('pause', this.onPause);

    this.$events.on(EVENTS.player.play, this.play);
    this.$events.on(EVENTS.player.pause, this.pause);
    this.$events.on(EVENTS.player.reloadStream, this.reloadStream);

    this.actualizeVolume();
    this.everySecondIntervalId = setInterval(this.everySecond, 1000);

    const hasWatchInUrl = this.$route.fullPath.search('watch') >= 0;

    if (this.isAnonymous && hasWatchInUrl) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    if (await this.startPlayArchive()) {
      this.showOverlay();

      this.iosFullscreenAvailable = typeof this.refVideo?.webkitEnterFullScreen === 'function';

      this.gaEvent({
        category: 'player_controls',
        action: 'Запуск контента',
        title_id: this.titleId,
        vod_name: this.sourceId,
      });
    }
  }

  async beforeDestroy() {
    if (this.everySecondIntervalId) {
      clearInterval(this.everySecondIntervalId);
    }

    document.removeEventListener('MSFullscreenChange', this.actualizeFullscreen.bind(this));
    document.onfullscreenchange =
      document.onwebkitfullscreenchange =
      document.onmozfullscreenchange =
        null;

    window.removeEventListener('online', this.onGoOnline);
    window.removeEventListener('offline', this.onGoOffline);
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('keyup', this.onKeyup);
    window.removeEventListener('visibilitychange', this.sendStatOnSessionEnd);
    // TODO waiting for mobile player's design update
    // window.removeEventListener('orientationchange', this.onOrientationchange);

    this.$events.off(EVENTS.player.play, this.play);
    this.$events.off(EVENTS.player.pause, this.pause);
    this.$events.off(EVENTS.player.reloadStream, this.reloadStream);

    await this.closePlayer();
  }

  // TODO waiting for mobile player's design update
  // onOrientationchange() {
  //   if (typeof window !== 'undefined' && window.innerHeight < window.innerWidth) {
  //     this.pause();
  //   } else {
  //     this.play();
  //   }
  //   this.exitFullScreenOnAndroid();
  // }

  async startPlayArchive() {
    const titleId = this.titleId;
    let episodeId = this.episodeIdFromParams || this.playingEpisodeId;
    let mediaItemId = this.mediaItemIdFromParams || this.playingMediaItemId;

    if (!episodeId?.startsWith('e-') && episodeId) {
      mediaItemId = episodeId;
      episodeId = '';
    }

    this.networkChange.reloadAttempt = 1;

    if (titleId || episodeId || mediaItemId) {
      await actions.pauses.loadVodPauses(this.$store, this.sourceId, titleId);

      if (
        !this.isSavedPause && this.title?.preview?.hasSeries
          ? this.lastPause.episodeId === episodeId
          : this.lastPause.titleId === titleId
      ) {
        actions.vod.setIsSavedPause(this.$store, true);
      }

      await actions.archive
        .playVideo(this.$store, {
          titleId,
          episodeId,
          mediaItemId,
          fromStart: mediaItemId === this.lastPause.mediaItemId ? this.lastPause.fromStart : 0,
        })
        .catch(() => {
          actions.vod.resetVideoData(this.$store);
          actions.vod.showTitleDetails(this.$store, this.sourceId, titleId, true);
        });

      actions.player.stopLoading(this.$store);
      actions.player.setLoaded(this.$store, true);

      // TODO remove after successful test or uncomment and test again
      // if (this.isMobile) {
      //   await this.attachUrl();
      // }

      return true;
    } else {
      actions.player.hidePlayer(this.$store);
      return false;
    }
  }

  async onLoadedMetadata() {
    if (this.hls && this.hasSubtitles) {
      this.hls.subtitleDisplay = this.subtitleDisplay;
      this.hls.subtitleTrack = this.subtitleDisplay ? 0 : -1;
    }
  }

  async initHls() {
    if (!HLS) {
      HLS = (await import(/* webpackChunkName: "hls.js" */ 'hls.js')).default;
    }

    this.destroyHls();

    log.info('initHls');

    const config = {
      debug: storage.get('__debug_hls__') === 1,
    };

    actions.player.clearError(this.$store);

    let videoFragmentNumber = 1;
    const renditionId = selectors.tvCurrentChannel.currentRenditionSelector(this.$store)?.id;
    const isMaxQuality = typeof renditionId === 'string' && renditionId.toLowerCase() === 'max';

    this.hls = new HLS(config);

    if (this.hls) {
      this.hls.subtitleDisplay = this.subtitleDisplay;
    }

    let fragsCounter = 0;
    this.hls?.on(HLS.Events.MANIFEST_LOADING, () => {
      if (!fragsCounter) {
        actions.player.setIsPlaying(this.$store, false);
        actions.player.startLoading(this.$store);
        this.isVideoActuallyPlaying = false;
      }
    });

    this.hls?.on(HLS.Events.FRAG_BUFFERED, () => {
      fragsCounter++;
      if (fragsCounter >= 2 && this.isPlayerLoading) {
        actions.player.stopLoading(this.$store);
        actions.player.setLoaded(this.$store, true);
        this.isVideoActuallyPlaying = true;
      }
    });

    this.hls?.on(HLS.Events.MANIFEST_LOADED, (event, data) => {
      const subtitles = data?.subtitles || [];
      const subtitlesLength = subtitles?.length;

      log.info(
        `HLS EVENT: MANIFEST_LOADED. Subtitle track(s) length: ${subtitlesLength}`,
        'subtitleTracks:',
        subtitles
      );
      actions.player.setHasSubtitles(this.$store, !!this.hls && !!subtitlesLength);
    });

    this.hls?.on(HLS.Events.MEDIA_ATTACHED, () => {
      // fired when video html element has been successfully attached to hls instance
      log.info('HLS EVENT: MEDIA_ATTACHED. Video html-element was attached to hls');
      this.attachUrl();
    });

    this.hls?.on(HLS.Events.BUFFER_APPENDING, (event, data) => {
      if (data.type === 'video') {
        const isChromiumBasedBrowser = navigator.userAgent.includes(BROWSERS.chrome);
        if (videoFragmentNumber === 2 && data.data && isMaxQuality && isChromiumBasedBrowser) {
          // if it's the second fragment and type of the first frame of the fragment is not IDR (Instantaneous Decoder Refresh) - Chromium-based browsers will not be able to play this stream
          // see https://dev.tightvideo.com/issues/39749#note-9
          const mdat = mp4toJSON(data.data as any)?.[1];
          const showMaxQualityError = mdat && !mdat.nals?.includes('IDR');
          if (showMaxQualityError) {
            this.showMaxQualityError();
          }
        }
        videoFragmentNumber++;
      }
    });

    this.hls?.on(HLS.Events.ERROR, this.handleHlsError);

    if (this.refVideo) {
      this.hls?.attachMedia(this.refVideo);
    }
  }

  async handleHlsError(event: Events.ERROR, data: ErrorData) {
    const response = data.response as LoaderResponseFixed;
    const responseCode = response?.code;
    const isBadResponse = HTTP_PLAYER_BAD_RESPONSE_CODES.includes(responseCode);
    const isNetworkError = data.type === HLS.ErrorTypes.NETWORK_ERROR;
    const isManifestParsingError = data.details === HLS.ErrorDetails.MANIFEST_PARSING_ERROR; // responseStatus could be 200, but it should be considered as fatal as well
    const isFatalStatus = isNetworkError && (isBadResponse || isManifestParsingError);
    const isFatalError = isFatalStatus || data.fatal; // fatal should also be when stream returns 400, 404 or 500

    if (
      isNetworkError &&
      responseCode === 403 &&
      this.networkChange.reloadAttempt <= this.networkChange.maxAttempts
    ) {
      // probably network has been changed (e.g. VPN has been turned on)
      try {
        log.info(
          'Chunk load returns 403, maybe network has been changed... Reloading stream. Attempt',
          this.networkChange.reloadAttempt
        );
        this.networkChange.reloadAttempt++;
        this.refVideo?.pause();
        this.destroyHls();
        await this.startPlayArchive();
        return;
      } catch {
        // do nothing
      }
    }

    if (isFatalError) {
      // fatal error – when stream is unavailable
      log.error('HLS ERROR ', event, data);
      this.showPlayerNetworkError();
      actions.player.stopLoading(this.$store);

      switch (data.details) {
        case HLS.ErrorDetails.BUFFER_STALLED_ERROR:
          // when buffering is started, see https://github.com/video-dev/hls.js/issues/2113#issuecomment-460682147
          actions.player.startLoading(this.$store);
          break;
        // в сафари не срабатывает BUFFER_NUDGE_ON_STALL
        case HLS.ErrorDetails.BUFFER_NUDGE_ON_STALL:
          // when buffering is finished, see https://github.com/video-dev/hls.js/issues/2113#issuecomment-460682147
          actions.player.stopLoading(this.$store);
          break;
      }

      const allowFullscreenErrors = [HLS.ErrorDetails.BUFFER_NUDGE_ON_STALL];
      if (!allowFullscreenErrors.includes(data.details) && this.isFullscreen) {
        actions.player.exitFullscreen(this.$store);
      }
    } else {
      // just a warning when buffering is in progress
      log.warning('HLS WARNING ', event, data);
    }
  }

  async reloadStream() {
    log.info('reloadStream', { isPlayerPlaying: this.isPlayerPlaying });
    actions.archive.updateVideoUrlTime(this.$store);
    if (this.isPlayerPlaying) {
      await this.play();
    } else {
      await this.pause();
    }
  }

  /**
   * On window online event
   */
  async onGoOnline() {
    log.warning('connection is back online, reloading the stream...');
    await actions.appInfo.updateServerTime(this.$store);
    actions.common.setIsOffline(this.$store, false);
    actions.player.setIsPlaying(this.$store, this.wasPlayingBeforeOffline);
    this.clearAllErrors();

    if (this.isPlayerPlaying) {
      await this.play();
    }
  }

  togglePlayPause() {
    if (this.isPlayerPlaying) {
      this.pause();
    } else {
      this.play();
    }

    const { titleId, sourceId } = videoDataSelector(this.$store);
    this.gaEvent({
      category: 'player_controls',
      action: `${this.isPlayerPlaying ? 'Pause' : 'Play'}`,
      title_id: titleId,
      vod_name: sourceId,
    });
  }

  async play() {
    if (selectors.archive.isDvrRestrictedSelector(this.$store)) {
      actions.player.setError(
        this.$store,
        'dvr',
        this.getTranslation('archive_unavailable_restricted')
      );
      return false;
    }

    if (!this.refVideo) {
      log.info('refVideo is not ready yet');
      return false;
    }

    this.clearAllErrors();

    if (!this.hasPlayerError) {
      actions.player.startLoading(this.$store);
    }

    // since stream is changing on almost every this.play() call,
    // and as it is recommended in hls.js docs
    // we should re-initialize hls on stream change to release hls resources
    if (this.useHls) {
      // when hls enabled - stream will be attached once DOM will be ready on MEDIA_ATTACHED event
      await this.initHls();
    } else {
      // for non-hls version we attach stream to DOM video element straight ahead
      this.refVideo?.pause();
      await this.attachUrl();
    }

    log.info('start playing...');

    try {
      await this.refVideo.play();

      this.showOverlay();
      this.showSmokingWarning = true;
      actions.player.setIsPlaying(this.$store, true);
      this.isVideoActuallyPlaying = true;
      log.info('play');
    } catch (err) {
      // to avoid: "the play() request was interrupted..."
      log.error('play:', err);
      actions.player.setIsPlaying(this.$store, false);
    } finally {
      actions.player.stopLoading(this.$store);
      if (this.waitingLoaderTimeoutId) {
        clearTimeout(this.waitingLoaderTimeoutId);
      }
    }

    if (this.isPlayerMinimized) {
      actions.player.expandPlayer(this.$store);
      actions.player.lockScroll(this.$store);
    }
  }

  everySecond() {
    if (!this.playingTitleId || this.isOffline) {
      return;
    }

    if (this.isVideoActuallyPlaying) {
      actions.vod.setPlayingCurrentTime(
        this.$store,
        selectors.vod.playingCurrentTimeSelector(this.$store) + 1000
      );
    }
  }
}
</script>

<style lang="scss">
@import 'player';
</style>
