<template>
  <div class="player-error dark" :class="{ fullscreen: isFullscreen }">
    <p
      v-if="error?.options?.askUserToUpdateBrowser"
      class="body2"
      v-html="getTranslation('player_error_update_old_browser')"
    />
    <template v-else>
      <p
        v-if="showTitle"
        class="title h4"
        v-text="title || getTranslation('channel_unavailable')"
      />

      <template v-if="isProviderError">
        <p
          v-if="providerDescription"
          ref="providerDescription"
          class="description body2"
          v-html="providerDescription"
        />
        <p v-if="providerPhone" class="phone body1" v-text="providerPhone" />
        <a
          v-if="providerUrl"
          class="link nav-colored"
          target="_blank"
          :href="providerUrl"
          v-html="getTranslation('channel_unavailable_subscribe')"
        />
      </template>
      <p v-else class="body2" v-text="error?.message || ''" />
    </template>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { PLAYER_ERROR_CODES } from 'src/constants';
import { TPlayerError } from 'src/store/player/types';

@Component
export default class PlayerError extends SequoiaComponent {
  @Prop()
  title?: string;

  @Prop()
  error!: TPlayerError | null;

  @Ref('providerDescription')
  readonly refProviderDescription?: HTMLDivElement;

  get showTitle() {
    return this.error?.code !== PLAYER_ERROR_CODES.INTERNAL;
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isChannelWithVitrina() {
    return selectors.tvCurrentChannel.isCurrentChannelWithVitrinaSelector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get wasPlaybackLoaded() {
    return selectors.tvCurrentChannel.playbackInfoDetailsSelector(this.$store);
  }

  get isProviderError() {
    return this.error && this.error.code === PLAYER_ERROR_CODES.PROVIDER_ERROR;
  }

  get providerPhone() {
    return selectors.providerInfo.salesPhoneFormattedSelector(this.$store);
  }

  get providerUrl() {
    return selectors.providerInfo.urlSelector(this.$store);
  }

  get providerDescription() {
    let gaEventAction = '';
    let translationId = '';
    let forceLocalErrorMessage = false;

    if (this.isAnonymous) {
      translationId = 'channel_unavailable_not_logged_in';
      if (this.wasPlaybackLoaded && !this.isChannelWithVitrina) {
        gaEventAction = 'Появление ошибки "Вы не авторизованы"';
      }
      forceLocalErrorMessage = true; // https://lifestream.atlassian.net/browse/SEQ-1318
    } else if (this.providerPhone || this.providerUrl) {
      if (this.providerPhone && this.providerUrl) {
        translationId = 'channel_unbought_provider_url_and_phone';
      } else if (!this.providerPhone && this.providerUrl) {
        translationId = 'channel_unbought_provider_url_only';
      } else if (this.providerPhone && !this.providerUrl) {
        translationId = 'channel_unbought_provider_phone_only';
      }
      gaEventAction = '"Нет подписки" c контактами провайдера';
    } else {
      translationId = 'channel_unbought_no_provider_info';
      gaEventAction = '"Нет подписки" без контактов провайдера';
    }

    let providerDescription = this.error?.message || '';

    if (forceLocalErrorMessage || !providerDescription) {
      providerDescription = this.getTranslation(translationId);
    }

    if (this.wasPlaybackLoaded) {
      this.gaEvent({
        category: 'blocked_content_click',
        action: gaEventAction,
        channel_name: this.currentChannel?.name,
      });
    }

    return providerDescription
      .replace(/%channelName%/g, this.currentChannel?.name || '')
      .replace(`%url%`, this.providerUrl);
  }

  mounted() {
    actions.providerInfo.loadProviderInfo(this.$store);
    this.refProviderDescription?.querySelector('a')?.addEventListener('click', (event) => {
      const link = new URL((event.target as HTMLLinkElement).href);

      if (link.hostname === window.location.hostname) {
        event.preventDefault();
        this.$router.push(link.pathname);
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.player-error {
  .title {
    margin-bottom: 0;
  }

  .description {
    max-width: 480px;
  }

  .phone {
    margin: 8px 0;
    font-weight: bold;
  }
}
</style>
