var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"player-settings-menu body2",on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMainMenuVisible),expression:"isMainMenuVisible"}],staticClass:"main"},[(_vm.isTv)?_c('PlayerSettingsButtonText',{staticClass:"button-about-channel",attrs:{"title":_vm.getTranslation('about_channel'),"icon":_vm.IconInfo},on:{"click":_vm.showChannelDetails}}):_vm._e(),(_vm.isTv)?_c('PlayerSettingsButtonText',{staticClass:"button-favorite",attrs:{"title":_vm.getTranslation(_vm.isChannelInFavorites ? 'favorites_off' : 'favorites_on'),"icon":_vm.isChannelInFavorites ? _vm.IconBookmarkOn : _vm.IconBookmarkOff},on:{"click":_vm.toggleChannelFavorite}}):_vm._e(),((_vm.isTv && _vm.lastPauseTv.time && _vm.pauseDescription) || _vm.hasSavedPause)?_c('PlayerSettingsButtonText',{staticClass:"button-back-to-pause",attrs:{"title":_vm.getTranslation('continue_watching'),"description":_vm.pauseDescription,"icon":_vm.IconBackToPause},on:{"click":_vm.goToPause}}):_vm._e(),(
        _vm.isTv &&
        ((_vm.languages.length > 1 && _vm.languages[_vm.languageIndex]) ||
          (_vm.renditions.length > 1 && _vm.renditions[_vm.renditionIndex]))
      )?_c('ContentSeparator',{attrs:{"theme-forced":"dark"}}):_vm._e(),(!_vm.isVod && _vm.languages.length > 1 && _vm.languages[_vm.languageIndex])?_c('PlayerSettingsButtonSection',{attrs:{"title":_vm.getTranslation('language'),"value":_vm.languages[_vm.languageIndex].caption},on:{"click":_vm.toggleLanguageSubMenu}}):_vm._e(),(!_vm.isVod && _vm.renditions.length > 1 && _vm.renditions[_vm.renditionIndex])?_c('PlayerSettingsButtonSection',{attrs:{"title":_vm.getTranslation('quality'),"value":_vm.renditions[_vm.renditionIndex].caption},on:{"click":_vm.toggleRenditionSubMenu}}):_vm._e(),(
        !_vm.isVod &&
        ((_vm.languages.length > 1 && _vm.languages[_vm.languageIndex]) ||
          (_vm.renditions.length >= 1 && _vm.renditions[_vm.renditionIndex]))
      )?_c('ContentSeparator',{attrs:{"theme-forced":"dark"}}):_vm._e(),_c('PlayerSettingsButtonSwitch',{attrs:{"title":_vm.getTranslation('enlarge'),"value":_vm.isVideoScaled},on:{"input":_vm.toggleVideoScale}}),_c('PlayerSettingsButtonSwitch',{staticClass:"hidden-mobile",attrs:{"title":_vm.getTranslation('show_watch'),"value":_vm.isDateTimeVisible},on:{"input":_vm.toggleWatch}}),(_vm.hasSubtitles)?_c('PlayerSettingsButtonSwitch',{attrs:{"title":_vm.getTranslation('show_subtitles'),"value":_vm.subtitleDisplay},on:{"input":_vm.toggleSubtitles}}):_vm._e(),(_vm.isTv && _vm.hasFps50)?_c('PlayerSettingsButtonSwitch',{attrs:{"title":_vm.getTranslation('50fps'),"value":_vm.fps50},on:{"input":_vm.toggleFps}}):_vm._e(),(_vm.isPc)?_c('ContentSeparator',{attrs:{"theme-forced":"dark"}}):_vm._e(),(_vm.isPc)?_c('PlayerSettingsButtonText',{staticClass:"hot-keys",attrs:{"title":_vm.getTranslation('hot_keys'),"icon":_vm.IconKeyboard},on:{"click":_vm.showHelp}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMainMenuVisible),expression:"!isMainMenuVisible"}],staticClass:"sub"},[_c('PlayerSettingsOptions',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLanguageSubMenuVisible),expression:"isLanguageSubMenuVisible"}],attrs:{"title":_vm.getTranslation('language'),"settings":_vm.languagesMapped,"active":_vm.languageIndex || 0},on:{"select":function($event){return _vm.selectLanguage($event)},"close":_vm.toggleLanguageSubMenu}}),_c('PlayerSettingsOptions',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRenditionSubMenuVisible),expression:"isRenditionSubMenuVisible"}],attrs:{"title":_vm.getTranslation('quality'),"settings":_vm.renditions,"active":_vm.renditionIndex || 0},on:{"select":function($event){return _vm.selectRendition($event)},"close":_vm.toggleRenditionSubMenu}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }