<template>
  <div class="program-info scroll-wrap">
    <div v-text="startEndText" />
    <h5
      v-if="
        selectedProgram &&
        (selectedProgram.title || (selectedProgram.metaInfo && selectedProgram.metaInfo.title))
      "
      v-text="selectedProgram.title || selectedProgram.metaInfo.title"
    />
    <div
      class="thumbnail button-with-hover-overlay"
      :class="{
        'pointer-events-none':
          isDvrDisabledForChannel(epgChannel) ||
          isChannelLocked(epgChannel) ||
          selectedProgram.startMs > new Date().getTime(),
      }"
      @click="switchProgram(selectedProgram)"
    >
      <img :src="getProgramThumbnailUrl(selectedProgram)" :alt="selectedProgram.title" />
      <div v-if="selectedProgram.age" class="age body2"><span v-text="selectedProgram.age" />+</div>
      <span
        v-if="
          !isDvrDisabledForChannel(epgChannel) &&
          !isChannelLocked(epgChannel) &&
          selectedProgram.startMs < new Date().getTime()
        "
        class="overlay-with-icon"
      >
        <IconSVG :svg="IconPlay" />
      </span>
    </div>
    <div
      v-if="
        selectedProgram &&
        (selectedProgram.description ||
          (selectedProgram.metaInfo && selectedProgram.metaInfo.description))
      "
      class="description body2"
      v-text="selectedProgram.description || selectedProgram.metaInfo.description"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import { TChannelEnhanced, TProgramEnhanced } from 'src/api/channels/types';
import { getResizedImageUrl } from 'src/utils/images';

@Component({
  components: {
    IconSVG,
    PlayerButton,
  },
})
export default class PlayerFullscreenProgramInfo extends SequoiaComponent {
  IconPlay = IconPlay;

  @Prop()
  epgChannel!: TChannelEnhanced;

  @Prop()
  selectedProgram!: TProgramEnhanced;

  get startEndText() {
    return `${this.selectedProgram.startTimeHM} – ${this.selectedProgram.endTimeHM}`;
  }

  getProgramThumbnailUrl(program: TProgramEnhanced) {
    const thumbnail = program?.mediaInfo?.thumbnails?.[0]?.url;
    if (thumbnail) {
      return getResizedImageUrl(thumbnail, 232, 130);
    }
    return '';
  }

  isChannelLocked(channel: TChannelEnhanced) {
    return selectors.tvChannels.isChannelLockedSelector(this.$store, channel);
  }

  isDvrDisabledForChannel(channel: TChannelEnhanced) {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store, channel);
  }

  switchProgram(program: TProgramEnhanced) {
    this.$emit('switchProgram', program);
    this.gaEvent({
      category: 'player_controls',
      action: 'Запуск передачи (полноэкранный плеер)',
      title_id: program.title,
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.program-info {
  max-width: 400px;
  padding-right: 16px;
  margin-top: 148px;
  margin-right: 12px;
  margin-left: 24px;
  cursor: default;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  .thumbnail {
    position: relative;
    max-width: 232px;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

    .age {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: var(--z-2);
      padding: 2px 8px;
      background-color: var(--alpha-light-8);
      border-radius: 4px;
    }
  }

  .description {
    padding-bottom: 16px;
    cursor: default;
  }
}
</style>
