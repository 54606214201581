<template>
  <div class="player-buttons-group">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PlayerButtonsGroup extends Vue {}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-buttons-group {
  display: flex;

  &::v-deep .player-button {
    margin: 0 8px;

    @include tablet {
      margin: 0 4px;
    }

    @include mobile {
      margin: 0 8px;

      &.play-pause-stop {
        padding: 0;
      }
    }
  }
}
</style>
