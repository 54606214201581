<template>
  <div class="player-center" @click="hideEpgInfo">
    <template v-if="!isFullscreen">
      <transition name="epg-info-small">
        <PlayerEpgInfoBrief v-if="isEpgInfoVisible && !isLoading" />
      </transition>
    </template>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import logger from 'src/utils/logger';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import PlayerEpgInfoBrief from 'src/components/player/parts/tv/PlayerEpgInfoBrief.vue';

const log = logger('player-center');

@Component({
  components: {
    PlayerEpgInfoBrief,
    ButtonDefault,
  },
})
export default class PlayerCenter extends SequoiaComponent {
  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isEpgInfoVisible() {
    return selectors.player.isEpgInfoVisibleSelector(this.$store);
  }

  get isLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  hideEpgInfo() {
    if (!this.isEpgInfoVisible) {
      return false;
    }
    actions.player.hideEpgInfo(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-center {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  .epg-info-small-enter-active {
    animation: epg-info-small-appear 0.25s forwards;
  }

  .epg-info-small-leave-active {
    animation: epg-info-small-appear 0.15s reverse forwards;
  }

  @keyframes epg-info-small-appear {
    0% {
      opacity: 0;
      transform: translateY(24px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
</style>
