<template>
  <button
    type="button"
    class="button-text player-settings-button flex-center-vertical"
    @click="$emit('click')"
  >
    <span class="left flex">
      <span class="title body2" v-html="title" />
      <span class="description caption2 color-dark-font-secondary" v-html="description" />
    </span>
    <span class="right flex-center-vertical">
      <IconSVG :svg="icon" :size="20" />
    </span>
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: { IconSVG },
})
export default class PlayerSettingsButtonText extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop()
  description?: string;

  @Prop({ required: true })
  icon!: Record<string, unknown>;
}
</script>

<style lang="scss">
@import 'player-settings-button';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.button-text {
  .left {
    flex-direction: column;
    max-width: calc(100% - 28px);
    overflow: hidden;
    text-align: left;

    .description {
      width: 100%;
      @extend %truncate-after-1-line;
    }
  }
}
</style>
