<template>
  <div
    ref="timeline"
    class="player-progress-timeline"
    :class="theme"
    @mousemove="enableBalloonFollowingMouse"
  >
    <div v-if="playedWidth" class="played" :style="{ width: `${playedWidth * 100}%` }" />
    <div class="now" :style="{ width: `${nowWidth * 100}%` }" />

    <div ref="curProgramTick" class="tick left" :style="{ left: `${ticksOffset * 100}%` }" />
    <div
      ref="nextProgramTick"
      class="tick right"
      :style="{ left: `${(1 - ticksOffset) * 100}%` }"
    />

    <div
      class="transparent-overlay"
      :class="{ 'cursor-not-allowed': isDvrDisabled }"
      @mouseenter="timelineHovered = true"
      @mouseleave="(timelineHovered = false), (timelineHoverX = null)"
      @mousemove="timelineHoverX = $event.offsetX / $event.target.offsetWidth"
      @click="clickOnTimeline($event.offsetX / $event.target.offsetWidth)"
    />
    <div class="current-time-control" :style="{ left: `calc(${playedWidth * 100}% - 15px)` }" />

    <div
      v-if="showThumbnail && !isPlayerMinimized"
      class="timeline-thumbnail"
      :style="{ width: `${thumbnailWidthPx}px`, left: `${thumbnailPosition * 100}%` }"
    >
      <img v-if="thumbnail" :src="thumbnail.url" alt="" />
    </div>

    <TimelineBalloon
      v-if="showBalloon"
      :balloon-position="balloonPosition"
      :balloon-time="balloonTime"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import Component from 'vue-class-component';
import { updatePlayingTime } from 'src/store/player/actions';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { TPlayer } from 'src/store/player/types';
import { SequoiaComponent } from 'src/mixins';
import logger from 'src/utils/logger';
import { Ref, Watch } from 'vue-property-decorator';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import TimelineBalloon from 'src/components/player/parts/common/TimelineBalloon.vue';
import { boundMinMax } from 'src/utils/number';
import { DateTime } from 'src/utils/time/date-time';
import { videoDataSelector } from 'src/store/vod/selectors';
import { EVENTS } from 'src/constants';

const log = logger('player-progress-timeline');

@Component({
  components: { TimelineBalloon, LoaderSpinner },
})
export default class PlayerProgressTimeline extends SequoiaComponent {
  timelineHovered = false;
  timelineHoverX: number | null = null;
  balloonWidthPx = 70;
  thumbnailWidthPx = 200;
  ticksOffset = 0.06;
  thumbnails: Array<Array<any>> = [];
  player!: TPlayer;

  @Ref('timeline')
  refTimeline!: HTMLDivElement;

  @Watch('thumbnailsServer')
  onThumbnailsServerChange() {
    this.thumbnails = [];
  }

  @Watch('timelineHoverX')
  async onTimelineHoverXChange() {
    const timestamp = this.timelineHoverTimestamp;
    if (!this.thumbnailsServer || this.isHoveringFuture) {
      return;
    }

    if (
      this.thumbnails.length &&
      this.thumbnails.find(
        (images) => timestamp >= images[0].from && timestamp <= images[images.length - 1].to
      )
    ) {
      // thumbnails list has been already loaded
      return;
    }
    const period = 200;
    const { data } = await axios.get(this.thumbnailsServer, {
      params: {
        timestamp: timestamp / 1000,
        before: period,
        after: period,
      },
    });

    const images = data.images.map((image: { b: number; e: number; u: string }) => {
      const base = parseFloat(data.base);
      return {
        ...image,
        url: `${data.prefix}${image.u}${data.suffix}`,
        from: (base + image.b) * 1000,
        to: (base + image.e) * 1000,
      };
    });

    this.thumbnails.push(images);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get serverTimeMs() {
    return selectors.appInfo.serverTimeMsSelector(this.$store);
  }

  get programs() {
    return selectors.tvEpg.programsSelector(this.$store);
  }

  get currentProgram() {
    return selectors.tvEpg.currentProgramSelector(this.$store);
  }

  get playingTime() {
    return selectors.player.playingTimeMsSelector(this.$store);
  }

  /**
   * Current program start timestamp
   */
  get currentProgramStartMs() {
    return this.currentProgram?.startMs || 0;
  }

  /**
   * Current program end timestamp
   */
  get currentProgramEndMs() {
    return this.currentProgram?.endMs || 0;
  }

  /**
   * Current program's length in milliseconds
   */
  get currentProgramLengthMs() {
    return this.currentProgramEndMs - this.currentProgramStartMs;
  }

  /**
   * Timeline's length in milliseconds
   *
   * Say currentTitleLengthMs = 100ms, tickOffset = 5% or 0.05, then
   * currentTitleLengthPercents = 1 - ticksOffset + ticksOffset, or 0.9
   * currentTitleLengthMs = timelineLengthMs * currentTitleLengthPercents
   * timelineLengthMs = currentTitleLengthMs / currentTitleLengthPercents
   * timelineLengthMs = 111,11ms
   */
  get timelineLengthMs() {
    // TODO: is this formula correct? need to try to use formula from timelineDurationMsSelector instead
    return this.currentProgramLengthMs / (1 - this.ticksOffset * 2);
  }

  /**
   * Tick's length in milliseconds
   */
  get tickLengthMs() {
    return this.ticksOffset * this.timelineLengthMs;
  }

  /**
   * Timeline start timestamp
   */
  get timelineStartMs() {
    return this.currentProgramStartMs - this.tickLengthMs;
  }

  get isLive() {
    return selectors.player.isLiveSelector(this.$store);
  }

  get playingTimeMs() {
    return selectors.player.playingTimeMsSelector(this.$store);
  }

  /**
   * Width of a played area
   */
  get playedWidth() {
    return this.getPositionByTimestamp(this.playingTimeMs);
  }

  /**
   * Width of grey area until current time
   */
  get nowWidth() {
    if (this.isLive) {
      return 0;
    }

    return this.getPositionByTimestamp(this.serverTimeMs);
  }

  get showBalloon() {
    return (
      (this.timelineHovered || selectors.player.isShowBalloonSelector(this.$store)) &&
      !this.isDvrDisabled
    );
  }

  get balloonTimestamp() {
    let timestamp = this.playingTime;
    if (this.timelineHoverX && !this.isRewinding && !this.isFastForwarding) {
      timestamp = this.timelineStartMs + this.timelineHoverX * this.timelineLengthMs;
    }
    return timestamp;
  }

  get balloonTime() {
    if (!this.showBalloon) {
      return '';
    }
    return DateTime.getHM(new Date(this.balloonTimestamp));
  }

  get balloonPosition() {
    if (!this.showBalloon) {
      return 0;
    }
    const balloonWidthPercent = this.balloonWidthPx / this.refTimeline.clientWidth;
    const rightBound = 1 - balloonWidthPercent;
    const isFollowingMouse = selectors.player.isTimelineBalloonFollowingMouseSelector(this.$store);
    const widthHalf = balloonWidthPercent / 2;
    const balloonCenterPosition = !isFollowingMouse ? this.playedWidth : this.timelineHoverX || 0;
    return boundMinMax(0, balloonCenterPosition - widthHalf, rightBound);
  }

  get showThumbnail() {
    return (
      this.timelineHovered && !this.isDvrDisabled && this.thumbnailsServer && !this.isHoveringFuture
    );
  }

  get thumbnailPosition() {
    if (!this.showThumbnail || !this.refTimeline) {
      return;
    }
    const thumbnailWidthPc = this.thumbnailWidthPx / this.refTimeline.clientWidth;
    const rightBound = 1 - thumbnailWidthPc;
    const isFollowingMouse = selectors.player.isTimelineBalloonFollowingMouseSelector(this.$store);
    const widthHalf = thumbnailWidthPc / 2;
    const balloonCenterPosition = !isFollowingMouse ? this.playedWidth : this.timelineHoverX || 0;
    return boundMinMax(0, balloonCenterPosition - widthHalf, rightBound);
  }

  get thumbnail() {
    let thumbnail = null;
    this.thumbnails.find((images) => {
      thumbnail = images.find(
        (image: { from: number; to: number }) =>
          this.balloonTimestamp >= image.from && this.balloonTimestamp < image.to
      );
      return !!thumbnail;
    });
    return thumbnail;
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get isRewinding() {
    return selectors.player.isRewindingSelector(this.$store);
  }

  get isFastForwarding() {
    return selectors.player.isFastForwardingSelector(this.$store);
  }

  get thumbnailsServer() {
    return selectors.tvCurrentChannel.thumbnailsServerSelector(this.$store);
  }

  get timelineHoverTimestamp() {
    if (!this.timelineHoverX) {
      return 0;
    }
    return Math.round(this.timelineStartMs + this.timelineHoverX * this.timelineLengthMs);
  }

  get isHoveringFuture() {
    return this.timelineHoverTimestamp && this.timelineHoverTimestamp >= Date.now();
  }

  clickOnTimeline(percents: number) {
    if (this.isDvrDisabled) {
      return log.warning('Cannot change playing time for DVR-disabled channel');
    }

    if (this.isDvrRestricted) {
      actions.player.setAlert(
        this.$store,
        selectors.tvCurrentChannel.dvrRestrictionMessageSelector(this.$store)
      );
      return log.warning('Cannot change playing time for DVR-restricted channel');
    }

    const newPlayingTime = Math.round(this.timelineStartMs + percents * this.timelineLengthMs);

    if (newPlayingTime > Date.now()) {
      return;
    }

    this.gaEvent({
      category: 'player_controls',
      action: 'Клик по таймлайну (навигация в DVR)',
      control_type: 'mouse',
      channel_name: videoDataSelector(this.$store).channelId,
    });

    actions.player.setIsLive(this.$store, false);
    updatePlayingTime(this.$store, newPlayingTime);
    this.$events.emit(EVENTS.player.reloadStream);
  }

  getPositionByTimestamp(timestamp: number) {
    if (!this.currentProgramLengthMs) {
      return 0;
    }
    const lengthMs = timestamp - this.timelineStartMs;
    return boundMinMax(0, lengthMs / this.timelineLengthMs, 1);
  }

  enableBalloonFollowingMouse() {
    const isTimelineBalloonFollowingMouse =
      selectors.player.isTimelineBalloonFollowingMouseSelector(this.$store);
    if (!isTimelineBalloonFollowingMouse) {
      actions.player.setTimelineBalloonFollowingMouse(this.$store, true);
    }
  }
}
</script>

<style lang="scss">
@import 'src/components/player/parts/player-timeline';
</style>
