<template>
  <div
    class="player-settings-menu body2"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <div v-show="isMainMenuVisible" class="main">
      <PlayerSettingsButtonText
        v-if="isTv"
        class="button-about-channel"
        :title="getTranslation('about_channel')"
        :icon="IconInfo"
        @click="showChannelDetails"
      />

      <PlayerSettingsButtonText
        v-if="isTv"
        class="button-favorite"
        :title="getTranslation(isChannelInFavorites ? 'favorites_off' : 'favorites_on')"
        :icon="isChannelInFavorites ? IconBookmarkOn : IconBookmarkOff"
        @click="toggleChannelFavorite"
      />

      <PlayerSettingsButtonText
        v-if="(isTv && lastPauseTv.time && pauseDescription) || hasSavedPause"
        class="button-back-to-pause"
        :title="getTranslation('continue_watching')"
        :description="pauseDescription"
        :icon="IconBackToPause"
        @click="goToPause"
      />

      <ContentSeparator
        v-if="
          isTv &&
          ((languages.length > 1 && languages[languageIndex]) ||
            (renditions.length > 1 && renditions[renditionIndex]))
        "
        theme-forced="dark"
      />

      <PlayerSettingsButtonSection
        v-if="!isVod && languages.length > 1 && languages[languageIndex]"
        :title="getTranslation('language')"
        :value="languages[languageIndex].caption"
        @click="toggleLanguageSubMenu"
      />

      <PlayerSettingsButtonSection
        v-if="!isVod && renditions.length > 1 && renditions[renditionIndex]"
        :title="getTranslation('quality')"
        :value="renditions[renditionIndex].caption"
        @click="toggleRenditionSubMenu"
      />

      <ContentSeparator
        v-if="
          !isVod &&
          ((languages.length > 1 && languages[languageIndex]) ||
            (renditions.length >= 1 && renditions[renditionIndex]))
        "
        theme-forced="dark"
      />

      <PlayerSettingsButtonSwitch
        :title="getTranslation('enlarge')"
        :value="isVideoScaled"
        @input="toggleVideoScale"
      />

      <PlayerSettingsButtonSwitch
        class="hidden-mobile"
        :title="getTranslation('show_watch')"
        :value="isDateTimeVisible"
        @input="toggleWatch"
      />

      <PlayerSettingsButtonSwitch
        v-if="hasSubtitles"
        :title="getTranslation('show_subtitles')"
        :value="subtitleDisplay"
        @input="toggleSubtitles"
      />

      <PlayerSettingsButtonSwitch
        v-if="isTv && hasFps50"
        :title="getTranslation('50fps')"
        :value="fps50"
        @input="toggleFps"
      />

      <ContentSeparator v-if="isPc" theme-forced="dark" />

      <PlayerSettingsButtonText
        v-if="isPc"
        class="hot-keys"
        :title="getTranslation('hot_keys')"
        :icon="IconKeyboard"
        @click="showHelp"
      />
    </div>

    <div v-show="!isMainMenuVisible" class="sub">
      <PlayerSettingsOptions
        v-show="isLanguageSubMenuVisible"
        :title="getTranslation('language')"
        :settings="languagesMapped"
        :active="languageIndex || 0"
        @select="selectLanguage($event)"
        @close="toggleLanguageSubMenu"
      />

      <PlayerSettingsOptions
        v-show="isRenditionSubMenuVisible"
        :title="getTranslation('quality')"
        :settings="renditions"
        :active="renditionIndex || 0"
        @select="selectRendition($event)"
        @close="toggleRenditionSubMenu"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import PlayerSettingsOptions from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsOptions.vue';
import PlayerSettingsButtonSection from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsButtonSection.vue';
import PlayerSettingsButtonText from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsButtonText.vue';
import PlayerSettingsButtonSwitch from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsButtonSwitch.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconKeyboard from 'src/svg/player/keyboard.svg';
import IconInfo from 'src/svg/info.svg';
import IconBackToPause from 'src/svg/player/back-to-pause.svg';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconBookmarkOff from 'src/svg/bookmark-off.svg';
import ContentSeparator from 'src/components/ui/ContentSeparator.vue';
import logger from 'src/utils/logger';
import { getIsFullscreen } from 'src/utils';
import { EVENTS } from 'src/constants';
import * as api from 'src/api';
import { DateTime } from 'src/utils/time/date-time';
import { getDeviceFlags } from 'src/utils/platform-detector';
import Vod from 'src/mixins/Vod';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { TMediaItem } from 'src/store/archive/types';
import { convertToSeconds } from 'src/utils/time/convert-time';

const log = logger('player-settings-menu');

@Component({
  components: {
    ContentSeparator,
    PlayerSettingsButtonText,
    PlayerSettingsButtonSection,
    PlayerSettingsOptions,
    PlayerSettingsButtonSwitch,
    IconSVG,
    PlayerButton,
  },
})
export default class PlayerSettingsMenu extends mixins(Vod) {
  IconKeyboard = IconKeyboard;
  IconInfo = IconInfo;
  IconBackToPause = IconBackToPause;
  IconBookmarkOn = IconBookmarkOn;
  IconBookmarkOff = IconBookmarkOff;
  isMainMenuVisible = true;
  isLanguageSubMenuVisible = false;
  isRenditionSubMenuVisible = false;

  get isLive() {
    return selectors.player.isLiveSelector(this.$store);
  }

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get isVod() {
    return this.playerType === 'vod';
  }

  get isPc() {
    return getDeviceFlags().isPc;
  }

  get hasSubtitles() {
    return selectors.player.hasSubtitlesSelector(this.$store);
  }

  get languages() {
    return selectors.tvCurrentChannel.languagesSelector(this.$store);
  }

  get languagesMapped() {
    return this.languages.map((lang) => {
      return {
        caption: this.getTranslation(lang.caption),
        id: lang.id,
      };
    });
  }

  get languageIndex() {
    return selectors.tvCurrentChannel.currentLanguageIndexSelector(this.$store);
  }

  get renditions() {
    return selectors.tvCurrentChannel.renditionsSelector(this.$store);
  }

  get renditionIndex() {
    return selectors.tvCurrentChannel.currentRenditionIndexSelector(this.$store);
  }

  get isVideoScaled() {
    return selectors.player.isVideoScaledSelector(this.$store);
  }

  get isDateTimeVisible() {
    return selectors.player.isDateTimeVisibleSelector(this.$store);
  }

  get subtitleDisplay() {
    return selectors.player.subtitleDisplaySelector(this.$store);
  }

  get hasFps50() {
    return selectors.player.hasFps50Selector(this.$store);
  }

  get fps50() {
    return selectors.player.fps50Selector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }
  get currentChannelId() {
    return (
      this.currentChannel?.id ||
      selectors.tvCurrentChannel.currentChannelIdSelector(this.$store) ||
      ''
    );
  }

  get isChannelInFavorites() {
    return this.favoriteChannels[this.currentChannelId];
  }

  get lastPause() {
    return this.isTv ? this.lastPauseTv : this.lastPauseVod;
  }

  get lastPauseTv() {
    return selectors.pauses.lastTvPause(this.$store);
  }
  get lastPauseVod() {
    return selectors.pauses.lastVodPause(this.$store);
  }

  get lastPauseEpisode() {
    return this.lastPauseSeason?.episodes.find(
      (e) => e?.id === this.lastPauseVod.episodeId || e?.preview?.id === this.lastPauseVod.episodeId
    );
  }

  get lastPauseEpisodeId() {
    return this.lastPauseEpisode?.id;
  }

  get mediaItemWithPause() {
    let item: any;

    if (this.hasSeries) {
      const getLastPauseEpisode = (episodes?: TVODTitlePreviewEnhanced[] | null) =>
        episodes?.find((episode) => episode.preview?.id === this.lastPauseVod.episodeId);

      item =
        this.seasons?.find((season) => getLastPauseEpisode(season.episodes)) ||
        getLastPauseEpisode(this.playingTitle?.episodes);
    } else {
      item = this.playingTitle;
    }

    return item?.details?.mediaItems?.find(
      (mediaItem: TMediaItem) => mediaItem.id === this.lastPauseVod.mediaItemId
    );
  }

  get lastPauseTitle() {
    const title = [];

    if (
      !this.hasSavedPause &&
      !this.lastPauseSeason &&
      !this.lastPauseEpisode &&
      !this.lastPauseEpisodeWithoutSeasons
    ) {
      return '';
    }

    if (!this.hasSeries && this.mediaItemWithPause?.title) {
      title.push(this.mediaItemWithPause.title);
      return title.join('. ');
    }

    if (this.seasonsLength) {
      title.push(
        this.lastPauseSeason?.title ||
          `${this.getTranslation('season')} ${this.lastPauseSeason?.number || ''}`
      );
      title.push(`${this.lastPauseEpisode?.title || this.lastPauseEpisode?.preview?.title || ''}`);
    } else {
      title.push(this.lastPauseEpisodeWithoutSeasons?.preview?.title || '');
    }

    return title.join('. ');
  }

  get epochDay() {
    return DateTime.toEpochDay(new Date(this.lastPauseTv.time));
  }

  get pauseTimeHM() {
    return DateTime.getHM(new Date(this.lastPauseTv.time));
  }

  get programs() {
    return (
      (this.currentChannelId &&
        selectors.tvEpg.programsSelector(this.$store, this.currentChannelId)) ||
      []
    );
  }

  get program() {
    const pauseTime = this.lastPauseTv.time;
    return this.programs.find(
      (program) => pauseTime >= program.startMs && pauseTime <= program.endMs
    );
  }

  get date() {
    if (!this.program) {
      return;
    }
    return DateTime.getDMonth(new Date(this.program.startMs));
  }

  get pauseDescription() {
    if (this.isTv) {
      return this.date && this.pauseTimeHM && this.program?.title
        ? `${this.date}, ${this.pauseTimeHM}. ${this.program.title}`
        : '';
    } else {
      return this.lastPauseTitle;
    }
  }

  get hasSeries() {
    return this.playingTitle?.preview?.hasSeries;
  }

  get lastPauseSeason() {
    return this.seasons?.find((s) => s?.id === this.lastPauseVod.seasonId);
  }

  get lastPauseEpisodeWithoutSeasons() {
    return this.getEpisodes()?.find(
      (e: TVODTitlePreviewEnhanced) => e?.preview?.id === this.lastPauseVod.episodeId
    );
  }

  get hasSavedPause() {
    if (!this.lastPause.id) {
      return false;
    }

    // for single titles
    if (!this.hasSeries) {
      return true;
    }

    // for episodes with series
    if (this.seasonsLength) {
      // within seasons
      return !!this.lastPauseSeason?.episodes.find(
        (e) =>
          e?.id === this.lastPauseVod.episodeId || e?.preview?.id === this.lastPauseVod.episodeId
      );
    } else if (this.getEpisodes()?.length) {
      // without seasons
      return !!this.lastPauseEpisodeWithoutSeasons;
    }
  }

  get isSavedPause() {
    return selectors.vod.isSavedPause(this.$store);
  }

  set isSavedPause(val) {
    actions.vod.setIsSavedPause(this.$store, val);
  }

  async goToPause() {
    if (!this.isVod) {
      actions.player.updatePlayingTime(this.$store, this.lastPauseTv.time);
    }

    switch (this.playerType) {
      case 'tv':
        actions.player.setIsLive(this.$store, false);
        actions.player.setPickedEpochDay(this.$store, this.epochDay);
        break;
      case 'vod':
        try {
          if (this.title.preview?.hasSeries && this.lastPauseEpisodeId) {
            actions.vod.setCurrentSeasonNum(this.$store, this.currentSeasonNumForNav);
            await actions.vod.playVideo(
              this.$store,
              this.sourceId,
              this.title,
              this.lastPauseEpisodeId
            );
          } else {
            await actions.vod.playVideo(this.$store, this.sourceId, this.title);
          }

          actions.vod.setPlayingCurrentTime(
            this.$store,
            convertToSeconds(this.lastPauseVod?.fromStart, 'millisecond')
          );
        } catch {
          // do nothing, errors should already be logged and printed
        }
        break;
      case 'archive':
        if (this.lastPauseVod?.mediaItemId) {
          if (this.hasSeries) {
            await actions.archive.playVideo(this.$store, {
              titleId: this.playingTitleId,
              episodeId: this.lastPauseEpisodeId,
              mediaItemId: this.lastPauseVod?.mediaItemId,
              fromStart: this.lastPauseVod?.fromStart,
            });
          } else {
            await actions.archive.playVideo(this.$store, {
              titleId: this.playingTitleId,
              mediaItemId: this.lastPauseVod?.mediaItemId,
              fromStart: this.lastPauseVod?.fromStart,
            });
          }
        }
        break;
    }

    this.$events.emit(EVENTS.player.play);
    this.$events.emit(EVENTS.player.reloadStream);
    this.gaEvent({ category: 'player_controls', action: 'Клик по кнопке "Продолжить просмотр"' });
  }

  async showChannelDetails() {
    await actions.tvChannels.showChannelDetails(this.$store, this.currentChannelId, true);
    if (getIsFullscreen()) {
      actions.player.exitFullscreen(this.$store);
    }
  }

  async toggleChannelFavorite() {
    this.gaEvent({ category: 'player_controls', action: 'to_favourites' });
    if (this.currentChannel) {
      await actions.tvChannels.toggleChannelFavorite(this.$store, this.currentChannelId);
    }

    const { favouriteChannels } = await api.channels.getRecentAndFavChannels();
    Vue.set(this.$store.tvChannels, 'favorites', favouriteChannels || []);
  }

  toggleLanguageSubMenu() {
    this.isMainMenuVisible = !this.isMainMenuVisible;
    this.isLanguageSubMenuVisible = !this.isLanguageSubMenuVisible;
  }

  toggleRenditionSubMenu() {
    this.isMainMenuVisible = !this.isMainMenuVisible;
    this.isRenditionSubMenuVisible = !this.isRenditionSubMenuVisible;
  }

  toggleVideoScale() {
    this.$store.player.video.scaled = !this.isVideoScaled;
    log.info("video's scaled flag is set to ", this.isVideoScaled);

    this.gaEvent({
      category: 'player_controls',
      action: `${this.isVideoScaled ? 'Включить' : 'Выключить'} увеличение видео`,
    });
  }

  toggleWatch() {
    this.$store.player.isDateTimeVisible = !this.isDateTimeVisible;
    log.info("player's isDateTimeVisible flag is set to", this.isDateTimeVisible);

    this.gaEvent({
      category: 'player_controls',
      action: `${this.isDateTimeVisible ? 'Отображать' : 'Скрыть'} часы`,
    });
  }

  toggleSubtitles() {
    this.$store.player.video.subtitleDisplay = !this.subtitleDisplay;
    log.info(`video's subtitles are O${this.subtitleDisplay ? 'N' : 'FF'}`);

    this.gaEvent({
      category: 'player_controls',
      action: `${this.subtitleDisplay ? 'Отображать' : 'Скрыть'} субтитры`,
    });
  }

  async toggleFps() {
    await actions.tvChannels.loadPlaybackInfoDetails(this.$store, this.currentChannel, !this.fps50);
    this.$store.player.video.fps50 = !this.fps50;
    actions.player.goLive(this.$store, this.$events);
    log.info("player's 50fps flag is set to", this.fps50);
  }

  selectLanguage(languageIndex: number) {
    this.gaEvent({
      category: 'player_controls',
      action: 'Изменить язык',
      control_type: 'mouse',
    });
    const isLanguageAvailable = selectors.tvCurrentChannel.isLanguageAvailableSelector(
      this.$store,
      languageIndex
    );
    if (isLanguageAvailable) {
      actions.tvCurrentChannel.setLanguageIndex(this.$store, languageIndex);
      actions.tvCurrentChannel.setRenditionIndex(this.$store, 0);
      this.$store.player.video.wasLanguageChanged = true;
      this.$events.emit(EVENTS.player.reloadStream);
    } else {
      actions.player.setAlert(this.$store, this.getTranslation('language_unavailable'));
    }
  }

  selectRendition(renditionIndex: number) {
    this.gaEvent({
      category: 'player_controls',
      action: 'Изменить битрейт',
      control_type: 'mouse',
    });
    actions.tvCurrentChannel.setRenditionIndex(this.$store, renditionIndex);
    this.$events.emit(EVENTS.player.reloadStream);
  }

  showHelp() {
    if (getIsFullscreen()) {
      actions.player.exitFullscreen(this.$store);
    }

    actions.player.showHelp(this.$store);
  }
}
</script>

<style lang="scss">
@import 'divider-line';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-settings-menu {
  width: 220px;
  padding: 8px 0;
  background-color: rgba(var(--c-dark-400-rgb), 0.8);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);

  @include mobile {
    width: 230px;
  }

  .content-separator {
    width: auto;
    margin-right: 8px;
    margin-left: 8px;
  }
}
</style>
