<template>
  <transition name="fade-ease-out-slow">
    <div v-if="!isPlayerMinimized" class="player-head flex" :class="{ fullscreen: isFullscreen }">
      <div class="left">
        <PlayerButton v-if="isFullscreen && isTv" class="burger mr-16" @click="toggleBurger">
          <IconSVG :svg="IconBurger" :size="24" />
        </PlayerButton>

        <div v-if="currentChannel && isTv" class="flex">
          <PlayerHeadChannelTitle
            :number="getChannelNumber(currentChannel)"
            :name="getChannelTitle(currentChannel)"
            class="mr-16 mr-tablet-8 mr-mobile-8"
          />
          <ButtonBookmark
            v-if="!isAnonymous"
            size="large"
            :class="{ 'pointer-events-none': isDisabled }"
            :has-indicator="false"
            :active="currentChannelInFavorites"
            @click.native="toggleCurrentChannelFavorite"
            @mouseleave.native="$event.target.blur()"
          />
        </div>

        <div v-if="mobileTitle" class="mobile-title body2" v-text="mobileTitle" />
      </div>

      <div class="right">
        <PlayerControlLive v-if="isTv" class="control-button visible-mobile" />

        <PlayerButton
          v-if="!isTv"
          class="button-info visible-mobile"
          :class="{ disabled: isPlayerLoading }"
          data-cy="button-info"
          @click="showDetails"
        >
          <IconSVG :svg="IconInfo" />
        </PlayerButton>

        <PlayerButtonFullscreen
          v-if="isFullscreenAvailable"
          class="visible-mobile"
          @toggleFullscreen="$emit('toggleFullscreen')"
        />

        <PlayerSettings v-if="isMobile" class="visible-mobile" />

        <PlayerDateTime />

        <button
          v-if="!isFullscreen"
          :class="{ 'pointer-events-none': isDisabled }"
          type="button"
          class="button button-action button-close-fullsize"
          data-cy="close-fullsize-player"
          @click="
            $emit('closePlayer');
            gaEvent({
              category: 'player_controls',
              action: 'Закрытие плеера',
              player_type: `${playerType}_player`,
            });
          "
        >
          <IconSVG :svg="IconCross" />
        </button>

        <!--    TODO: keep it until further design update -->
        <!--      <div v-if="isFullscreen">-->
        <!--        <transition name="epg-info-full">-->
        <!--          <PlayerEpgInfoFull v-if="isFullscreen && isEpgInfoVisible" />-->
        <!--        </transition>-->
        <!--      </div>-->
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import * as api from 'src/api';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import PlayerEpgInfoFull from 'src/components/player/parts/tv/PlayerEpgInfoFull.vue';
import PlayerHeadChannelTitle from 'src/components/player/parts/tv/PlayerHeadChannelTitle.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconBurger from 'src/svg/burger.svg';
import IconCross from 'src/svg/cross.svg';
import IconInfo from 'src/svg/info.svg';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import IconBookmarkOn from 'src/svg/bookmark-on.svg';
import IconBookmarkOff from 'src/svg/bookmark-off.svg';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import IconSVG from 'src/components/IconSVG.vue';
import { getChannelNumber, getChannelTitle } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';
import PlayerDateTime from 'src/components/player/parts/common/PlayerDateTime.vue';
import { Prop, Watch } from 'vue-property-decorator';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import { getIsFullscreen } from 'src/utils';
import PlayerButtonFullscreen from 'src/components/player/parts/common/player-controls/PlayerButtonFullscreen.vue';
import logger from 'src/utils/logger';
import PlayerControlLive from 'src/components/player/parts/tv/PlayerControlLive.vue';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import PlayerSettings from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettings.vue';
import { getDeviceFlags } from 'src/utils/platform-detector';
import AddResizeListener from 'src/mixins/AddResizeListener';

const log = logger('player-head');

const CHANNEL_ACTION = {
  addFavorite: 'addFavorite',
  aboutChannel: 'aboutChannel',
};

@Component({
  components: {
    PlayerSettings,
    ButtonAction,
    PlayerControlLive,
    PlayerButtonFullscreen,
    MarqueeText,
    PlayerButton,
    PlayerHeadChannelTitle,
    PlayerEpgInfoFull,
    ButtonBookmark,
    PlayerDateTime,
    IconSVG,
  },
})
export default class PlayerHead extends mixins(SequoiaComponent, AddResizeListener) {
  IconBurger = IconBurger;
  IconCross = IconCross;
  IconInfo = IconInfo;
  IconMoreHorizontal = IconMoreHorizontal;

  isMobile = this.isMobileDevice || this.windowWidth < 600;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop({ default: true })
  iosFullscreenAvailable!: boolean;

  @Watch('windowWidth')
  onWindowWidthChange() {
    this.isMobile = this.isMobileDevice || this.windowWidth < 600;
  }

  get isMobileDevice() {
    return getDeviceFlags().isMobile;
  }

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get isPlayerLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isFullscreenAvailable() {
    const isFullscreenAvailable =
      process.env.VUE_ENV !== 'server'
        ? document.fullscreenEnabled ||
          document.mozFullscreenEnabled ||
          document.webkitFullscreenEnabled ||
          document.msFullscreenEnabled
        : false;

    return isFullscreenAvailable || this.iosFullscreenAvailable;
  }

  get sourceId() {
    return this.$route.params.sourceId || 'archive';
  }

  get playingTitle() {
    return selectors.vod.playingTitleSelector(this.$store, this.sourceId);
  }

  get seasons() {
    return this.playingTitle?.seasons || [];
  }

  get seasonsLength() {
    return this.seasons?.length || 0;
  }

  get playingEpisodeId() {
    return selectors.vod.playingEpisodeIdSelector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get currentChannelId() {
    return (
      this.currentChannel?.id ||
      selectors.tvCurrentChannel.currentChannelIdSelector(this.$store) ||
      ''
    );
  }

  get currentChannelInFavorites() {
    if (!this.currentChannel) {
      return false;
    }
    return selectors.tvCurrentChannel.isInFavoritesSelector(this.$store);
  }

  get isEpgInfoVisible() {
    return selectors.player.isEpgInfoVisibleSelector(this.$store);
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get isChannelInFavorites() {
    return this.favoriteChannels[this.currentChannelId];
  }

  get actionMenuChannel() {
    const actions = [
      {
        id: CHANNEL_ACTION.aboutChannel,
        key: '2',
        value: this.getTranslation('about_channel'),
        icon: IconInfo,
        action: () => this.showChannelDetails(),
      },
    ];

    if (!this.isAnonymous) {
      actions.unshift({
        id: CHANNEL_ACTION.addFavorite,
        key: '1',
        value: this.getTranslation(this.isChannelInFavorites ? 'favorites_off' : 'favorites_on'),
        icon: this.isChannelInFavorites ? IconBookmarkOn : IconBookmarkOff,
        action: () => this.toggleChannelFavorite(),
      });
    }

    return actions;
  }

  get mobileTitle() {
    const title = this.playingTitle?.preview?.title;
    const showMobileTitle = !this.isTv && title;
    return showMobileTitle ? title : '';
  }

  async toggleChannelFavorite() {
    this.gaEvent({ category: 'player_controls', action: 'to_favourites' });
    if (this.currentChannel) {
      await actions.tvChannels.toggleChannelFavorite(this.$store, this.currentChannelId);
    }

    const { favouriteChannels } = await api.channels.getRecentAndFavChannels();
    Vue.set(this.$store.tvChannels, 'favorites', favouriteChannels || []);
  }
  getChannelNumber(channel: TChannelEnhanced) {
    return getChannelNumber(channel);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  toggleBurger() {
    actions.player.showFullscreenMenu(this.$store);
    this.gaEvent({
      category: 'player_controls',
      action: 'Запуск бургер меню',
      control_type: 'mouse',
    });
  }

  async toggleCurrentChannelFavorite() {
    actions.metrics.setGaParams(this.$store, {
      category: 'player_controls',
      control_type: 'mouse',
    });
    await actions.tvCurrentChannel.toggleCurrentChannelFavorite(this.$store);
  }

  showDetails() {
    if (this.isTv) {
      this.showChannelDetails();
    } else {
      this.showTitleDetails();
    }
  }

  async showTitleDetails() {
    const titleId = this.playingTitle?.preview?.id;

    if (titleId) {
      await actions.vod.showTitleDetails(this.$store, this.sourceId, titleId, true);
    } else {
      log.warning("titleId was not found; cannot show title's details");
    }

    if (getIsFullscreen()) {
      actions.player.exitFullscreen(this.$store);
    }
  }

  async showChannelDetails() {
    await actions.tvChannels.showChannelDetails(this.$store, this.currentChannelId, true);
    if (getIsFullscreen()) {
      actions.player.exitFullscreen(this.$store);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.player-head {
  position: relative;
  z-index: var(--z-8);
  align-items: flex-start;
  justify-content: space-between;
  padding: 48px 64px 0;
  margin-bottom: auto;

  @include tablet {
    padding-right: 48px;
    padding-left: 48px;
  }

  @include mobile {
    padding-top: 8px;
    padding-right: 8px;
    padding-left: 24px;
  }

  .flex {
    flex-wrap: nowrap;
    align-items: center;
  }

  .player-channel-title {
    cursor: default;
  }

  .player-channel-title,
  .button.bookmark {
    position: relative;
    z-index: var(--z-2);
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      justify-content: left;
      width: 50%;
      max-width: calc(100% - 140px);
      height: 40px;
    }

    .burger {
      position: relative;
      z-index: var(--z-2);
      display: flex;

      @media (max-width: #{$desktop-s-max}) {
        display: none;
      }

      @include devices-with-touch {
        display: none;
      }
    }

    .mobile-title {
      display: none;
      @extend %truncate-after-1-line;

      @include mobile {
        display: block;
      }
    }

    .button.bookmark {
      @include mobile {
        display: none;
      }
    }
  }

  .right {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    width: 25%;
    min-width: 140px;

    @include mobile {
      > *:not(.player-date-time) {
        position: relative;
        z-index: var(--z-3);
        flex-shrink: 0;
        height: 40px;
        background-color: transparent;

        &.control-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
        }
      }

      .menu-dropdown {
        margin: 0;
      }
    }

    .button-close-fullsize {
      z-index: var(--z-2);
      width: 40px;
      height: 40px;
      padding: 8px;
      margin-right: -8px;
      margin-left: 32px;

      @media (min-width: #{$desktop-s-min}) {
        margin-top: 4px;
      }

      @include desktop-s {
        margin-left: 24px;
      }

      @include mobile-and-tablet {
        margin-left: 16px;
      }

      @include mobile {
        display: none;
      }
    }
  }

  // TODO: leave it until further design update
  //.epg-info-full-enter-active {
  //  animation: epg-info-full-appear 0.35s forwards;
  //}
  //
  //.epg-info-full-leave-active {
  //  animation: epg-info-full-appear 0.25s reverse forwards;
  //}
  //
  //@keyframes epg-info-full-appear {
  //  0% {
  //    transform: translateX(425px);
  //  }
  //
  //  100% {
  //    transform: translateX(0);
  //  }
  //}
}
</style>
