<template>
  <transition appear name="genres-transition" mode="out-in">
    <div class="genres">
      <div class="scroll-wrap">
        <div
          v-for="(genre, i) in genresFiltered"
          :key="genre.title + genre.id + i"
          class="genre"
          :class="{ active: currentGenre === genre.title }"
          @click="selectGenre(genre.title)"
        >
          <IconSVG :svg="IconAir" :svg-path="getGenreIconPath(genre)" :size="20" />
          <span class="title" v-html="getGenreName(genre.title)" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import IconSVG from 'src/components/IconSVG.vue';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { TGenre } from 'src/store/tv-channels/types';
import IconAir from 'src/svg/genres/air.svg';

@Component({
  components: {
    IconSVG,
  },
})
export default class PlayerFullscreenGenres extends SequoiaComponent {
  IconAir = IconAir;
  get genres() {
    return selectors.tvChannels.genresSelector(this.$store);
  }

  get genresFiltered() {
    return this.genres.filter((genre) => this.getFilteredChannels(genre.title).length);
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  getFilteredChannels(genre: string) {
    return Object.values(selectors.tvChannels.filteredChannelsSelector(this.$store, genre, true));
  }

  getGenreName(genreId: string) {
    return selectors.tvChannels.genreTitleSelector(this.$store, genreId);
  }

  getGenreIconPath(genre: TGenre) {
    return selectors.tvChannels.genreIconSelector(this.$store, genre);
  }

  selectGenre(genre: string) {
    this.$emit('setIsEpgOpen', false);
    actions.tvChannels.selectGenre(this.$store, genre);
    this.$emit('setIsProgramInfoOpen', false);
    this.$emit('setIsChannelInfoVisible', true);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.genres {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 208px;
  height: 100%;
  padding-right: 4px;
  margin-right: 24px;
  margin-left: 64px;
  opacity: 0.5;

  @include devices-with-hover {
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: #{$desktop-s-max}) {
    width: 54px;
    margin-right: 12px;
  }

  .scroll-wrap {
    width: 100%;
    height: 100%;
  }

  .genre {
    display: flex;
    padding: 8px;
    margin-bottom: 8px;
    color: var(--c-dark-font-secondary);
    cursor: pointer;
    border-radius: 4px;
    @extend %body1;

    @media (max-width: #{$desktop-s-max}) {
      width: 36px;
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-3);
      }
    }

    &.active {
      color: var(--c-dark-font-primary);
      background-color: var(--alpha-dark-5);
    }

    @media (max-width: #{$desktop-s-max}) {
      .title {
        display: none;
      }
    }

    .icon {
      flex-shrink: 0;
      margin-right: 8px;
      color: var(--c-dark-font-primary);
    }
  }
}

// --------------------------------------------
// Animation
// --------------------------------------------
.genres-transition-enter-active,
.genres-transition-leave-active {
  transition: transform 0.25s var(--ease-in-out), opacity 0.25s var(--ease-in-out);
}

.genres-transition-enter,
.genres-transition-leave-to {
  opacity: 0;
  transform: translateX(-64px);
}

.genres-transition-enter-to,
.genres-transition-leave {
  transform: translateX(0);
}
</style>
