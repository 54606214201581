<template>
  <PlayerButton
    class="play-pause-stop"
    :class="{ disabled: isDisabled }"
    data-cy="play-pause-stop"
    @click="$emit('click')"
  >
    <IconSVG v-if="!isPlayerPlaying" :svg="IconPlay" data-cy="play" />
    <IconSVG
      v-else-if="isPlayerPlaying && (!isTv || (isTv && !isDvrDisabled))"
      :svg="IconPause"
      data-cy="pause"
    />
    <IconSVG v-else :svg="IconStop" data-cy="stop" />
  </PlayerButton>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import IconPause from 'src/svg/player/pause.svg';
import IconStop from 'src/svg/player/stop.svg';

@Component({
  components: {
    PlayerButton,
    IconSVG,
  },
})
export default class PlayPauseStop extends Vue {
  IconPlay = IconPlay;
  IconPause = IconPause;
  IconStop = IconStop;

  @Prop({ default: false })
  isDisabled!: boolean;

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get isPlayerPlaying() {
    return selectors.player.isPlayingSelector(this.$store);
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.play-pause-stop {
  @include mobile {
    .icon {
      width: 48px;
      height: 48px;
    }
  }
}
</style>
