<template>
  <div class="player-progress-program">
    <div v-if="!isPlayerMinimized" class="top">
      <BadgeSequoia
        theme-forced="dark"
        :text="getTranslation(now ? 'schedule_line_now' : 'schedule_line_later')"
      />
      <div class="timing body1" v-text="time" />
    </div>
    <div
      v-if="programTitle && programTitle.trim()"
      class="program flex-center-vertical marquee-wrap"
      :class="{ now }"
      @mouseenter="freezeOverlay()"
      @mouseleave="releaseOverlay()"
    >
      <MarqueeText :text="programTitle" class="mb-0" :class="marqueTextClass" />
      <PlayerButton
        v-if="now"
        class="button-info"
        :class="{ disabled: isPlayerLoading }"
        data-cy="button-info"
        @click="showChannelDetails"
      >
        <IconSVG :svg="IconInfo" />
      </PlayerButton>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import { selectors } from 'src/store/selectors';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconInfo from 'src/svg/info.svg';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import { getIsFullscreen } from 'src/utils';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import Metrics from 'src/mixins/Metrics';

@Component({
  components: {
    BadgeSequoia,
    PlayerButton,
    MarqueeText,
    IconSVG,
  },
})
export default class PlayerProgressProgram extends mixins(Global, Metrics) {
  IconInfo = IconInfo;

  @Prop()
  time!: string;

  @Prop()
  label!: string;

  @Prop()
  programTitle!: string;

  @Prop()
  now?: boolean;

  get isPlayerLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store) || '';
  }

  get marqueTextClass() {
    return this.isPlayerMinimized ? 'h5' : this.now ? 'h3' : 'h4';
  }

  freezeOverlay() {
    actions.player.freezeOverlay(this.$store);
  }

  releaseOverlay() {
    actions.player.releaseOverlay(this.$store);
  }

  async showChannelDetails() {
    await actions.tvChannels.showChannelDetails(this.$store, this.currentChannelId, true);
    if (getIsFullscreen()) {
      actions.player.exitFullscreen(this.$store);
    }
    this.gaEvent({
      category: 'player_controls',
      action: 'Клик по кнопке "Info"',
      channel_name: this.currentChannelId,
    });
  }

  // TODO: keep it until further design update
  // toggleEpgInfo() {
  //   actions.player.toggleEpgInfo(this.$store);
  // }
}
</script>

<style lang="scss" scoped>
.player-progress-program {
  display: flex;
  flex-direction: column;

  .top {
    position: relative;
    z-index: var(--z-2);
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: default;

    .badge {
      margin-right: 8px;
    }
  }

  .program {
    flex-wrap: nowrap;
    color: var(--c-dark-font-secondary);

    &.now {
      color: var(--c-dark-font-primary);
    }

    .marquee-text {
      cursor: default;
    }
  }
}
</style>
