<template>
  <transition name="epg-transition" @after-enter="afterEpgEnter">
    <div v-if="isEpgOpen && !isChannelInfoVisible" class="epg">
      <PlayerButton class="close-epg" @click="closeEpg">
        <IconSVG :svg="IconAngleLeft" />
        <span class="text" v-html="getTranslation('back')" />
      </PlayerButton>

      <PlayerFullscreenEpgHead
        :epg-channel="epgChannel"
        @selectChannel="selectChannel"
        @closeEpg="closeEpg"
      />

      <template v-if="programs.length">
        <PlayerFullscreenEpgCalendar :calendar="calendar" />

        <div
          ref="epgPrograms"
          class="epg-programs scroll-wrap"
          :class="{ locked: isChannelLocked(epgChannel) }"
        >
          <div
            v-for="program in programs"
            :key="program.id"
            class="epg-program"
            :class="{
              live: isProgramLive(program),
              active: isProgramActive(program),
              inactive: isProgramInFuture(program) || isChannelLocked(epgChannel),
              disabled: isDvrDisabledForChannel(epgChannel) && !isProgramLive(program),
              open: program.id === programInfoId,
            }"
          >
            <div class="hover-wrap">
              <span
                class="epg-program-start"
                :class="shouldDisableClicks(epgChannel, program)"
                @click="switchProgram(program)"
                v-text="getHM(new Date(program.startMs))"
              />
              <button
                v-if="shouldShowPlayButton(epgChannel, program)"
                type="button"
                class="epg-play"
                @click="switchProgram(program)"
              >
                <IconSVG :svg="IconPlay" :size="16" />
              </button>
              <button
                v-if="program && (program.title || (program.metaInfo && program.metaInfo.title))"
                type="button"
                class="epg-program-title"
                :class="shouldDisableClicks(epgChannel, program)"
                @click="switchProgram(program)"
                v-text="program.title || program.metaInfo.title"
              />
            </div>
            <IconSVG v-if="isProgramLive(program)" :svg="IconAir" class="icon-live" />
            <PlayerButton
              class="epg-info"
              :class="{ active: program.id === programInfoId }"
              @click="showProgramInfo(program)"
            >
              <IconSVG :svg="IconInfo" />
            </PlayerButton>
          </div>
        </div>
      </template>

      <div v-else class="body1" v-html="getTranslation('program_is_not_found')" />
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Ref } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconInfo from 'src/svg/info.svg';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconPlay from 'src/svg/player/play.svg';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerFullscreenEpgCalendar from './PlayerFullscreenEpgCalendar.vue';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import { DateTime } from 'src/utils/time/date-time';
import { selectors } from 'src/store/selectors';
import * as epg from 'src/utils/epg';
import { EPG_TOP_MARGIN } from 'src/constants';
import PlayerFullscreenEpgHead from 'src/components/player/parts/tv/fullscreen/PlayerFullscreenEpgHead.vue';
import { TChannelEnhanced, TProgramEnhanced } from 'src/api/channels/types';
import IconAir from 'src/svg/genres/air.svg';

@Component({
  components: {
    PlayerFullscreenEpgHead,
    IconSVG,
    PlayerButton,
    PlayerFullscreenEpgCalendar,
    ButtonBookmark,
  },
})
export default class PlayerFullscreenEpg extends SequoiaComponent {
  IconAir = IconAir;
  IconPlay = IconPlay;
  IconInfo = IconInfo;
  IconAngleLeft = IconAngleLeft;

  getHM = DateTime.getHM;
  programInfoId = '';

  @Prop({ required: true })
  isEpgOpen!: boolean;

  @Prop({ required: true })
  isChannelInfoVisible!: boolean;

  @Prop()
  selectedChannel!: TChannelEnhanced;

  @Prop()
  epgChannel!: TChannelEnhanced;

  @Prop()
  selectedProgram!: TProgramEnhanced;

  @Ref('epgPrograms')
  refEpgPrograms?: HTMLDivElement;

  get calendar() {
    if (this.epgChannel) {
      return epg.getCalendarByChannelId(this.$store, this.epgChannel.id);
    }
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get programs() {
    return (
      this.calendar?.find(
        (day) => day.epochDay === selectors.player.pickedEpochDaySelector(this.$store)
      )?.programs || []
    );
  }

  get playingTimeMs() {
    return selectors.player.playingTimeMsSelector(this.$store);
  }

  afterEpgEnter() {
    if (!this.refEpgPrograms) {
      return false;
    }

    const programTop =
      this.refEpgPrograms.querySelector('.live')?.getBoundingClientRect().top ||
      this.refEpgPrograms.querySelector('.inactive')?.getBoundingClientRect().top;

    if (programTop) {
      this.refEpgPrograms.scrollTop = programTop - EPG_TOP_MARGIN;
    }
  }

  isChannelLocked(channel: TChannelEnhanced) {
    return selectors.tvChannels.isChannelLockedSelector(this.$store, channel);
  }

  isDvrDisabledForChannel(channel: TChannelEnhanced) {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store, channel);
  }

  shouldShowPlayButton(channel: TChannelEnhanced, program: TProgramEnhanced) {
    return (
      (!this.isDvrDisabledForChannel(channel) || this.isProgramLive(program)) &&
      !this.isChannelLocked(channel) &&
      program.startMs < new Date().getTime()
    );
  }

  shouldDisableClicks(channel: TChannelEnhanced, program: TProgramEnhanced) {
    return (this.isDvrDisabledForChannel(channel) && !this.isProgramLive(program)) ||
      this.isChannelLocked(channel) ||
      this.isProgramInFuture(program)
      ? 'pointer-events-none'
      : '';
  }

  closeEpg() {
    this.programInfoId = '';
    setTimeout(() => {
      this.$emit('setIsChannelInfoVisible', true);
    }, 500);
    this.$emit('setIsProgramInfoOpen', false);
    this.$emit('setIsEpgOpen', false);
    this.$emit('setSelectedChannelId', '');
  }

  isProgramLive(program: TProgramEnhanced) {
    return epg.isProgramLive(this.$store, program);
  }

  isProgramInFuture(program: TProgramEnhanced) {
    return epg.isProgramInFuture(program);
  }

  // current program in player
  isProgramActive(program: TProgramEnhanced) {
    return (
      this.selectedChannel.id === this.currentChannelId &&
      this.playingTimeMs > program.startMs &&
      this.playingTimeMs < program.endMs
    );
  }

  switchProgram(program: TProgramEnhanced) {
    this.$emit('switchProgram', program);
    this.gaEvent({
      category: 'player_controls',
      action: 'Запуск передачи (полноэкранный плеер)',
      title_id: program.title,
    });
  }

  showProgramInfo(program: TProgramEnhanced) {
    this.programInfoId = program.id;
    this.$emit('setIsProgramInfoOpen', true);
    this.$emit('setSelectedProgram', program);
    this.gaEvent({
      category: 'player_controls',
      action: 'Инфо о передаче',
      title_id: program.title,
    });
  }

  selectChannel(e: Event) {
    this.$emit('selectChannel', e);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

$epg-transition: opacity 0.5s, visibility 0.5s linear, color 0.15s var(--ease-in-out);

.epg {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  margin-left: 32px;
  opacity: 0.5;

  @include desktop-s {
    max-width: 360px;
    margin-left: 24px;
  }

  &:hover {
    opacity: 1;
  }

  .close-epg {
    align-self: start;
    margin-bottom: 16px;
  }

  .calendar {
    padding: 0 40px;
  }

  .epg-programs {
    height: 100%;
    margin-top: 16px;

    &:not(.locked) {
      .epg-program {
        &.disabled {
          pointer-events: initial;

          .hover-wrap {
            cursor: default;
          }
        }

        &:not(.inactive):not(.disabled) {
          .hover-wrap {
            cursor: pointer;

            &:hover {
              background-color: var(--alpha-dark-3);

              .epg-program-start {
                visibility: hidden;
                opacity: 0;
              }

              .epg-play {
                visibility: visible;
                opacity: 1;
                transition-delay: 0s;
              }
            }

            &:active {
              background-color: var(--alpha-dark-5);
            }
          }
        }
      }
    }

    &.locked {
      .epg-program {
        &:hover {
          .epg-program-start {
            visibility: initial;
            opacity: initial;
          }
        }
      }
    }

    .epg-program {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding-left: 4px;
      margin-bottom: 8px;
      @extend %body1;

      &:hover {
        .epg-info {
          visibility: visible;
          opacity: 1;
          transition-delay: 0s;
        }
      }

      &.open,
      &.open.live {
        .epg-info {
          opacity: 1;
        }

        .icon-live {
          display: none;
        }
      }

      &.live {
        &:hover {
          .icon-live {
            opacity: 0;
          }
        }

        .icon-live {
          position: absolute;
          right: 8px;
          color: var(--c-light-brand);
          opacity: 1;
          transition: $epg-transition;
        }
      }

      &.active {
        .epg-program-title,
        .epg-program-start {
          color: var(--c-light-brand);
        }
      }

      &.inactive {
        color: var(--c-dark-font-tertiary);
        cursor: default;
      }

      .hover-wrap {
        display: flex;
        flex-grow: 1;
        align-items: center;
        width: calc(100% - 48px);
        height: 100%;
        padding-right: 16px;
        padding-left: 8px;
        margin-right: 8px;
        cursor: default;
        border-radius: 4px;
        transition: background 0.5s var(--ease-out);
      }

      .epg-program-start {
        width: 42px;
        transition: $epg-transition;
        transition-delay: 0s;
      }

      .epg-play {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: $epg-transition;
      }

      .epg-program-title {
        flex-grow: 2;
        margin-left: 8px;
        color: inherit;
        text-align: left;
        @extend %truncate-after-1-line;
      }

      .epg-info {
        opacity: 0;
        transition: $epg-transition;
      }
    }
  }
}

// --------------------------------------------
// Animation
// --------------------------------------------
.epg-transition-enter-active,
.epg-transition-leave-active {
  transition: transform 0.25s var(--ease-in-out), opacity 0.25s var(--ease-in-out);
}

.epg-transition-leave-active {
  pointer-events: none;
}

.epg-transition-enter,
.epg-transition-leave-to {
  opacity: 0;
  transform: translateX(-32px);

  @include desktop-s {
    transform: translateX(-24px);
  }
}

.epg-transition-enter-to,
.epg-transition-leave {
  transform: translateX(0);
}
</style>
