<template>
  <div
    class="timeline-balloon body2"
    :style="{ left: `${balloonPosition * 100}%` }"
    v-text="balloonTime"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class TimelineBalloon extends Vue {
  @Prop({ required: true })
  balloonPosition!: number;

  @Prop({ required: true })
  balloonTime!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.timeline-balloon {
  position: absolute;
  bottom: 14px;
  z-index: var(--z-smart-banner);
  padding: 4px 8px;
  color: var(--c-dark-font-primary);
  text-align: center;
  background-color: var(--c-dark-400);
  border-radius: 8px;
  backdrop-filter: blur(10px);

  @include mobile {
    padding: 2px 8px;
  }
}
</style>
