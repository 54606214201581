import { render, staticRenderFns } from "./PlayerSettingsButtonNav.vue?vue&type=template&id=7aa8cdda&scoped=true&"
import script from "./PlayerSettingsButtonNav.vue?vue&type=script&lang=ts&"
export * from "./PlayerSettingsButtonNav.vue?vue&type=script&lang=ts&"
import style0 from "./PlayerSettingsButtonNav.vue?vue&type=style&index=0&id=7aa8cdda&prod&lang=scss&"
import style1 from "./PlayerSettingsButtonNav.vue?vue&type=style&index=1&id=7aa8cdda&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa8cdda",
  null
  
)

export default component.exports