<template>
  <div
    ref="timeline"
    class="player-progress-timeline vod-timeline"
    :class="[{ disabled: isDisabled }, $store.theme]"
  >
    <div class="played" :style="{ width: `${playedWidth * 100}%` }" />

    <template v-if="isSmotreshkaPlaybackInfo">
      <div
        ref="curProgramTick"
        class="tick left"
        :style="{ left: `calc(${ticksOffset * 100}% - 5px)` }"
      />
      <div
        ref="nextProgramTick"
        class="tick right"
        :style="{ left: `calc(${(1 - ticksOffset) * 100}% + 5px)` }"
      />
    </template>

    <div
      class="transparent-overlay"
      @mouseenter="timelineHovered = true"
      @mouseleave="
        timelineHovered = false;
        timelineHoverX = null;
      "
      @mousemove="timelineHoverX = $event.offsetX / $event.target.offsetWidth"
      @click="clickOnTimeline"
    />

    <div class="current-time-control" :style="{ left: `calc(${playedWidth * 100}% - 15px)` }" />

    <TimelineBalloon
      v-if="showBalloon"
      :balloon-position="balloonPosition"
      :balloon-time="balloonTime"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { TPlayer } from 'src/store/player/types';
import { convertToSeconds } from 'src/utils/time/convert-time';
import TimelineBalloon from 'src/components/player/parts/common/TimelineBalloon.vue';
import { boundMinMax } from 'src/utils/number';
import { DateTime } from 'src/utils/time/date-time';
import { EVENTS } from 'src/constants';

@Component({
  components: { TimelineBalloon },
})
export default class PlayerProgressTimelineArchive extends SequoiaComponent {
  timelineHovered = false;
  timelineHoverX: number | null = null;
  timelineHoverXAfterClick: number | null = null;
  balloonWidthPx = 70;
  player!: TPlayer;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Ref('timeline')
  refTimeline!: HTMLDivElement;

  get isSmotreshkaPlaybackInfo() {
    return selectors.archive.isSmotreshkaPlaybackInfoSelector(this.$store);
  }

  get ticksOffset() {
    return selectors.vod.playingTickOffsetSelector(this.$store);
  }

  get titleDurationMs() {
    return selectors.archive.titleDurationMsSelector(this.$store);
  }

  get timelineDurationMs() {
    return selectors.archive.timelineDurationMsSelector(this.$store);
  }

  get timelineStartMs() {
    return selectors.archive.timelineStartsMsSelector(this.$store);
  }

  get playingCurrentTime() {
    return selectors.vod.playingCurrentTimeSelector(this.$store);
  }

  /**
   * Width of a played area from the beginning of the timeline
   */
  get playedWidth() {
    return this.getPositionByTimestamp(this.timelineStartMs + this.playingCurrentTime);
  }

  get showBalloon() {
    return this.timelineHovered;
  }

  get balloonTimestamp() {
    let timestamp = convertToSeconds(this.playingCurrentTime, 'millisecond');
    if (this.timelineHoverX) {
      timestamp = this.timelineHoverX * convertToSeconds(this.timelineDurationMs, 'millisecond');
    }
    return timestamp;
  }

  get balloonTime() {
    if (!this.showBalloon) {
      return '';
    }
    return DateTime.prettyTimeHMS(this.balloonTimestamp);
  }

  get balloonPosition() {
    if (!this.timelineHovered) {
      return 0;
    }
    const balloonWidthPercent = this.balloonWidthPx / this.refTimeline.clientWidth;
    const rightBound = 1 - balloonWidthPercent;
    const halfWidth = balloonWidthPercent / 2;
    const balloonCenterPosition = this.timelineHoverX || this.timelineHoverXAfterClick || 0;
    return boundMinMax(0, balloonCenterPosition - halfWidth, rightBound);
  }

  get timelineHoverTimestamp() {
    if (!this.timelineHoverX) {
      return 0;
    }
    return Math.round(this.timelineStartMs + this.timelineHoverX * this.timelineDurationMs);
  }

  clickOnTimeline(event: any) {
    this.timelineHovered = true;
    this.timelineHoverXAfterClick = this.timelineHoverX;

    const newCurrentTime = (event.layerX / this.refTimeline.clientWidth) * this.timelineDurationMs;
    if (!isNaN(newCurrentTime)) {
      this.$emit('setCurrentTime', newCurrentTime);
    }
    this.$events.emit(EVENTS.player.reloadStream);
  }

  getPositionByTimestamp(timestamp: number) {
    if (!this.titleDurationMs) {
      return 0;
    }
    const lengthMs = timestamp - this.timelineStartMs;
    return boundMinMax(0, lengthMs / this.timelineDurationMs, 1);
  }
}
</script>

<style lang="scss">
@import 'src/components/player/parts/player-timeline';
</style>
