<template>
  <span
    ref="container"
    class="marquee-text"
    :class="[
      isOverflowing && !startAnimation ? 'fade-right' : 'no-fade',
      isOverflowing && startAnimation ? ['fade-both', 'animate'] : '',
    ]"
    :style="cssVars"
    @mouseenter="toggleStartAnimation"
    @mouseleave="toggleStartAnimation"
  >
    <span class="text" v-html="text" />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';

@Component
export default class MarqueeText extends Vue {
  isOverflowing = false;
  startAnimation = false;
  marqueeWidth = 1;
  animationDuration = 3;
  actualizeCssVarsWithDebounce = debounce(this.actualizeCssVars, 200);

  @Prop({ required: true })
  text!: string;

  @Watch('text')
  onTextChange() {
    this.$nextTick(() => this.actualizeCssVarsWithDebounce());
  }

  @Watch('player.video.isFullscreen')
  onFullscreenChange() {
    this.actualizeCssVarsWithDebounce();
  }

  @Ref('container')
  refContainer?: HTMLSpanElement;

  get cssVars() {
    return {
      '--marquee-width': `-${this.marqueeWidth}px`,
      '--animation-duration': `${this.animationDuration}s`,
    };
  }

  mounted() {
    this.actualizeCssVarsWithDebounce();
    window.addEventListener('resize', this.actualizeCssVarsWithDebounce);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.actualizeCssVarsWithDebounce);
  }

  actualizeCssVars() {
    const container = this.refContainer;
    if (!container) {
      return;
    }
    const gap = 40; // space between the end of the text and a container's right border
    const textWidth = container.scrollWidth;
    const containerWidth = container.offsetWidth;
    this.marqueeWidth = textWidth - containerWidth + gap; // diff between container and text width + gap
    this.marqueeWidth = Math.max(this.marqueeWidth, 1);
    this.isOverflowing = textWidth > container.offsetWidth;
    this.animationDuration = Math.floor((textWidth / containerWidth) * 4);
    this.animationDuration = Math.max(this.animationDuration, 3);
  }

  toggleStartAnimation() {
    this.startAnimation = !this.startAnimation;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/fade';

.marquee-text {
  position: relative;
  z-index: var(--z-2);
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  @include fade-none;

  &.fade-right {
    @include fade-right;
  }

  .text {
    position: relative;
    display: inline-block;
  }

  &.animate .text {
    animation: run-text var(--ease-in-out) var(--animation-duration) infinite;
  }

  &.fade-both {
    animation: fade-both var(--ease-in-out) var(--animation-duration) infinite;
  }

  @keyframes run-text {
    0%,
    5% {
      transform: translateX(0);
    }

    85% {
      opacity: 1;
      transform: translateX(var(--marquee-width));
    }

    98% {
      transform: translateX(var(--marquee-width));
    }

    100% {
      opacity: 0;
      transform: translateX(0);
    }
  }

  @keyframes fade-both {
    0% {
      @include fade-right;
    }

    25%,
    96% {
      @include fade-both;
    }

    100% {
      @include fade-right;
    }
  }
}
</style>
