<template>
  <div class="player-settings-block body2">
    <PlayerSettingsButtonNav :title="title" @click="$emit('close')" />

    <ContentSeparator theme-forced="dark" />

    <PlayerSettingsButtonSelector
      v-for="(setting, index) in settings"
      :key="setting.id"
      :title="setting.caption"
      :active="index === active"
      @click="$emit('select', index)"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PlayerSettingsButtonNav from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsButtonNav.vue';
import IconSVG from 'src/components/IconSVG.vue';
import PlayerSettingsButtonSelector from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsButtonSelector.vue';
import ContentSeparator from 'src/components/ui/ContentSeparator.vue';

@Component({
  components: { ContentSeparator, PlayerSettingsButtonSelector, PlayerSettingsButtonNav, IconSVG },
})
export default class PlayerSettingsOptions extends Vue {
  @Prop()
  title!: string;

  @Prop()
  settings!: { id: string; caption: string }[];

  @Prop()
  active?: number;
}
</script>

<style lang="scss">
@import 'divider-line';
</style>

<style lang="scss" scoped>
.player-settings-block {
  .flex {
    .left {
      margin-right: -16px;
    }

    .right {
      margin-left: 24px;
    }
  }

  .content-separator {
    width: auto;
    margin-right: 8px;
    margin-left: 8px;
  }
}
</style>
