<template>
  <div v-click-outside="hideMenu" class="player-settings">
    <PlayerButton :class="{ active: isSettingsMenuVisible }" @click="toggleMenu">
      <IconSVG :svg="IconMoreHorizontal" />
    </PlayerButton>
    <transition name="fade-ease-out-default">
      <PlayerSettingsMenu v-if="isSettingsMenuVisible" />
    </transition>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import PlayerSettingsMenu from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettingsMenu.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconSettings from 'src/svg/player/settings.svg';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import { clickOutside } from 'src/utils/directives';
import { Watch } from 'vue-property-decorator';
import AddResizeListener from 'src/mixins/AddResizeListener';
import { getDeviceFlags } from 'src/utils/platform-detector';

@Component({
  components: { PlayerSettingsMenu, PlayerButton, IconSVG },
  directives: { clickOutside },
})
export default class PlayerSettings extends mixins(SequoiaComponent, AddResizeListener) {
  IconSettings = IconSettings;
  IconMoreHorizontal = IconMoreHorizontal;

  isMobile = this.isMobileDevice || this.windowWidth < 600;

  @Watch('windowWidth')
  onWindowWidthChange() {
    this.isMobile = this.isMobileDevice || this.windowWidth < 600;
  }

  get isMobileDevice() {
    return getDeviceFlags().isMobile;
  }

  get isSettingsMenuVisible() {
    return selectors.player.isSettingsMenuVisibleSelector(this.$store);
  }

  showOverlay() {
    actions.player.showOverlay(this.$store);
  }

  toggleMenu() {
    if (this.isSettingsMenuVisible) {
      this.hideMenu();
    } else {
      this.showOverlay();
      this.showMenu();
      this.gaEvent({
        category: 'player_controls',
        action: 'Показать настройки',
        control_type: 'mouse',
      });
    }
  }

  showMenu() {
    actions.player.showSettingsMenu(this.$store);
  }

  hideMenu() {
    actions.player.hideSettingsMenu(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-settings {
  position: relative;
  z-index: var(--z-3);
  display: inline-flex;

  .player-settings-menu {
    position: absolute;
    right: 0;
    bottom: 48px;
    z-index: var(--z-8);
    height: fit-content;
    max-height: calc(100vh - 240px);
    overflow-y: auto;

    @media (max-height: 600px) and (min-width: 600px) {
      position: fixed;
      right: 50%;
      bottom: 45%;
      z-index: var(--z-3);
      max-height: 100%;
      transform: translate(50%, 50%);
    }

    @include mobile {
      position: absolute;
      top: 0;
      right: 48px;
      max-height: calc(100vh - 96px);
    }
  }
}
</style>
