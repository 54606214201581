<template>
  <div
    v-if="!isPlayerMinimized || (isPlayerMinimized && isFullscreen)"
    class="player-errors"
    :class="{ 'pointer-events-none': hasPlayerError }"
  >
    <transition name="fade-ease-out-slow">
      <div v-if="hasPlayerError && !isPlayerMinimized" class="errors" data-cy="errors">
        <PlayerError
          class="error"
          data-cy="player-error"
          :error="playerError"
          :title="errorTitle"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import PlayerError from 'src/components/player/parts/common/PlayerError.vue';
import { selectors } from 'src/store/selectors';
import { PLAYER_ERROR_CODES } from 'src/constants';

@Component({
  components: { PlayerError },
})
export default class PlayerErrors extends SequoiaComponent {
  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get playerError() {
    return selectors.player.errorSelector(this.$store);
  }

  get hasPlayerError() {
    return selectors.player.hasErrorSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get errorTitle() {
    return this.isTv || this.playerError?.code === PLAYER_ERROR_CODES.INTERNAL
      ? ''
      : this.getTranslation(`${this.playerType}_unavailable_title`);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-errors {
  position: absolute;
  bottom: 108px;
  left: 0;
  width: 35%;
  padding: 48px 32px 32px 64px;
  overflow: hidden;

  @include mobile-and-tablet {
    width: 100%;
  }

  @include tablet {
    padding-left: 48px;
  }

  @include mobile {
    position: relative;
    bottom: auto;
    left: auto;
    padding-bottom: 0;
    padding-left: 24px;
  }

  .error {
    + .error {
      margin-top: 16px;
    }
  }
}
</style>
