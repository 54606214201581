<template>
  <div
    v-if="currentProgram"
    class="epg-info-small color-dark-font-primary"
    @mouseenter="freezeOverlay()"
    @mouseleave="releaseOverlay()"
  >
    <div class="picture">
      <img v-if="currentProgramImage" :src="currentProgramImage" alt="" />
    </div>
    <div class="info">
      <div class="time body1">
        <div v-text="timeText" />
      </div>
      <div class="title h4" v-html="currentProgram.title" />
      <TextWithTruncation
        v-if="currentProgram.description"
        class="body2"
        :source-text="currentProgram.description"
        theme-forced="dark"
      />
    </div>
    <PlayerButton class="close" @click="hideEpgInfo">
      <IconSVG :svg="IconCross" />
    </PlayerButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerHelp from 'src/components/player/parts/tv/PlayerHelp.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import { getResizedImageUrl } from 'src/utils/images';

@Component({
  components: { PlayerButton, PlayerHelp, TextWithTruncation, IconSVG },
})
export default class PlayerEpgInfoBrief extends Vue {
  IconCross = IconCross;

  get timeText() {
    return `${this.currentProgram?.startTimeHM} – ${this.currentProgram?.endTimeHM}`;
  }
  get currentProgram() {
    return selectors.tvEpg.currentProgramSelector(this.$store);
  }

  get currentProgramImage() {
    return this.currentProgram?.image ? getResizedImageUrl(this.currentProgram.image, 168, 95) : '';
  }

  freezeOverlay() {
    actions.player.freezeOverlay(this.$store);
  }

  releaseOverlay() {
    actions.player.releaseOverlay(this.$store);
  }

  hideEpgInfo() {
    actions.player.hideEpgInfo(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.epg-info-small {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  cursor: default;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 1)
  );

  @include tablet {
    justify-content: flex-start;
  }

  .close {
    align-self: baseline;
  }

  .picture {
    flex-basis: 200px;
    padding-left: 32px;

    img {
      display: block;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .info {
    flex-basis: 50%;
    padding: 0 20px;
    //noinspection CssInvalidPropertyValue
    overflow: -moz-scrollbars-none;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  .time {
    margin-bottom: 4px;
  }

  .title {
    margin-bottom: 12px;
  }

  .text-with-truncation {
    padding-bottom: 30px;
  }
}
</style>
