<template>
  <transition name="fade-ease-out-slow">
    <div
      v-show="(!isPlayerMinimized || (isPlayerMinimized && isFullscreen)) && !hasError"
      class="player-controls"
    >
      <ButtonScrollToCatalog @scrollToCatalog="scrollToCatalog" />

      <div class="controls left">
        <template v-if="isTv">
          <div v-if="alertVisible" class="player-controls-group" :class="{ visible: alertVisible }">
            <PlayerAlert>
              <span v-html="alert" />
            </PlayerAlert>
          </div>

          <div v-if="!hasError" class="player-controls-group" :class="{ visible: !alertVisible }">
            <PlayerControlBackToPause v-if="lastPause.time" />
            <PlayerControlLive />
          </div>
        </template>

        <template v-else>
          <TimingBlock
            v-if="!hasError && duration > 1"
            :current-time="currentTime"
            :duration="duration"
          />
        </template>
      </div>

      <PlayerButtonsGroup class="controls center">
        <!-- rewind -->
        <!--suppress VueUnrecognizedDirective -->
        <PlayerButton
          v-if="isTv"
          v-show="!isDvrDisabled"
          v-mouseup-outside="stopRewind"
          :flip="true"
          :disabled="isDvrRestricted || isPlayerLoading"
          @mousedown.prevent="startRewind"
          @mouseup="stopRewind"
        >
          <IconSVG :svg="IconFastForward" />
        </PlayerButton>

        <PlayPauseStop :is-disabled="isDisabled" @click="$emit('togglePlayPause')" />

        <!-- fast-forward -->
        <!--suppress VueUnrecognizedDirective -->
        <PlayerButton
          v-if="isTv"
          v-show="!isDvrDisabled"
          v-mouseup-outside="stopFastForward"
          :disabled="isLive || isDvrRestricted || isPlayerLoading"
          @mousedown.prevent="startFastForward"
          @mouseup="stopFastForward"
        >
          <IconSVG :svg="IconFastForward" />
        </PlayerButton>
      </PlayerButtonsGroup>

      <!-- settings, volume, fullscreen -->
      <PlayerButtonsGroup class="controls right">
        <PlayerSettings v-if="!isMobile" />

        <PlayerVolume @actualizeVolume="$emit('actualizeVolume')" />

        <PlayerButtonFullscreen @toggleFullscreen="$emit('toggleFullscreen')" />
      </PlayerButtonsGroup>
    </div>
  </transition>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { HIDE_PLAYER_ALERT_DELAY, THRESHOLDS, MOUSE_EVENT_BUTTONS, EVENTS } from 'src/constants';
import { convertToSeconds } from 'src/utils/time/convert-time';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import IconFastForward from 'src/svg/player/fast-forward.svg';
import PlayerVolume from 'src/components/player/parts/common/player-controls/player-volume/PlayerVolume.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerButtonsGroup from 'src/components/player/parts/common/player-controls/PlayerButtonsGroup.vue';
import PlayerSettings from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettings.vue';
import PlayerControlLive from 'src/components/player/parts/tv/PlayerControlLive.vue';
import PlayerControlBackToPause from 'src/components/player/parts/tv/PlayerControlBackToPause.vue';
import PlayerAlert from 'src/components/player/parts/tv/PlayerAlert.vue';
import PlayPauseStop from 'src/components/player/parts/common/player-controls/PlayPauseStop.vue';
import PlayerButtonFullscreen from 'src/components/player/parts/common/player-controls/PlayerButtonFullscreen.vue';
import TimingBlock from 'src/components/player/parts/common/TimingBlock.vue';
import ButtonScrollToCatalog from 'src/components/player/parts/common/ButtonScrollToCatalog.vue';
import { getDeviceFlags } from 'src/utils/platform-detector';
import AddResizeListener from 'src/mixins/AddResizeListener';
import logger from 'src/utils/logger';

const log = logger('player-controls');

@Component({
  components: {
    ButtonScrollToCatalog,
    PlayerButtonFullscreen,
    PlayerVolume,
    PlayerButton,
    PlayerButtonsGroup,
    PlayerControlLive,
    PlayerControlBackToPause,
    PlayerAlert,
    PlayerSettings,
    PlayPauseStop,
    TimingBlock,
    IconSVG,
  },
})
export default class PlayerControls extends mixins(SequoiaComponent, AddResizeListener) {
  IconPlay = IconPlay;
  IconFastForward = IconFastForward;

  alertVisible = false;
  alertHideTimeout?: NodeJS.Timeout;
  alertClearTimeout?: NodeJS.Timeout;
  alertClearTimeoutMs = 3500;

  isMobile = this.isMobileDevice || this.windowWidth < 600;

  accumulationInterval: NodeJS.Timeout | null = null;
  seekLength = 0;

  @Prop()
  isLanguageError!: boolean;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop({ default: false })
  hasError!: boolean;

  @Watch('windowWidth')
  onWindowWidthChange() {
    this.isMobile = this.isMobileDevice || this.windowWidth < 600;
  }

  @Watch('alert')
  onAlertChange(alert: string) {
    if (alert?.length) {
      this.showAlert();
    }
  }

  get isMobileDevice() {
    return getDeviceFlags().isMobile;
  }

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get isPlayerLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get alert() {
    return selectors.player.alertSelector(this.$store);
  }

  get isLive() {
    return selectors.player.isLiveSelector(this.$store);
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get dvrRestrictionMessage() {
    return selectors.tvCurrentChannel.dvrRestrictionMessageSelector(this.$store);
  }

  get lastPause() {
    return selectors.pauses.lastTvPause(this.$store);
  }

  get currentTimeForVod() {
    return selectors.vod.playingCurrentTimeSelector(this.$store);
  }

  get currentTimeForArchive() {
    return convertToSeconds(selectors.vod.playingCurrentTimeSelector(this.$store), 'millisecond');
  }

  get currentTime() {
    return this.playerType === 'vod' ? this.currentTimeForVod : this.currentTimeForArchive;
  }

  get videoData() {
    return selectors.vod.videoDataSelector(this.$store);
  }

  get durationForVod() {
    return this.videoData.duration;
  }

  get durationForArchive() {
    return convertToSeconds(
      selectors.archive.timelineDurationMsSelector(this.$store),
      'millisecond'
    );
  }

  get duration() {
    return this.playerType === 'vod' ? this.durationForVod : this.durationForArchive;
  }

  get seekStep() {
    if (this.seekLength > THRESHOLDS.tv.seekLengthToSwitchToFast) {
      return THRESHOLDS.tv.seekStep.fast;
    } else if (this.seekLength > THRESHOLDS.tv.seekLengthToSwitchToMedium) {
      return THRESHOLDS.tv.seekStep.medium;
    } else {
      return THRESHOLDS.tv.seekStep.normal;
    }
  }

  async mounted() {
    this.$events.on(EVENTS.player.fastForward.start, this.startFastForward);
    this.$events.on(EVENTS.player.fastForward.stop, this.stopFastForward);
    this.$events.on(EVENTS.player.rewind.start, this.startRewind);
    this.$events.on(EVENTS.player.rewind.stop, this.stopRewind);
  }

  getGaControlType(e: MouseEvent | KeyboardEvent) {
    if (e instanceof MouseEvent) {
      return 'mouse';
    } else if (e instanceof KeyboardEvent) {
      return 'keyboard';
    }
    return '';
  }

  scrollToCatalog() {
    const evt: WheelEvent = document.createEvent('WheelEvent');
    evt.initEvent('wheel', true, true);
    Object.defineProperty(evt, 'deltaY', {
      value: 1,
      writable: true,
    });
    this.$emit('onScroll', evt);
    this.gaEvent({
      category: 'player_controls',
      action: 'Прокрутить до каталога',
      player_type: `${this.playerType}_player`,
    });
  }

  showAlert() {
    if (this.alertHideTimeout) {
      clearTimeout(this.alertHideTimeout);
    }

    if (this.alertClearTimeout) {
      clearTimeout(this.alertClearTimeout);
    }

    this.alertVisible = true;

    // hide alert timeout
    this.alertHideTimeout = setTimeout(() => {
      this.alertVisible = false;
    }, HIDE_PLAYER_ALERT_DELAY);

    // clear alert timeout
    this.alertClearTimeout = setTimeout(() => {
      actions.player.clearAlert(this.$store);
    }, this.alertClearTimeoutMs);
  }

  startFastForward(e: MouseEvent | KeyboardEvent) {
    if (e instanceof MouseEvent && e.button !== MOUSE_EVENT_BUTTONS.MAIN_BUTTON) {
      return;
    }

    if (this.isDvrDisabled || this.isLive) {
      return;
    }

    actions.player.showOverlay(this.$store);

    if (this.isDvrRestricted) {
      actions.player.setAlert(this.$store, this.dvrRestrictionMessage);
      return;
    }

    if (!this.accumulationInterval) {
      log.info('startFastForward');
      this.$store.player.fastForwarding = true;
      this.$store.player.timeline.isShowBalloon = true;
      actions.player.setTimelineBalloonFollowingMouse(this.$store, false);
      this.accumulateJump(+1);
      // 85 - approximate time delay equal to pressing arrows on a keyboard
      this.accumulationInterval = setInterval(() => this.accumulateJump(+1), 85);
    }

    this.gaEvent({
      category: 'player_controls',
      action: 'Forward',
      control_type: this.getGaControlType(e),
    });
  }

  stopFastForward() {
    if (!this.$store.player.fastForwarding) {
      return;
    }

    if (this.isDvrDisabled) {
      return;
    }

    if (this.isDvrRestricted) {
      actions.player.setAlert(this.$store, this.dvrRestrictionMessage);
      return;
    }
    log.info('stopFastForward');
    this.stopAccumulation(+1);
  }

  startRewind(e: MouseEvent | KeyboardEvent) {
    if (e instanceof MouseEvent && e.button !== MOUSE_EVENT_BUTTONS.MAIN_BUTTON) {
      return;
    }

    if (this.isDvrDisabled) {
      return;
    }

    actions.player.showOverlay(this.$store);

    if (this.isDvrRestricted) {
      actions.player.setAlert(this.$store, this.dvrRestrictionMessage);
      return;
    }

    if (!this.accumulationInterval) {
      log.info('startRewind');
      this.$store.player.isLive = false;
      this.$store.player.rewinding = true;
      this.$store.player.timeline.isShowBalloon = true;
      actions.player.setTimelineBalloonFollowingMouse(this.$store, false);
      this.accumulateJump(-1);
      // 85 - approximate time delay equal to pressing arrows on a keyboard
      this.accumulationInterval = setInterval(() => this.accumulateJump(-1), 85);
    }

    this.gaEvent({
      category: 'player_controls',
      action: 'Rewind',
      control_type: this.getGaControlType(e),
    });
  }

  stopRewind() {
    if (!this.$store.player.rewinding) {
      return;
    }
    log.info('stopRewind');
    this.stopAccumulation(-1);
  }

  accumulateJump(direction: number) {
    this.seekLength++;
    const timestamp = this.$store.player.video.playingTimeMs + direction * this.seekStep;
    actions.player.updatePlayingTime(this.$store, timestamp);
  }

  performAccumulatedJumpDebounce = debounce(this.performAccumulatedJump, 2000, {
    leading: true,
    trailing: true,
  });

  performAccumulatedJump(direction: number) {
    const timestamp = this.$store.player.video.playingTimeMs + direction * this.seekStep;
    actions.player.updatePlayingTime(this.$store, timestamp);
    setTimeout(() => (this.$store.player.timeline.isShowBalloon = false), 3000);
    log.info('performAccumulatedJump and emit play', direction);
    this.$emit('play');
  }

  stopAccumulation(direction: number) {
    if (this.accumulationInterval) {
      clearInterval(this.accumulationInterval);
      this.accumulationInterval = null;
      this.seekLength = 0;
      this.$store.player.rewinding = false;
      this.$store.player.fastForwarding = false;
      this.performAccumulatedJumpDebounce(direction);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.player-controls {
  position: relative;

  .dvr-restriction {
    width: 1px;
    height: 1px;
  }

  .player-controls-group {
    position: absolute;
    display: flex;
    max-width: 100%;
    opacity: 0;

    &.visible {
      opacity: 1;
      transition: opacity 0.25s var(--ease-in);
    }
  }

  .player-controls-group + .player-controls-group {
    margin-left: 8px;
  }
}
</style>
