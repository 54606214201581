<template>
  <div class="player-channel-title h3 mb-0">
    <span class="color-dark-font-secondary mr-8" v-text="number" />
    <MarqueeText :text="name" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
@Component({
  components: { MarqueeText },
})
export default class PlayerHeadChannelTitle extends Vue {
  @Prop()
  number?: string;

  @Prop()
  name!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.player-channel-title {
  display: flex;
  width: 100%;
  max-width: 1000px;

  @include desktop-l {
    max-width: 900px;
  }

  @include desktop-m {
    max-width: 700px;
  }

  @include desktop-s {
    max-width: 600px;
  }

  @include tablet-l {
    max-width: 400px;
  }

  @include tablet-s {
    max-width: 300px;
  }

  @include tablet {
    @include heading5;
  }

  @include mobile {
    @include body2;
  }
}
</style>
