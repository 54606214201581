<template>
  <div class="epg-channel-head">
    <span class="epg-channel-title flex">
      <button
        type="button"
        class="epg-channel-logo button-with-hover-overlay"
        @click="$emit('selectChannel', epgChannel.id)"
      >
        <img
          v-if="getChannelLogoResized(epgChannel)"
          :alt="getChannelTitle(epgChannel)"
          :src="getChannelLogoResized(epgChannel)"
        />
        <span class="overlay-with-icon">
          <IconSVG :svg="IconPlay" />
        </span>
      </button>

      <button
        class="epg-channel-name h3"
        type="button"
        @click="$emit('closeEpg')"
        v-html="getChannelTitle(epgChannel)"
      />
    </span>

    <ButtonBookmark
      v-if="!isAnonymous"
      size="small"
      :active="isCurrentEpgChannelInFavorites"
      :has-indicator="false"
      @click.native="toggleEpgChannelFavorite"
      @mouseleave.native="$event.target.blur()"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { getChannelLogoResized, getChannelTitle } from 'src/utils/channel';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import * as api from 'src/api';
import { TChannelEnhanced } from 'src/api/channels/types';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';

@Component({
  components: { IconSVG, ButtonBookmark },
})
export default class PlayerFullscreenEpgHead extends SequoiaComponent {
  IconPlay = IconPlay;

  @Prop()
  epgChannel!: TChannelEnhanced;

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get isCurrentEpgChannelInFavorites() {
    return !!this.favoriteChannels[this.epgChannel?.id];
  }

  getChannelLogoResized(channel: TChannelEnhanced) {
    return getChannelLogoResized(channel, 78);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  async toggleEpgChannelFavorite() {
    if (this.epgChannel) {
      actions.metrics.setGaParams(this.$store, {
        category: 'player_controls',
        control_type: 'mouse',
        fullscreen: 'true',
      });
      await actions.tvChannels.toggleChannelFavorite(this.$store, this.epgChannel.id);
    }

    const { favouriteChannels } = await api.channels.getRecentAndFavChannels();
    Vue.set(this.$store.tvChannels, 'favorites', favouriteChannels || []);
  }
}
</script>

<style lang="scss" scoped>
.epg-channel-head {
  display: flex;
  margin-bottom: 24px;

  .epg-channel-title {
    flex-wrap: nowrap;
    text-align: left;

    .epg-channel-logo {
      position: relative;
      flex-shrink: 0;
      width: 72px;
      margin-right: 16px;
    }

    .epg-channel-name {
      margin-bottom: 0;
      text-align: left;
    }
  }

  .button.bookmark {
    margin-top: 4px;
    margin-left: 16px;
  }
}
</style>
