<template>
  <transition name="fade-ease-out-slow">
    <div class="player-progress-programs full-view">
      <template v-if="showPrograms">
        <div class="top">
          <div class="left" @mouseenter="freezeOverlay()" @mouseleave="releaseOverlay()">
            <div class="marquee-wrap">
              <MarqueeText
                v-if="playingEpisodeId && playingEpisodeTitle"
                class="body2 color-dark-font-secondary"
                :text="playingEpisodeTitle"
              />
            </div>
          </div>

          <div v-if="nextEpisodeTitle" class="right">
            <BadgeSequoia theme-forced="dark" :text="getTranslation('schedule_line_later')" />
            <div
              class="next-episode marquee-wrap body2"
              :class="{ disabled: isPlayerLoading }"
              @click="playNextEpisode"
              @mouseenter="freezeOverlay()"
              @mouseleave="releaseOverlay()"
            >
              <MarqueeText :text="nextEpisodeTitle" />
              <IconSVG :svg="IconMediaNext" />
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="marquee-wrap">
            <MarqueeText v-if="!isTitleLoadig" class="h3" :text="title.preview.title" />
            <PlayerButton
              class="button-info"
              :class="{ disabled: isPlayerLoading }"
              data-cy="button-info"
              @click="showTitleDetails"
            >
              <IconSVG :svg="IconInfo" />
            </PlayerButton>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Global from 'src/mixins/Global';
import { selectors } from 'src/store/selectors';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerVodArchive from 'src/components/player/PlayerVodArchive';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconInfo from 'src/svg/info.svg';
import IconMediaNext from 'src/svg/player/media-next.svg';

@Component({
  components: {
    PlayerButton,
    IconSVG,
    MarqueeText,
    BadgeSequoia,
  },
})
export default class PlayerNavigation extends mixins(Global, PlayerVodArchive) {
  IconInfo = IconInfo;
  IconMediaNext = IconMediaNext;

  @Prop({ default: false })
  isDisabled!: boolean;

  get isTitleLoadig() {
    return selectors.vod.isTitleLoadingSelector(this.$store);
  }

  get showPrograms() {
    return (
      (!this.isPlayerMinimized || (this.isPlayerMinimized && this.isFullscreen)) &&
      this.title?.preview?.title
    );
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.player-progress-programs {
  .top {
    display: flex;
    padding-bottom: 4px;
  }

  .left,
  .right {
    min-height: 24px;
  }

  .left {
    width: 55%;
    cursor: default;
  }

  .right {
    position: relative;
    z-index: var(--z-2);
    display: flex;
    align-items: center;
    align-self: baseline;
    justify-content: flex-end;
    width: 30%;
    margin-left: auto;
    color: var(--c-dark-font-secondary);
    @include body2;

    .badge {
      margin-right: 8px;
      cursor: default;
    }

    .next-episode {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include devices-with-hover {
        &:hover {
          color: var(--c-dark-font-primary);
        }
      }

      .marquee-text {
        margin-right: 4px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
