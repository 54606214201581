import { render, staticRenderFns } from "./SmokingWarning.vue?vue&type=template&id=806e12a4&scoped=true&"
import script from "./SmokingWarning.vue?vue&type=script&lang=ts&"
export * from "./SmokingWarning.vue?vue&type=script&lang=ts&"
import style0 from "./SmokingWarning.vue?vue&type=style&index=0&id=806e12a4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "806e12a4",
  null
  
)

export default component.exports