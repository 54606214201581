<template>
  <div class="player-progress" :class="{ fullscreen: isFullscreen, minimized: isPlayerMinimized }">
    <PlayerProgressTimeline v-if="!hasError" />

    <div class="player-progress-programs full-view">
      <PlayerProgressProgram
        v-if="currentProgram"
        :time="currentProgram.startTimeHM"
        :program-title="currentProgram.title"
        :now="true"
        class="program-now"
      />
      <PlayerProgressProgram
        v-if="nextProgram"
        :time="nextProgram.startTimeHM"
        :program-title="nextProgram.title"
        class="program-next"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import PlayerProgressProgram from 'src/components/player/parts/tv/progress/PlayerProgressProgram.vue';
import PlayerProgressTimeline from 'src/components/player/parts/tv/progress/PlayerProgressTimeline.vue';

@Component({
  components: {
    PlayerProgressProgram,
    PlayerProgressTimeline,
  },
})
export default class PlayerProgress extends SequoiaComponent {
  @Prop({ default: false })
  hasError!: boolean;

  get currentProgram() {
    return selectors.tvEpg.currentProgramSelector(this.$store);
  }

  get nextProgram() {
    return selectors.tvEpg.nextProgramSelector(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-progress {
  position: relative;
  z-index: var(--z-2);
  min-height: 84px;

  @include mobile {
    min-height: 0;
  }

  &.minimized {
    min-height: 0;
  }

  .player-progress-programs {
    justify-content: space-between;
  }

  .program-now {
    width: 55%;
  }

  .program-next {
    align-items: end;
    width: 30%;
  }
}
</style>
