<template>
  <button
    v-if="showButton"
    class="player-control-button player-back-to-pause"
    data-cy="player-back-to-pause"
    type="button"
    @click="playFromSavedPause"
    @mouseenter="onMouse"
    @mouseleave="onMouse"
  >
    <transition name="fade-ease-out-slow">
      <span v-show="showTooltip" class="tooltip">
        <span class="inner body2">
          <span class="translation" v-html="getTranslation('continue_watching')" />
          <span class="date"><span v-text="date" />, <span v-text="pauseTimeHM" /></span>
          <MarqueeText class="title" :text="program ? program.title : ''" />
        </span>
      </span>
    </transition>

    <IconSVG :svg="IconBackToPause" :size="24" />
    <span class="text" v-html="getTranslation('continue_watching')" />
  </button>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { DateTime } from 'src/utils/time/date-time';
import IconSVG from 'src/components/IconSVG.vue';
import IconBackToPause from 'src/svg/player/back-to-pause.svg';
import { EVENTS } from 'src/constants';

@Component({
  components: {
    IconSVG,
    MarqueeText,
  },
})
export default class PlayerControlBackToPause extends SequoiaComponent {
  IconBackToPause = IconBackToPause;

  showTooltip = false;

  get lastPause() {
    return selectors.pauses.lastTvPause(this.$store);
  }

  get pauseTimeHM() {
    return DateTime.getHM(new Date(this.lastPause.time));
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get epochDay() {
    return DateTime.toEpochDay(new Date(this.lastPause.time));
  }

  get programs() {
    return (
      (this.currentChannelId &&
        selectors.tvEpg.programsSelector(this.$store, this.currentChannelId)) ||
      []
    );
  }

  get program() {
    const pauseTime = this.lastPause.time;
    return this.programs.find(
      (program) => pauseTime >= program.startMs && pauseTime <= program.endMs
    );
  }

  get date() {
    if (!this.program) {
      return;
    }
    return DateTime.getDMonth(new Date(this.program.startMs));
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get showButton() {
    return !this.isDvrDisabled && !this.isDvrRestricted;
  }

  onMouse() {
    this.showTooltip = !this.showTooltip;
  }

  async playFromSavedPause() {
    actions.player.setIsLive(this.$store, false);
    actions.player.updatePlayingTime(this.$store, this.lastPause.time);
    actions.player.setPickedEpochDay(this.$store, this.epochDay);
    this.$events.emit(EVENTS.player.play);
    this.$events.emit(EVENTS.player.reloadStream);
    this.gaEvent({ category: 'player_controls', action: 'Клик по кнопке "Продолжить просмотр"' });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-back-to-pause {
  .tooltip {
    position: absolute;
    top: -68px;
    left: 0;
    display: block;
    max-width: 252px;
    transition: height 0s, opacity 0.3s linear;

    @media (min-width: #{$desktop-m-min}) {
      top: -44px;
    }

    .inner {
      position: relative;
      top: 0;
      display: block;
      height: 60px;
      padding: 0 12px;
      text-align: left;
      white-space: nowrap;
      background-color: var(--alpha-dark-3);
      border-radius: 4px;

      @media (min-width: #{$desktop-m-min}) {
        height: 40px;
      }

      .translation {
        @media (min-width: #{$desktop-m-min}) {
          display: none;
        }
      }

      .date {
        display: block;
        color: var(--c-dark-font-secondary);
      }

      .title {
        color: var(--c-dark-font-primary);
      }
    }
  }
}
</style>
