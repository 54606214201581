<template>
  <div class="player-volume">
    <PlayerButton @click="toggleMute" @mouseover="showSlider" @mouseout="hideSlider">
      <IconSVG :svg="buttonIcon" />
    </PlayerButton>
    <transition name="fade-ease-out-fast">
      <VolumeSlider
        v-if="!isMobile && !isTablet && !isSliderHidden"
        v-show="isVolumeSliderVisible"
        :volume="volume"
        @onmouseenter="showSlider"
        @onmouseleave="hideSlider"
        @changeVolume="changeVolume"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop, Watch } from 'vue-property-decorator';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { PLAYER_DEFAULT_VOLUME, STORE_KEYS } from 'src/constants';
import { storage } from 'src/utils';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import { TPlayer } from 'src/store/player/types';
import VolumeSlider from 'src/components/player/parts/common/player-controls/player-volume/PlayerVolumeSlider.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconVolumeOn from 'src/svg/player/volume-on.svg';
import IconVolumeOff from 'src/svg/player/volume-off.svg';
import logger from 'src/utils/logger';
import { checkNumberWithDefault } from 'src/utils/number';

const log = logger('player-volume');

@Component({
  components: {
    IconSVG,
    PlayerButton,
    VolumeSlider,
  },
})
export default class PlayerVolume extends SequoiaComponent {
  IconVolumeOn = IconVolumeOn;
  IconVolumeOff = IconVolumeOff;

  player!: TPlayer;

  @Prop({ default: false })
  isSliderHidden!: boolean;

  @Watch('$store.player.video.volume')
  onVideoVolumeChange(val: number) {
    if (val > 0 && val < 1) {
      this.$emit('actualizeVolume');
    }
  }

  get isMobile() {
    return getDeviceFlags().isMobile;
  }

  get isTablet() {
    return getDeviceFlags().isTablet;
  }

  get buttonIcon() {
    return this.$store.player.video.muted ? this.IconVolumeOff : this.IconVolumeOn;
  }

  get muted() {
    return this.$store.player.video.muted;
  }

  get volume() {
    return this.muted ? 0 : this.$store.player.video.volume;
  }

  get isVolumeSliderVisible() {
    return selectors.player.isVolumeSliderVisibleSelector(this.$store);
  }

  mounted() {
    if (!this.$store.player.video.isVolumeMounted) {
      this.restoreVolume();
      this.restoreMute();
      this.$store.player.video.isVolumeMounted = true;
    }

    window.addEventListener('keydown', this.onKeydown);
    window.addEventListener('keyup', this.onKeyup);
  }

  beforeDestroy() {
    this.$store.player.video.isVolumeMounted = false;
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('keyup', this.onKeyup);
  }

  restoreVolume() {
    const volume = checkNumberWithDefault(
      storage.get(STORE_KEYS.player.volume),
      PLAYER_DEFAULT_VOLUME
    );
    actions.player.changeVolume(this.$store, volume);
  }

  restoreMute() {
    const muted = !!storage.get(STORE_KEYS.player.mute);
    actions.player.changeMute(this.$store, muted);
    log.info('restoreMute -> should be muted', muted);
  }

  showSlider() {
    actions.player.showVolumeSlider(this.$store);
  }

  hideSlider() {
    actions.player.hideVolumeSlider(this.$store);
  }

  changeVolume(volume: number) {
    actions.player.changeVolume(this.$store, volume);
    if (volume > 0 && this.muted) {
      actions.player.unmute(this.$store);
      storage.set(STORE_KEYS.player.mute, false);
    }
    if (volume === 0) {
      actions.player.mute(this.$store);
      storage.set(STORE_KEYS.player.mute, true);
    }
    storage.set(STORE_KEYS.player.volume, volume);
  }

  toggleMute() {
    actions.player.toggleMute(this.$store);
  }

  showOverlay() {
    actions.player.showOverlay(this.$store);
  }

  onKeydown(event: KeyboardEvent) {
    if ((event.target as HTMLInputElement)?.tagName?.toLowerCase() === 'input') {
      return;
    }

    const code = (event.code === 'KeyF' ? event.code : event.key || event.code)?.toLowerCase();

    // when cmnd/ctrl is pressed in conjunction with other keys -> do nothing
    if (!code || event.metaKey || event.ctrlKey) {
      return;
    }

    switch (code) {
      case 'arrowup':
        event.preventDefault();
        this.showOverlay();
        actions.player.volumeUp(this.$store);
        actions.player.showVolumeSlider(this.$store);
        break;

      case 'arrowdown':
        event.preventDefault();
        this.showOverlay();
        actions.player.volumeDown(this.$store);
        actions.player.showVolumeSlider(this.$store);
        break;
    }
  }

  onKeyup(event: KeyboardEvent) {
    const code = (event.key || event.code)?.toLowerCase();
    if (!code) {
      return;
    }

    switch (code) {
      case 'arrowup':
      case 'arrowdown':
        actions.player.hideVolumeSlider(this.$store);
        this.gaEvent({
          category: 'player_controls',
          action: 'Изменить громкость',
          control_type: 'keyboard',
        });
        break;
    }
  }
}
</script>

<style lang="scss" scoped>
.player-volume {
  position: relative;
  display: inline-flex;

  .volume-slider {
    position: absolute;
    bottom: 48px;
    left: 50%;
    z-index: var(--z-4);
    transform: translateX(-50%);
  }
}
</style>
