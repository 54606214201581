<template>
  <transition name="fade-ease-out-slow">
    <button
      v-if="!isPlayerMinimized && !isFullscreen"
      type="button"
      class="button-scroll-to-catalog"
      data-cy="button-scroll-to-catalog"
      @click="$emit('scrollToCatalog')"
    >
      <span class="body1" v-html="getTranslation('archive_scroll_down')" />
      <IconSVG :svg="IconAngleDown" :size="16" />
    </button>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import IconAngleDown from 'src/svg/angle-down.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { selectors } from 'src/store/selectors';

@Component({
  components: {
    IconSVG,
  },
})
export default class ButtonScrollToCatalog extends SequoiaComponent {
  IconAngleDown = IconAngleDown;

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }
}
</script>

<style scoped lang="scss">
@import 'src/styles/placeholders-and-mixins/media-queries';

.button-scroll-to-catalog {
  position: absolute;
  bottom: 42px;
  left: 50%;
  z-index: var(--z-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: auto;
  min-height: 40px;
  margin-top: auto;
  margin-bottom: 16px;
  color: var(--c-dark-font-secondary);
  transform: translateX(-50%);

  @include tablet {
    margin-bottom: 56px;
  }

  @include mobile {
    display: none;
  }

  @media (max-height: 520px) {
    display: none;
  }

  @include devices-with-hover {
    &:hover {
      color: var(--c-dark-font-primary);
    }
  }
}
</style>
