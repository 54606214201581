<template>
  <div class="timing" :class="{ minimized: isPlayerMinimized }">
    <span v-if="time" v-text="time" />
    <template v-if="!time && currentTimeFormatted && durationFormatted">
      <span v-text="currentTimeFormatted" />
      <span class="divider">&nbsp;/&nbsp;</span>
      <span class="duration" v-text="durationFormatted" />
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { DateTime } from 'src/utils/time/date-time';
import { convertToSeconds } from 'src/utils/time/convert-time';
import { selectors } from 'src/store/selectors';

@Component
export default class TimingBlock extends Vue {
  // for TV player
  @Prop()
  time?: string;

  // for VOD / Archive
  // in ms
  @Prop()
  currentTime?: number;

  // in ms
  @Prop()
  duration?: number;

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get currentTimeFormatted() {
    return DateTime.prettyTimeHMS(
      this.currentTime || 0,
      (this.currentTime || 0) > convertToSeconds(1, 'hour')
    );
  }

  get durationFormatted() {
    if (this.duration) {
      return DateTime.prettyTimeHMS(this.duration);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.timing {
  position: relative;
  z-index: var(--z-2);
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 24px;
  color: var(--c-dark-font-primary);
  text-align: center;
  cursor: default;
  background-color: rgba(var(--c-dark-400-rgb), 0.8);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  @extend %body1;

  @include mobile-and-tablet {
    margin-right: 16px;
    margin-left: 0;

    &.minimized {
      .duration,
      .divider {
        display: none;
      }
    }
  }

  @include mobile {
    height: auto;
    padding: 0;
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    box-shadow: none;
    @include body2;

    .divider,
    .duration {
      color: var(--c-dark-font-secondary);
    }
  }
}
</style>
