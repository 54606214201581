var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"epg-transition"},on:{"after-enter":_vm.afterEpgEnter}},[(_vm.isEpgOpen && !_vm.isChannelInfoVisible)?_c('div',{staticClass:"epg"},[_c('PlayerButton',{staticClass:"close-epg",on:{"click":_vm.closeEpg}},[_c('IconSVG',{attrs:{"svg":_vm.IconAngleLeft}}),_c('span',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.getTranslation('back'))}})],1),_c('PlayerFullscreenEpgHead',{attrs:{"epg-channel":_vm.epgChannel},on:{"selectChannel":_vm.selectChannel,"closeEpg":_vm.closeEpg}}),(_vm.programs.length)?[_c('PlayerFullscreenEpgCalendar',{attrs:{"calendar":_vm.calendar}}),_c('div',{ref:"epgPrograms",staticClass:"epg-programs scroll-wrap",class:{ locked: _vm.isChannelLocked(_vm.epgChannel) }},_vm._l((_vm.programs),function(program){return _c('div',{key:program.id,staticClass:"epg-program",class:{
            live: _vm.isProgramLive(program),
            active: _vm.isProgramActive(program),
            inactive: _vm.isProgramInFuture(program) || _vm.isChannelLocked(_vm.epgChannel),
            disabled: _vm.isDvrDisabledForChannel(_vm.epgChannel) && !_vm.isProgramLive(program),
            open: program.id === _vm.programInfoId,
          }},[_c('div',{staticClass:"hover-wrap"},[_c('span',{staticClass:"epg-program-start",class:_vm.shouldDisableClicks(_vm.epgChannel, program),domProps:{"textContent":_vm._s(_vm.getHM(new Date(program.startMs)))},on:{"click":function($event){return _vm.switchProgram(program)}}}),(_vm.shouldShowPlayButton(_vm.epgChannel, program))?_c('button',{staticClass:"epg-play",attrs:{"type":"button"},on:{"click":function($event){return _vm.switchProgram(program)}}},[_c('IconSVG',{attrs:{"svg":_vm.IconPlay,"size":16}})],1):_vm._e(),(program && (program.title || (program.metaInfo && program.metaInfo.title)))?_c('button',{staticClass:"epg-program-title",class:_vm.shouldDisableClicks(_vm.epgChannel, program),attrs:{"type":"button"},domProps:{"textContent":_vm._s(program.title || program.metaInfo.title)},on:{"click":function($event){return _vm.switchProgram(program)}}}):_vm._e()]),(_vm.isProgramLive(program))?_c('IconSVG',{staticClass:"icon-live",attrs:{"svg":_vm.IconAir}}):_vm._e(),_c('PlayerButton',{staticClass:"epg-info",class:{ active: program.id === _vm.programInfoId },on:{"click":function($event){return _vm.showProgramInfo(program)}}},[_c('IconSVG',{attrs:{"svg":_vm.IconInfo}})],1)],1)}),0)]:_c('div',{staticClass:"body1",domProps:{"innerHTML":_vm._s(_vm.getTranslation('program_is_not_found'))}})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }