<template>
  <div class="player-alert body2" @click="$emit('click')">
    <IconSVG :svg="IconInfo" class="alert" />
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IconSVG from 'src/components/IconSVG.vue';
import IconInfo from 'src/svg/info.svg';

@Component({
  components: { IconSVG },
})
export default class PlayerAlert extends Vue {
  IconInfo = IconInfo;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-alert {
  display: flex;
  align-items: center;
  min-height: 24px;
  padding: 6px 8px;
  background-color: rgba(238, 238, 238, 0.16);
  border-radius: 4px;

  .alert {
    flex-shrink: 0;
    align-self: baseline;
    margin-right: 8px;
    color: var(--c-dark-status-error);
  }
}
</style>
