import { render, staticRenderFns } from "./ButtonScrollToCatalog.vue?vue&type=template&id=698b5446&scoped=true&"
import script from "./ButtonScrollToCatalog.vue?vue&type=script&lang=ts&"
export * from "./ButtonScrollToCatalog.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonScrollToCatalog.vue?vue&type=style&index=0&id=698b5446&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698b5446",
  null
  
)

export default component.exports