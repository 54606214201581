<template>
  <div
    class="player-vod player-tv-vod-archive dark"
    :class="{
      mobile: isMobile,
      fullscreen: isFullscreen,
      minimized: isPlayerMinimized && !isFullscreen,
    }"
    data-cy="player-vod"
  >
    <div ref="player" class="player" @click="tryFocusOnPlayerContainer" @mousemove="showOverlay()">
      <div class="video-wrap" data-cy="video-wrap">
        <video
          ref="video"
          class="video"
          :class="{ scaled: isVideoScaled }"
          :muted="isVideoMuted"
          :controls="false"
          playsinline
          preload="metadata"
        />
      </div>

      <SmokingWarning v-if="isShowSmokingWarning" />

      <div v-if="isPlayerLoading && !isPlayerMinimized && !hasPlayerError" class="loader">
        <LoaderSpinner />
      </div>

      <div ref="overlay" class="overlay" :class="{ hidden: shouldHideOverlay }">
        <PlayerHead
          :is-disabled="isOffline"
          :ios-fullscreen-available="iosFullscreenAvailable"
          @closePlayer="closePlayer"
          @toggleFullscreen="toggleFullscreen"
        />

        <PlayerErrors v-if="hasPlayerError" />

        <PlayerControlsMobile
          :has-error="hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline || hasPlayerError"
          @play="play"
          @pause="pause"
          @actualizeVolume="actualizeVolume"
          @togglePlayPause="togglePlayPause"
          @toggleFullscreen="toggleFullscreen"
          @onScroll="$emit('onScroll', $event)"
        />

        <div class="timing-and-episode" :class="{ 'mt-0': hasPlayerError }">
          <TimingBlock
            class="visible-mobile pl-24"
            :current-time="currentTime"
            :duration="duration"
          />

          <div class="episode marquee-wrap hidden-mobile">
            <MarqueeText
              v-if="playingEpisodeId && playingEpisodeTitle"
              class="body1 color-dark-font-primary"
              :text="playingEpisodeTitle"
            />
          </div>

          <div class="mobile-episode body2 color-dark-font-primary" v-text="playingEpisodeTitle" />
        </div>

        <PlayerControls
          class="hidden-mobile"
          :has-error="hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline || hasPlayerError"
          @actualizeVolume="actualizeVolume"
          @togglePlayPause="togglePlayPause"
          @toggleFullscreen="toggleFullscreen"
          @onScroll="$emit('onScroll', $event)"
        />

        <PlayerControlsMinimized
          :has-error="hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline"
          @actualizeVolume="actualizeVolume"
          @togglePlayPause="togglePlayPause"
          @closePlayer="closePlayer"
        />

        <PlayerProgressTimelineVod
          v-if="!hasPlayerError"
          :is-disabled="isPlayerLoading || isOffline"
          @setCurrentTime="onTimelineChange"
        />

        <PlayerProgressPrograms :is-disabled="isPlayerLoading || isOffline || hasPlayerError" />

        <ClickableUnderlay
          v-if="!hasPlayerError"
          @play="play"
          @pause="pause"
          @toggleFullscreen="toggleFullscreen"
        />
      </div>
    </div>

    <div class="notifications-container">
      <NotificationWithDetails
        v-if="showNotification"
        :title="getTranslation('vod_unavailable_title')"
        :message="playerErrorMessage"
        :icon="IconInfo"
        @hide="closeNotificationWithDetails"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';

// Types
import { LoaderResponseFixed } from 'src/components/player/types';
import type THls from 'hls.js/dist/hls.js';
import type TShaka from 'shaka-player/dist/shaka-player.ui';

// Mixins
import AddResizeListener from 'src/mixins/AddResizeListener';
import { SequoiaComponent } from 'src/mixins';
import PlayerVodArchive from 'src/components/player/PlayerVodArchive';

// Components
import LoaderSpinner from '../ui/loader/LoaderSpinner.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerProgressTimelineVod from 'src/components/player/parts/vod-archive/PlayerProgressTimelineVod.vue';
import PlayerProgressPrograms from 'src/components/player/parts/vod-archive/PlayerProgressPrograms.vue';
import PlayPauseStop from 'src/components/player/parts/common/player-controls/PlayPauseStop.vue';
import PlayerControls from 'src/components/player/parts/common/player-controls/PlayerControls.vue';
import PlayerControlsMinimized from 'src/components/player/parts/common/player-controls/PlayerControlsMinimized.vue';
import PlayerErrors from 'src/components/player/parts/common/PlayerErrors.vue';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import IconSVG from 'src/components/IconSVG.vue';

// utils & other
import {
  HTTP_PLAYER_BAD_RESPONSE_CODES,
  VOD_SESSION_USER_EVENT,
  EVENTS,
  PLAYER_ERROR_CODES,
} from 'src/constants';
import { storage } from 'src/utils';
import { useHttpsProtocol } from 'src/utils/url';
import { convertToSeconds } from 'src/utils/time/convert-time';
import { VodPlayback } from 'src/utils/vod/playback';
import logger from 'src/utils/logger';
import ClickableUnderlay from 'src/components/player/parts/common/ClickableUnderlay.vue';
import PlayerHead from 'src/components/player/parts/common/PlayerHead.vue';
import TimingBlock from 'src/components/player/parts/common/TimingBlock.vue';
import PlayerControlsMobile from 'src/components/player/parts/common/player-controls/PlayerControlsMobile.vue';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import { videoDataSelector } from 'src/store/vod/selectors';
import SmokingWarning from 'src/components/player/parts/common/SmokingWarning.vue';
import { ErrorData, Events, HlsConfig } from 'hls.js';
import { getBrowserFlags } from 'src/utils/platform-detector';
import { TPlayerErrorOptions } from 'src/store/player/types';

const log = logger('player-vod');

let HLS: typeof THls; // async import
let Shaka: typeof TShaka; // async import

@Component({
  components: {
    SmokingWarning,
    MarqueeText,
    PlayerControlsMobile,
    TimingBlock,
    PlayerProgressTimelineVod,
    PlayerProgressPrograms,
    PlayerControls,
    LoaderSpinner,
    PlayerButton,
    PlayPauseStop,
    PlayerControlsMinimized,
    PlayerErrors,
    NotificationWithDetails,
    IconSVG,
    ClickableUnderlay,
    PlayerHead,
  },
})
export default class PlayerVod extends mixins(
  PlayerVodArchive,
  SequoiaComponent,
  AddResizeListener
) {
  shakaPlayer: TShaka.Player | null = null;

  @Watch('videoUrl')
  async onVideoUrlChange(videoUrl: string) {
    if (this.isPlayerLoading) {
      log.warning('videoUrl has been changed, though player is still loading -> skip init');
      return;
    }

    actions.player.startLoading(this.$store);
    actions.player.setLoaded(this.$store, false);
    log.info('onVideoUrlChange', {
      videoUrl: videoUrl ? new URL(videoUrl) : '',
      hasVideoEnded: this.hasVideoEnded,
    });
    actions.player.setIsPlaying(this.$store, false);
    if (this.playbackSession) {
      this.changeEpisode = true;
      await this.playbackSession.userEvent(VOD_SESSION_USER_EVENT.stop, {
        ...this.playbackSessionEventData,
        destroy: true,
        loadingNewState: true,
      });
      if (this.playingEpisodeId !== this.playbackSession.episodeId) {
        this.lastPauseTime = 0;
      }
    }

    this.clearAllErrors();

    if (this.lastPauseTime && this.playbackSession && !this.playbackSession.checkSession()) {
      log.warning('onVideoUrlChange - this.playbackSession.checkSession() failed');
      this.setCurrentTime(this.lastPauseTime);
      await this.attachUrl();
      return;
    }

    if (this.playbackSession && this.playingTitleId !== this.playbackSession?.titleId) {
      await this.playbackSession?.userEvent(VOD_SESSION_USER_EVENT.stop, {
        ...this.playbackSessionEventData,
        destroy: true,
        loadingNewState: true,
      });
      this.lastPauseTime = 0;
    }

    if (!this.playbackSession) {
      this.lastPauseTime = 0;
    }

    this.setCurrentTime(
      this.isSavedPause && this.lastPause.episodeId === this.episodeIdFromParams
        ? convertToSeconds(this.lastPause.fromStart, 'millisecond')
        : this.lastPauseTime
        ? this.lastPauseTime
        : 0
    );

    if (videoUrl) {
      await this.init();
    } else {
      this.destroyHls();
      await this.destroyShaka();
    }

    actions.player.setHasVideoEnded(this.$store, false);
    actions.player.stopLoading(this.$store);
    actions.player.setLoaded(this.$store, true);
  }

  @Watch('$store.player.minimized')
  onPlayerMinimizedChange() {
    if (!this.isFullscreen) {
      this.playbackSession?.userEvent(VOD_SESSION_USER_EVENT.displayMode, {
        ...this.playbackSessionEventData,
      });
    }
  }

  @Watch('playbackSession.sessionError')
  async onPlaybackErrorChange(error: boolean) {
    log.error('playbackSession.sessionError', error);
    if (error && this.isPlayerPlaying) {
      this.refVideo?.pause();
      await this.play();
    }
  }

  @Watch('$store.player.visible')
  onPlayerVisibilityChange() {
    if (this.refVideo) {
      this.videoWidth = this.refVideo.offsetWidth;
    }
  }

  @Watch('$store.vod.videoData.episodeId')
  onEpisodeIdChange(newValue: string, oldValue: string) {
    if (this.isPlayerLoading || !this.playingTitleId) {
      return;
    }
    log.info(`episodeId '${oldValue}' has changed to '${newValue}'`);
    this.changeEpisode = true;
    const seasonNum = this.seasons?.findIndex((s) => s.id === this.playingSeason?.id);

    actions.vod.setCurrentSeasonNum(this.$store, seasonNum && seasonNum >= 0 ? seasonNum : 0);
    actions.vod.setCurrentAndNextSeasonsAndEpisodes(
      this.$store,
      this.sourceId,
      newValue,
      !!this.isSavedPause
    );
  }

  @Watch('subtitleDisplay')
  onSubtitleDisplayChangeForShakaPlayer(val: boolean) {
    if (this.shakaPlayer) {
      log.info('subtitleDisplay has been set to ', val);
      this.shakaPlayer.setTextTrackVisibility(val);
    }
  }

  get hasWatchInUrl() {
    return this.$route.fullPath.search('watch') >= 0;
  }

  get showNotification() {
    return (
      !this.isAnonymous &&
      this.hasPlayerError &&
      this.showNotificationWithDetails &&
      (this.isPlayerMinimized || this.isMobile)
    );
  }

  get title() {
    return this.titleFromParams || this.playingTitle;
  }

  get titleId() {
    return this.titleIdFromParams || this.playingTitleId;
  }

  get episodeId() {
    return this.episodeIdFromParams || this.playingEpisodeId;
  }

  serverPrefetch() {
    actions.player.setPlayerType(this.$store, 'vod');
  }

  async mounted() {
    log.info('VOD player is mounting...');

    actions.player.setPlayerType(this.$store, 'vod');
    actions.player.setHasSubtitles(this.$store, false);

    if (this.isFullscreen) {
      this.toggleFullscreen();
    }

    document.addEventListener('MSFullscreenChange', this.actualizeFullscreen.bind(this));
    document.onfullscreenchange =
      document.onwebkitfullscreenchange =
      document.onmozfullscreenchange =
        this.actualizeFullscreen.bind(this); // this could be triggered when user exits from the fullscreen mode by pressing esc

    // bind events
    window.addEventListener('online', this.onGoOnline);
    window.addEventListener('offline', this.onGoOffline);
    // TODO waiting for mobile player's design update
    // window.addEventListener('orientationchange', this.onOrientationchange);
    window.addEventListener('keydown', this.onKeydown);
    window.addEventListener('keyup', this.onKeyup);
    window.addEventListener('visibilitychange', this.sendStatOnSessionEnd); // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session

    this.refVideo?.addEventListener('waiting', this.onWaiting);
    this.refVideo?.addEventListener('timeupdate', this.onPlayingCurrentTimeChange);
    this.refVideo?.addEventListener('durationchange', this.onDurationChange);
    this.refVideo?.addEventListener('play', this.onPlaying);
    this.refVideo?.addEventListener('webkitendfullscreen', this.actualizeFullscreen.bind(this));
    this.refVideo?.addEventListener('canplay', this.onCanplay);

    // used for triggering savePause,
    // particularly on mobile native players
    this.refVideo?.addEventListener('pause', this.onPause);

    this.$events.on(EVENTS.player.play, this.play);
    this.$events.on(EVENTS.player.pause, this.pause);

    // for starting HLS video from predetermined currentTime:
    // - in custom player in Safari
    // https://github.com/videojs/videojs-contrib-hls/issues/1087#issuecomment-294254377
    // - in native player in all iOS browsers
    // https://stackoverflow.com/questions/18266437/html5-video-currenttime-not-setting-properly-on-iphone
    this.refVideo?.addEventListener('loadedmetadata', this.onLoadedMetadata);

    this.actualizeVolume();

    if (this.isAnonymous && this.hasWatchInUrl) {
      this.setShowNotificationAuthAndReg();
      return;
    }

    if (this.titleId) {
      try {
        if (!this.title) {
          await actions.vod.loadTitleVod(this.$store, this.sourceId, this.titleId);
        }

        await this.startPlayTitle();

        this.showOverlay();

        this.iosFullscreenAvailable = typeof this.refVideo?.webkitEnterFullScreen === 'function';

        this.gaEvent({
          category: 'player_controls',
          action: 'Запуск контента',
          title_id: this.titleId,
          vod_name: this.sourceId,
        });
      } catch {
        // do nothing, errors should already be logged and printed
      }
    } else {
      actions.player.hidePlayer(this.$store);
    }
    log.info('VOD player was mounted');
  }

  async beforeDestroy() {
    document.removeEventListener('MSFullscreenChange', this.actualizeFullscreen.bind(this));
    document.onfullscreenchange =
      document.onwebkitfullscreenchange =
      document.onmozfullscreenchange =
        null;

    window.removeEventListener('online', this.onGoOnline);
    window.removeEventListener('offline', this.onGoOffline);
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('keyup', this.onKeyup);
    window.removeEventListener('visibilitychange', this.sendStatOnSessionEnd);
    // TODO waiting for mobile player's design update
    // window.removeEventListener('orientationchange', this.onOrientationchange);

    this.$events.off(EVENTS.player.play, this.play);
    this.$events.off(EVENTS.player.pause, this.pause);

    await this.closePlayer();
    await this.destroyShaka();

    if (this.playbackSession) {
      await this.playbackSession?.userEvent(VOD_SESSION_USER_EVENT.stop, {
        ...this.playbackSessionEventData,
        destroy: true,
      });
      this.lastPauseTime = 0;
    }
  }

  /**
   * Play current title
   */
  async startPlayTitle() {
    this.networkChange.reloadAttempt = 1;
    await actions.pauses.loadVodPauses(this.$store, this.sourceId, this.titleId);

    if (
      !this.isSavedPause && this.title?.preview?.hasSeries
        ? this.lastPause.episodeId === this.episodeId
        : this.lastPause.titleId === this.titleId
    ) {
      actions.vod.setIsSavedPause(this.$store, true);
    }

    await actions.vod.playVideo(this.$store, this.sourceId, this.title, this.episodeId);

    if (this.videoData.playbackSession) {
      this.playbackSession = new VodPlayback(
        this.videoData.playbackSession,
        this.refVideo,
        this.playingTitleId,
        this.playingEpisodeId
      );
    }

    actions.player.stopLoading(this.$store);

    if (this.isMobile) {
      await this.attachUrl();
    }
  }

  // TODO waiting for mobile player's design update
  // onOrientationchange() {
  //   if (typeof window !== 'undefined' && window.innerHeight < window.innerWidth) {
  //     this.pause();
  //   } else {
  //     this.play();
  //   }
  //   this.exitFullScreenOnAndroid();
  // }

  async onLoadedMetadata() {
    if (this.refVideo) {
      log.info('Player event -> loadedmetadata');
      this.setCurrentTime(
        this.isSavedPause && this.lastPause.episodeId === this.episodeIdFromParams
          ? convertToSeconds(this.lastPause.fromStart, 'millisecond')
          : this.currentTime
      );
      this.refVideo.currentTime = this.currentTime;
    }
    if (this.hls && this.hasSubtitles) {
      this.hls.subtitleDisplay = this.subtitleDisplay;
      this.hls.subtitleTrack = this.subtitleDisplay ? 0 : -1;
    }
  }

  async init() {
    if (!this.refVideo) {
      log.info('init: refVideo is not ready yet');
      return false;
    }

    log.info('init started');
    let error = '';
    const isLocalHost = ['localhost', '0.0.0.0'].includes(window.location.hostname);

    if (this.useShaka) {
      log.info('init: playerType = shaka');
      if (window.location.protocol !== 'https:' && !isLocalHost) {
        const https = useHttpsProtocol(window.location.href);
        error = `Unable to play DRM because the protocol is not secure (http), try ${https}`;
      } else {
        await this.destroyShaka();
        await this.initShaka();
      }
    } else if (this.useHls) {
      // when hls enabled - stream will be attached once DOM will be ready on MEDIA_ATTACHED event
      log.info('init: playerType = hls');
      await this.initHls();
    } else {
      // for non-hls version we attach stream to DOM video element straight ahead
      log.info('init: playerType = native');
      this.refVideo?.pause();
      await this.attachUrl();
    }

    log.info('init completed');
    if (error) {
      actions.player.setError(this.$store, PLAYER_ERROR_CODES.INTERNAL, error);
    } else {
      await this.play(); // always start playing after init
    }
  }

  async initHls() {
    this.destroyHls();

    log.info('initHls');

    const config: Partial<HlsConfig> = {
      debug: storage.get('__debug_hls__') === 1,
    };

    actions.player.clearError(this.$store);

    if (!HLS) {
      HLS = (await import(/* webpackChunkName: "hls.js" */ 'hls.js')).default;
    }

    this.hls = new HLS(config);

    let fragsCounter = 0;
    this.hls?.on(HLS.Events.MANIFEST_LOADING, () => {
      if (!fragsCounter) {
        actions.player.setIsPlaying(this.$store, false);
        actions.player.startLoading(this.$store);
      }
    });

    this.hls?.on(HLS.Events.FRAG_BUFFERED, () => {
      fragsCounter++;
      if (fragsCounter >= 2 && this.isPlayerLoading) {
        actions.player.stopLoading(this.$store);
      }
    });

    this.hls?.on(HLS.Events.MANIFEST_LOADED, (event, data) => {
      const subtitles = data?.subtitles || [];
      const subtitlesLength = subtitles?.length;

      log.info(
        `HLS EVENT: MANIFEST_LOADED. Subtitle track(s) length: ${subtitlesLength}`,
        'subtitleTracks:',
        subtitles
      );
      actions.player.setHasSubtitles(this.$store, !!subtitlesLength);
    });

    this.hls?.on(HLS.Events.MEDIA_ATTACHED, () => {
      // fired when video html element has been successfully attached to hls instance
      log.info('HLS EVENT: MEDIA_ATTACHED. Video html-element was attached to hls');
      this.attachUrl();
    });

    this.hls?.on(HLS.Events.MANIFEST_PARSED, () => {
      log.info('HLS EVENT: MANIFEST_PARSED. Video URL was loaded to hls');
      if (
        this.wasPlayingBeforeOffline ||
        (!this.isPlayerPlaying &&
          (this.changeEpisode || this.playingTitleId !== this.playbackSession?.titleId))
      ) {
        this.clearAllErrors();
        this.play();
      }
    });

    this.hls?.on(HLS.Events.ERROR, this.handleHlsError);

    if (this.refVideo) {
      this.hls?.attachMedia(this.refVideo);
    }
  }

  async handleHlsError(event: Events.ERROR, data: ErrorData) {
    let isFatalError = data.fatal; // fatal should also be when stream returns 400, 404 or 500
    const response = data.response as LoaderResponseFixed;
    const responseCode = response?.code;
    const isBadResponse = HTTP_PLAYER_BAD_RESPONSE_CODES.includes(responseCode);
    const isNetworkError = data.type === HLS.ErrorTypes.NETWORK_ERROR;
    const isManifestParsingError = data.details === HLS.ErrorDetails.MANIFEST_PARSING_ERROR; // responseStatus could be 200, but it should be considered as fatal as well
    const isFatalStatus = isNetworkError && (isBadResponse || isManifestParsingError);
    isFatalError = isFatalStatus || isFatalError;

    if (
      isNetworkError &&
      responseCode === 403 &&
      this.networkChange.reloadAttempt <= this.networkChange.maxAttempts
    ) {
      // probably network has been changed (e.g. VPN has been turned on)
      try {
        log.info(
          'Chunk load returns 403, maybe network has been changed... Reloading stream. Attempt',
          this.networkChange.reloadAttempt
        );
        this.networkChange.reloadAttempt++;
        this.refVideo?.pause();
        this.destroyHls();
        await this.startPlayTitle().catch(() => {
          // do nothing
        });
        return;
      } catch {
        // do nothing
      }
    }

    if (isFatalError) {
      // fatal error – when stream is unavailable
      log.error('HLS ERROR ', event, data);
      this.showPlayerNetworkError();
      if (this.playbackSession) {
        this.playbackSession.playErrorsCnt++;
      }
    } else {
      // just a warning when buffering is in progress
      log.warning('HLS WARNING ', event, data);
    }

    switch (data.details) {
      case HLS.ErrorDetails.BUFFER_STALLED_ERROR:
        // when buffering is started, see https://github.com/video-dev/hls.js/issues/2113#issuecomment-460682147
        actions.player.startLoading(this.$store);
        break;
      // в сафари не срабатывает BUFFER_NUDGE_ON_STALL
      case HLS.ErrorDetails.BUFFER_NUDGE_ON_STALL:
        // when buffering is finished, see https://github.com/video-dev/hls.js/issues/2113#issuecomment-460682147
        actions.player.stopLoading(this.$store);
        break;
    }
  }

  async initShaka() {
    if (!Shaka) {
      window.muxjs = (await import('mux.js')).default; // to support video/mp2t, see https://github.com/shaka-project/shaka-player/blob/main/docs/tutorials/faq.md
      Shaka = (
        await import(
          /* webpackChunkName: "shaka-player" */ 'shaka-player/dist/shaka-player.ui'
          // 'shaka-player/dist/shaka-player.ui.debug' // for debug
        )
      ).default;
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const config: any = {
        // config: any - due to poor typing in shaka player
        drm: {
          servers: {},
          advanced: {
            'com.widevine.alpha': {
              // to avoid warning in console
              videoRobustness: 'SW_SECURE_CRYPTO',
              audioRobustness: 'SW_SECURE_CRYPTO',
            },
          },
        },
      };

      const { url, amedia2Playback, megogoPlayback } = this.$store.vod.videoData;

      if (amedia2Playback) {
        config.drm.servers['com.apple.fps'] = amedia2Playback.laFairplay;
        config.drm.servers['com.widevine.alpha'] = amedia2Playback.laWidevine;
        config.drm.servers['com.microsoft.playready'] = amedia2Playback.laPlayready;
        config.drm.advanced['com.apple.fps'] = {};
        config.drm.advanced['com.apple.fps'].serverCertificateUri = amedia2Playback.fpsCertificate;
      } else if (megogoPlayback) {
        config.drm.servers['com.apple.fps'] = megogoPlayback.license_server;
        config.drm.servers['com.widevine.alpha'] = megogoPlayback.license_server;
        config.drm.servers['com.microsoft.playready'] = megogoPlayback.license_server;
        config.drm.advanced['com.apple.fps'] = {};
        config.drm.advanced['com.apple.fps'].serverCertificateUri = megogoPlayback.wvls;
      }

      log.info('initShaka', config);

      this.shakaPlayer = new Shaka.Player(this.refVideo);
      this.shakaPlayer.configure(config);
      let segmentsCounter = 0;
      this.shakaPlayer.addEventListener('segmentappended', () => {
        // doesn't work in Safari
        segmentsCounter++;
        if (segmentsCounter >= 2 && this.isPlayerLoading) {
          actions.player.stopLoading(this.$store);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.shakaPlayer.addEventListener('buffering', (e: any) => {
        // e: any - due to poor typing in shaka player
        if (e.buffering === false) {
          actions.player.stopLoading(this.$store);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.shakaPlayer.addEventListener('error', async (e: any) => {
        // e: any is due to poor typing in shaka player
        log.error('shakaError', e.detail || e);
        const errorCode = PLAYER_ERROR_CODES.INTERNAL;
        const errorMessage = e.detail?.data?.[2];
        const errorOptions: TPlayerErrorOptions = {};
        if (getBrowserFlags().isYandex && (!e.detail?.code || e.detail?.code >= 6000)) {
          // if it's yandex browser - ask user to update it
          errorOptions.askUserToUpdateBrowser = true;
        }
        actions.player.setError(this.$store, errorCode, errorMessage, errorOptions);
        actions.player.stopLoading(this.$store);
        await this.pause();
        // todo trying to find a fix of "Media failed to decode" error in Safari
        // if ([3016, 6010].includes(e.detail.code) && this.shakaPlayerRetries < 5) {
        //   this.shakaPlayerRetries++;
        //   log.warning('retrying shaka, attempt', this.shakaPlayerRetries);
        //   await this.pause();
        //   await this.destroyShaka();
        //   const timestamp = Math.round(new Date().getTime() / 1000); // seconds
        //   this.$store.vod.videoData.url = this.$store.vod.videoData.url!.replace(
        //     /t=\d+/,
        //     `t=${timestamp}`
        //   );
        // }
      });

      if (url) {
        await this.shakaPlayer.load(url);
        const textTracks = this.shakaPlayer.getTextTracks();
        if (textTracks.length) {
          actions.player.setHasSubtitles(this.$store, true);
        }
      } else {
        log.error('Shaka: absent store.vod.videoData.url');
      }
    } catch (e) {
      const errors = Shaka.util.Error.Code;
      const errorCode = Object.keys(errors).find(
        (key) => errors[key as keyof typeof errors] === e.code
      );

      log.error('Shaka: unable to init', errorCode, e);

      if (process.env.NODE_ENV !== 'production') {
        log.info('Shaka.Player.probeSupport', await Shaka.Player?.probeSupport());
      }
    }
  }

  async destroyShaka() {
    if (this.shakaPlayer) {
      log.info('destroyShaka');
      await this.shakaPlayer.destroy();
    }
    this.shakaPlayer = null;
  }

  /**
   * On window online event
   */
  async onGoOnline() {
    log.warning('connection is back online, reloading the stream...');
    await actions.appInfo.updateServerTime(this.$store);
    actions.common.setIsOffline(this.$store, false);
    actions.player.setIsPlaying(this.$store, this.wasPlayingBeforeOffline);
    this.clearAllErrors();

    // always call init() to prepare all resources for playing the video afterward
    await this.init();
  }

  togglePlayPause() {
    if (this.isPlayerPlaying) {
      this.pause();
    } else {
      this.play();
    }

    const { titleId, sourceId } = videoDataSelector(this.$store);
    this.gaEvent({
      category: 'player_controls',
      action: `${this.isPlayerPlaying ? 'Pause' : 'Play'}`,
      title_id: titleId,
      vod_name: sourceId,
    });
  }

  /**
   * Play video
   */
  async play() {
    if (!this.refVideo) {
      log.info('refVideo is not ready yet');
      return false;
    }

    this.clearAllErrors();

    await this.updatePlaybackSession();

    if (
      this.isSavedPause &&
      (this.playingTitle?.preview?.hasSeries
        ? this.lastPause.episodeId === this.videoData.episodeId
        : this.lastPause.titleId === this.videoData.titleId)
    ) {
      this.setCurrentTime(convertToSeconds(this.lastPause.fromStart, 'millisecond'));
      actions.vod.setIsSavedPause(this.$store, false);
    } else {
      this.setCurrentTime(this.currentTime);
    }

    this.refVideo.currentTime = this.currentTime;

    log.info('start playing...');

    try {
      await this.refVideo.play();

      if (this.playbackSession) {
        await this.playbackSession?.userEvent(VOD_SESSION_USER_EVENT.start, {
          ...this.playbackSessionEventData,
        });
      }

      this.showOverlay();
      this.changeEpisode = false;
      this.showSmokingWarning = true;
      actions.player.setIsPlaying(this.$store, true);
      log.info('play');
    } catch (err) {
      // to avoid: "the play() request was interrupted..."
      log.error('play:', err);
      actions.player.setIsPlaying(this.$store, false);
    } finally {
      actions.player.stopLoading(this.$store);
      if (this.waitingLoaderTimeoutId) {
        clearTimeout(this.waitingLoaderTimeoutId);
      }
    }

    if (this.isPlayerMinimized) {
      actions.player.expandPlayer(this.$store);
      actions.player.lockScroll(this.$store);
    }
  }

  // Current Time / Duration / Timeline
  onPlayingCurrentTimeChange() {
    if (!this.refVideo || this.isOffline || !this.isPlayerPlaying || this.duration <= 1) {
      return;
    }

    actions.vod.setPlayingCurrentTime(this.$store, this.refVideo.currentTime);

    if (this.refVideo.currentTime >= this.duration) {
      actions.player.setHasVideoEnded(this.$store, true);

      // when current episode ends or
      // when fastforwarding beyond the video duration,
      // play the next episode if there are any
      if (this.playingEpisodeId && this.nextEpisodeNum !== undefined) {
        if (this.isShortJumping) {
          this.isShortJumpDisabled = true;
        }
        this.playNextEpisode();
        this.setCurrentTime(0);
      } else {
        this.playbackSession?.userEvent(VOD_SESSION_USER_EVENT.stop, {
          ...this.playbackSessionEventData,
          destroy: true,
        });
        this.pause();
        actions.player.minimizePlayer(this.$store);
        actions.player.unlockScroll(this.$store);
      }
    }
  }

  onDurationChange() {
    if (this.refVideo) {
      actions.vod.setPlayingDuration(this.$store, this.refVideo.duration);
    }
  }

  onTimelineChange(value: number) {
    this.setCurrentTime(value);
    if (this.playbackSession && this.isPlayerPlaying && this.playbackSession.checkSession()) {
      this.lastPauseTime = value;
      this.playbackSession.userEvent(VOD_SESSION_USER_EVENT.timeline, {
        ...this.playbackSessionEventData,
      });
    }
  }
}
</script>

<style lang="scss">
@import 'player';
</style>
