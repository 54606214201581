<template>
  <transition name="fade-ease-out-slow">
    <div
      v-show="isPlayerMinimized && !isFullscreen"
      class="player-controls-minimized responsive-container"
    >
      <template v-if="!hasError">
        <PlayPauseStop
          :class="{ disabled: isDisabled }"
          :is-playing="isPlayerPlaying"
          @click="$emit('togglePlayPause')"
        />

        <PlayerButton
          v-if="nextEpisodeTitle"
          :class="{ disabled: isDisabled }"
          class="media-next"
          @click="playNextEpisode"
        >
          <IconSVG :svg="IconMediaNext" />
        </PlayerButton>

        <TimingBlock
          v-if="isTv || duration > 1"
          :time="timeForTv"
          :current-time="currentTime"
          :duration="duration"
        />
      </template>

      <div v-if="isPlayerMinimized && (title || currentProgram)" class="player-progress-programs">
        <PlayerProgressProgram
          v-if="currentProgram"
          :time="currentProgram.startTimeHM"
          :program-title="currentProgram.title"
          :now="true"
        />

        <template v-if="title">
          <div class="marquee-wrap">
            <MarqueeText class="h5" :text="title.preview.title" />
            <MarqueeText
              v-if="playingEpisodeId"
              class="body2 color-dark-font-secondary"
              :text="playingEpisodeTitle"
            />
          </div>
          <PlayerButton
            class="button-info"
            :class="{ disabled: isDisabled }"
            data-cy="button-info"
            @click="showDetails"
          >
            <IconSVG :svg="IconInfo" />
          </PlayerButton>
        </template>
      </div>

      <div class="extra-controls">
        <template v-if="!hasError">
          <PlayerVolume :is-slider-hidden="true" @actualizeVolume="$emit('actualizeVolume')" />

          <PlayerButton data-cy="button-expand-view" @click="expandView">
            <IconSVG :svg="IconExpandView" />
          </PlayerButton>
        </template>

        <PlayerButton
          data-cy="button-close-player"
          @click="
            $emit('closePlayer');
            gaEvent({
              category: 'player_controls',
              action: 'Закрытие плеера',
              player_type: `${playerType}_player`,
            });
          "
        >
          <IconSVG :svg="IconCross" />
        </PlayerButton>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import IconExpandView from 'src/svg/player/expand-view.svg';
import IconCross from 'src/svg/cross.svg';
import IconInfo from 'src/svg/info.svg';
import IconMediaNext from 'src/svg/player/media-next.svg';
import TimingBlock from 'src/components/player/parts/common/TimingBlock.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import MarqueeText from 'src/components/ui/MarqueeText.vue';
import PlayPauseStop from 'src/components/player/parts/common/player-controls/PlayPauseStop.vue';
import IconSVG from 'src/components/IconSVG.vue';
import PlayerVolume from 'src/components/player/parts/common/player-controls/player-volume/PlayerVolume.vue';
import { actions } from 'src/store/actions';
import PlayerVodArchive from 'src/components/player/PlayerVodArchive';
import { Prop } from 'vue-property-decorator';
import { TProgramEnhanced } from 'src/api/channels/types';
import PlayerProgressProgram from 'src/components/player/parts/tv/progress/PlayerProgressProgram.vue';
import { selectors } from 'src/store/selectors';

@Component({
  components: {
    TimingBlock,
    PlayerButton,
    MarqueeText,
    PlayPauseStop,
    IconSVG,
    PlayerVolume,
    PlayerProgressProgram,
  },
})
export default class PlayerControlsMinimized extends PlayerVodArchive {
  IconExpandView = IconExpandView;
  IconCross = IconCross;
  IconInfo = IconInfo;
  IconMediaNext = IconMediaNext;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop({ default: false })
  isDvrDisabled!: boolean;

  @Prop({ default: false })
  hasError!: boolean;

  @Prop()
  currentProgram!: TProgramEnhanced;

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get timeForTv() {
    return this.currentProgram?.startTimeHM || '';
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store) || '';
  }

  showOverlay() {
    actions.player.showOverlay(this.$store);
  }

  expandView() {
    actions.player.expandPlayer(this.$store);
    actions.player.lockScroll(this.$store);
    actions.common.setShowNotificationWithDetails(this.$store, false);
  }

  async showDetails() {
    if (this.isTv) {
      await this.showChannelDetails();
      this.gaEvent({ category: 'player_controls', action: 'Клик по кнопке "Info"' });
    } else {
      await this.showTitleDetails();
    }
  }

  async showChannelDetails() {
    await actions.tvChannels.showChannelDetails(this.$store, this.currentChannelId, true);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
// minimized player is shown only for screens >= 600 px

.player-controls-minimized.responsive-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 auto;

  @include mobile-and-tablet {
    padding: 0 32px;
  }

  > * {
    position: relative;
    z-index: var(--z-2);
  }

  .play-pause-stop {
    margin-right: 8px;
  }

  .media-next {
    margin-right: 8px;

    @include mobile-and-tablet {
      display: none;
    }
  }

  .player-progress-programs {
    display: flex;
    align-items: center;
    max-width: calc(100% - 540px);
    padding: 0;

    @include mobile-and-tablet {
      max-width: calc(100% - 320px);
    }

    &.full-view {
      display: none;
    }

    .marquee-wrap {
      flex-direction: column;
      align-items: normal;
    }
  }

  .extra-controls {
    display: flex;
    justify-content: center;
    margin-left: auto;
    text-align: center;

    > * {
      margin-right: 16px;

      @include mobile-and-tablet {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // TV player only
  .player-progress-program {
    flex-direction: row;
    width: 100%;

    &::v-deep {
      .top {
        margin-bottom: 0;

        .label {
          display: none;
        }

        .timing {
          margin-right: 16px;
        }
      }

      .program {
        flex-direction: row;
      }
    }
  }
}
</style>
