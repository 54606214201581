<template>
  <div v-if="calendar.length > 0" class="calendar">
    <div class="slider">
      <div ref="days" :style="{ transform: `translateX(${transform}px)` }" class="days">
        <button
          v-for="day in calendar"
          :key="day.epochDay"
          type="button"
          class="day"
          :class="{ active: day.epochDay === pickedEpochDay }"
          @click="setPickedEpochDay(day.epochDay)"
        >
          <span class="day-of-week" v-text="day.dayOfWeek" />
          <span class="day-string" v-text="day.dayString" />
        </button>
      </div>
    </div>

    <PlayerButton
      v-show="!hideLeftArrow"
      class="slider-control slider-control-left"
      @click="slideLeft"
    >
      <IconSVG :svg="IconAngleLeft" />
    </PlayerButton>
    <PlayerButton
      v-show="!hideRightArrow"
      class="slider-control slider-control-right"
      @click="slideRight"
    >
      <IconSVG :svg="IconAngleRight" />
    </PlayerButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { EPG_SLIDER_LEFT_COUNT, EPG_SLIDER_RIGHT_COUNT } from 'src/constants';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconAngleRight from 'src/svg/angle-right.svg';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';

@Component({
  components: {
    IconSVG,
    PlayerButton,
  },
})
export default class PlayerEpgCalendar extends Vue {
  IconAngleLeft = IconAngleLeft;
  IconAngleRight = IconAngleRight;

  transform = 0;
  positionLeftItem = 0;
  maxPosition = 0;
  daysContainerWidth = 640;
  dayContainerWidth = 75;
  step = 79;
  hideLeftArrow = false;
  hideRightArrow = false;

  @Prop()
  calendar?: any;

  @Watch('calendar')
  onCalendarChange() {
    this.initSlider();
  }

  get pickedEpochDay() {
    return selectors.player.pickedEpochDaySelector(this.$store);
  }

  mounted() {
    this.initSlider();
  }

  setPickedEpochDay(day: number) {
    actions.player.setPickedEpochDay(this.$store, day);
  }

  initSlider() {
    const currentDayIndex = this.calendar.findIndex(
      (day: { epochDay: number }) => day.epochDay === this.pickedEpochDay
    );

    this.maxPosition = this.calendar.length + 1;
    this.positionLeftItem =
      currentDayIndex <= EPG_SLIDER_LEFT_COUNT ? 0 : currentDayIndex - EPG_SLIDER_LEFT_COUNT;
    this.hideLeftArrow = this.positionLeftItem <= 0;
    this.hideRightArrow = this.positionLeftItem + EPG_SLIDER_RIGHT_COUNT === this.maxPosition;
    this.transform = -this.positionLeftItem * this.step;
  }

  slideRight() {
    if (this.positionLeftItem + EPG_SLIDER_RIGHT_COUNT === this.maxPosition) {
      return;
    }

    this.positionLeftItem++;
    this.transform -= this.step;

    this.hideRightArrow = this.positionLeftItem + EPG_SLIDER_RIGHT_COUNT === this.maxPosition;
    if (this.hideLeftArrow) {
      this.hideLeftArrow = false;
    }
  }

  slideLeft() {
    if (this.positionLeftItem <= 0) return;

    this.positionLeftItem--;
    this.transform += this.step;

    this.hideLeftArrow = this.positionLeftItem === 0;
    if (this.hideRightArrow) {
      this.hideRightArrow = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.calendar {
  position: relative;

  .slider-control {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 4px;
    transform: translateY(-50%);
  }

  .slider-control-left {
    left: 0;
  }

  .slider-control-right {
    right: 0;
  }

  .slider {
    overflow: hidden;

    .days {
      white-space: nowrap;
      transition: transform 0.4s ease;

      .day {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 72px;
        padding: 8px;
        margin: 0 2px;
        background-color: transparent;
        border-radius: 3px;

        span {
          display: block;
        }

        @include devices-with-hover {
          &:hover {
            background-color: var(--alpha-dark-3);
          }
        }

        &.active {
          background-color: var(--alpha-dark-5);
        }

        .day-of-week {
          margin-bottom: 0;
          color: var(--c-dark-font-primary);
          @include heading5;
        }

        .day-string {
          color: var(--c-dark-font-secondary);
          @include caption2;
        }
      }
    }
  }
}
</style>
