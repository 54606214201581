<template>
  <transition name="fade-height" mode="out-in">
    <button
      class="player-control-live player-control-button body1"
      type="button"
      :class="{ active: isLive }"
      @click="goLive"
    >
      <IconSVG :svg="IconLive" :size="24" />
      <span class="text" v-html="text" />
    </button>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconLive from 'src/svg/player/live.svg';

@Component({
  components: {
    PlayerButton,
    IconSVG,
  },
})
export default class PlayerControlLive extends SequoiaComponent {
  IconLive = IconLive;

  get text() {
    return this.getTranslation(`${this.isLive ? 'on_the' : 'to'}_air`);
  }

  get isLive() {
    return selectors.player.isLiveSelector(this.$store);
  }

  goLive() {
    if (this.isLive) {
      return;
    }
    this.gaEvent({ category: 'player_controls', action: 'Клик по кнопке "К эфиру"' });
    actions.player.goLive(this.$store, this.$events);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-control-live {
  .text {
    @include mobile {
      display: none;
    }
  }

  @include devices-with-hover {
    &:hover {
      width: 100%;
      padding-right: 8px;
      background-color: var(--alpha-dark-4);
    }
  }

  &.active {
    cursor: default;
    background-color: var(--alpha-dark-2);

    .icon {
      color: var(--c-dark-brand-darker);
      cursor: default;
    }

    @include mobile {
      background-color: transparent;
    }
  }

  // animation
  .fade-height-enter-active,
  .fade-height-leave-active {
    transition: opacity 0.15s var(--ease-out);
  }

  .fade-height-enter,
  .fade-height-leave-to {
    opacity: 0;
  }

  .fade-height-enter-to,
  .fade-height-leave {
    max-height: 100px;
  }
}
</style>
