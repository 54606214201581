<template>
  <div
    class="player-tv player-tv-vod-archive dark"
    :class="{
      mobile: isMobile,
      fullscreen: isFullscreen,
      minimized: isPlayerMinimized && !isFullscreen,
      'vitrina-tv': isCurrentChannelWithVitrina,
    }"
    data-cy="player-tv"
  >
    <!-- Vitrina player -->
    <iframe
      v-if="isCurrentChannelWithVitrina"
      class="vitrina-iframe"
      :src="currentChannelVitrinaUrl"
    />

    <!-- Brand player -->
    <div
      v-if="!isCurrentChannelWithVitrina"
      ref="player"
      class="player"
      @click="tryFocusOnPlayerContainer"
      @mousemove="showOverlay()"
    >
      <div class="video-wrap" data-cy="video-wrap">
        <video
          ref="video"
          class="video"
          :class="{ scaled: isVideoScaled }"
          :muted="isVideoMuted"
          :controls="false"
          playsinline
        />
      </div>

      <div
        v-if="showPoster"
        class="poster"
        :style="{ backgroundImage: `url(${videoPoster})` }"
        :class="{ fullscreen: isFullscreen }"
      />

      <div v-if="(isPlayerLoading || !isPlayerLoaded) && !hasPlayerError" class="loader">
        <LoaderSpinner />
      </div>

      <div
        ref="overlay"
        class="overlay"
        data-cy="player-tv-overlay"
        :class="{ hidden: shouldHideOverlay, 'over-poster': showPoster }"
      >
        <PlayerFullscreenMenu v-if="isFullscreenMenuVisible" />

        <template v-else>
          <PlayerHead
            :is-disabled="isOffline"
            :ios-fullscreen-available="iosFullscreenAvailable"
            @closePlayer="closePlayer"
            @toggleFullscreen="toggleFullscreen"
          />

          <PlayerErrors v-if="hasPlayerError" />

          <PlayerControlsMobile
            :has-error="hasPlayerError"
            :is-disabled="isPlayerLoading || isOffline || hasPlayerError"
            @play="play"
            @pause="pause"
            @actualizeVolume="actualizeVolume"
            @togglePlayPause="togglePlayPause"
            @toggleFullscreen="toggleFullscreen"
            @onScroll="$emit('onScroll', $event)"
          />

          <div v-if="currentProgram" class="timing-and-episode" :class="{ 'mt-0': hasPlayerError }">
            <TimingBlock
              class="pl-24"
              :class="{ 'mt-0': hasPlayerError }"
              :time="currentProgram.startTimeHM || ''"
            />

            <div
              class="mobile-episode body2 color-dark-font-primary"
              v-text="currentProgram.title"
            />
          </div>

          <PlayerControls
            ref="playerControls"
            class="hidden-mobile"
            :has-error="hasPlayerError"
            :is-disabled="isPlayerLoading || isOffline || hasPlayerError"
            @play="play"
            @pause="pause"
            @togglePlayPause="togglePlayPause"
            @actualizeVolume="actualizeVolume"
            @toggleFullscreen="toggleFullscreen"
            @onScroll="$emit('onScroll', $event)"
          />

          <PlayerControlsMinimized
            :has-error="hasPlayerError"
            :is-disabled="isPlayerLoading || isOffline"
            :current-program="currentProgram || undefined"
            @actualizeVolume="actualizeVolume"
            @togglePlayPause="togglePlayPause"
            @closePlayer="closePlayer"
          />

          <PlayerProgress :has-error="hasPlayerError" />
        </template>

        <ClickableUnderlay
          v-if="!hasPlayerError"
          @play="play"
          @pause="pause"
          @toggleFullscreen="toggleFullscreen"
        />
      </div>
    </div>

    <div class="notifications-container">
      <NotificationWithDetails
        v-if="showNotification"
        :title="getTranslation('tv_channel_unavailable')"
        :message="playerError ? playerError.message : ''"
        :icon="IconInfo"
        @hide="closeNotificationWithDetails"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import {
  CHANNEL_PLAYING_STATISTIC_SAFE_TIME_IN_SECONDS,
  CHUNK_LENGTH,
  EVENTS,
  HTTP_CODES,
  HTTP_PLAYER_BAD_RESPONSE_CODES,
  PLAYER_ERROR_CODES,
  SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER,
  TV_SPECIAL_GENRES,
} from 'src/constants';
import { getIsFullscreen, storage } from 'src/utils';
import { getBrowserName, BROWSERS, getOsFlags, getDeviceFlags } from 'src/utils/platform-detector';
import { convertToSeconds } from 'src/utils/time/convert-time';
import { TPlayerErrorCodes } from 'src/store/player/types';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import PlayerHead from 'src/components/player/parts/common/PlayerHead.vue';
import PlayerCenter from 'src/components/player/parts/tv/PlayerCenter.vue';
import PlayerControls from 'src/components/player/parts/common/player-controls/PlayerControls.vue';
import PlayerProgress from 'src/components/player/parts/tv/progress/PlayerProgress.vue';
import PlayerFullscreenMenu from 'src/components/player/parts/tv/fullscreen/PlayerFullscreenMenu.vue';
import {
  HTMLDivWithFullscreenElement,
  HTMLVideoWithFullscreenElement,
  LoaderResponseFixed,
} from 'src/components/player/types';
import * as api from 'src/api';
import logger from 'src/utils/logger';
import { mp4toJSON } from 'src/utils/hls/mp4';
import type THls from 'hls.js/dist/hls.js';
import { getChannelLogo, getChannelNumber, getChannelTitle } from 'src/utils/channel';
import { makePath, matchUrlProtocolWithLocationProtocol } from 'src/utils/url';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getResizedImageUrl } from 'src/utils/images';
import GetAndSetWindowWidth from 'src/mixins/GetAndSetWindowWidth';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import IconInfo from 'src/svg/info.svg';
import PlayerControlsMinimized from 'src/components/player/parts/common/player-controls/PlayerControlsMinimized.vue';
import { loadEpgForChannel } from 'src/store/tv-epg/actions';
import ClickableUnderlay from 'src/components/player/parts/common/ClickableUnderlay.vue';
import PlayerErrors from 'src/components/player/parts/common/PlayerErrors.vue';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import { channelByIdSelector } from 'src/store/tv-channels/selectors';
import PlayerControlsMobile from 'src/components/player/parts/common/player-controls/PlayerControlsMobile.vue';
import TimingBlock from 'src/components/player/parts/common/TimingBlock.vue';
import { videoDataSelector } from 'src/store/vod/selectors';
import { ErrorData, Events } from 'hls.js';

const log = logger('player-tv');

let HLS: typeof THls; // async import in mounted()

interface EpgChannel {
  channelId: string;
  channelChanged?: boolean;
  timestamp?: number; // needed for playing programs from archive
  forced?: boolean;
}

@Component({
  components: {
    TimingBlock,
    PlayerControlsMobile,
    ModalSequoia,
    PlayerErrors,
    PlayerCenter,
    PlayerHead,
    PlayerControls,
    PlayerProgress,
    LoaderSpinner,
    PlayerFullscreenMenu,
    IconSVG,
    PlayerControlsMinimized,
    ClickableUnderlay,
    NotificationWithDetails,
  },
})
export default class PlayerTv extends mixins(SequoiaComponent, GetAndSetWindowWidth) {
  IconCross = IconCross;
  IconInfo = IconInfo;

  everySecondIntervalId?: NodeJS.Timeout;

  showPoster = false;
  channelId? = '';

  // COMMON FOR ALL PLAYERS
  hls?: THls | null;
  hlsDebug = false;

  videoWidth = 0;
  scrollPosition = 0;
  iosFullscreenAvailable = true;

  networkChange = {
    reloadAttempt: 1,
    maxAttempts: 3,
  };

  isVideoActuallyPlaying = false;

  @Ref('player')
  readonly refPlayer!: HTMLDivWithFullscreenElement;

  @Ref('video')
  readonly refVideo!: HTMLVideoWithFullscreenElement;

  @Ref('overlay')
  readonly refOverlay?: HTMLDivElement;

  @Ref('playerControls')
  readonly refPlayerControls!: PlayerControls;

  @Watch('isTvDataStateLoaded')
  async onTvDataStateLoadedChange(isLoaded: boolean) {
    if (!isLoaded) {
      await this.closePlayer();
      history.pushState({}, '', makePath(`/channels/${this.pageRoute}`));
    }
  }

  @Watch('subtitleDisplay')
  onSubtitleDisplayChange(val: boolean) {
    if (this.hls) {
      this.hls.subtitleDisplay = val;
      this.hls.subtitleTrack = val ? 0 : -1;
      log.info('subtitleDisplay has been set to ', val);
    }
  }

  @Watch('currentProgramId')
  onCurrentProgramChange() {
    this.$store.player.video.hasFps50 = false;

    const defaultPoster = this.getByLanguage(
      this.$store.siteConfig?.wlSpecials?.QSTvBackground
    )?.url;

    const poster =
      this.currentProgramImage ||
      (this.currentChannel ? this.getChannelLogo(this.currentChannel) : defaultPoster) ||
      '';

    actions.player.setPoster(this.$store, poster);
    log.info('update poster on currentProgram change', this.videoPoster);
  }

  @Watch('$store.player.visible')
  onPlayerVisibilityChange() {
    if (this.refVideo) {
      this.videoWidth = this.refVideo.offsetWidth;
    }
  }

  get showNotification() {
    return (
      !this.isAnonymous &&
      this.hasPlayerError &&
      this.showNotificationWithDetails &&
      (this.isPlayerMinimized || this.isMobile)
    );
  }

  get isTvDataStateLoaded() {
    return this.$store.flags.tvDataState.loaded;
  }

  get showNotificationWithDetails() {
    return selectors.common.showNotificationWithDetailsSelector(this.$store);
  }

  get videoPoster() {
    return selectors.player.videoPosterSelector(this.$store);
  }

  get isVideoScaled() {
    return selectors.player.isVideoScaledSelector(this.$store);
  }

  get isVideoMuted() {
    return selectors.player.isVideoMutedSelector(this.$store);
  }

  get isOffline() {
    return selectors.common.isOfflineSelector(this.$store);
  }

  get wasPlayingBeforeOffline() {
    return selectors.player.wasPlayingBeforeOfflineSelector(this.$store);
  }

  get pageRoute() {
    return this.isChannelsCatalogNowOpen ? 'now' : 'list';
  }

  get lastPause() {
    return selectors.pauses.lastTvPause(this.$store);
  }

  get isChannelsCatalogNowOpen() {
    return selectors.tvChannels.isChannelsCatalogNowOpenSelector(this.$store);
  }

  get serverTimeMs() {
    return selectors.appInfo.serverTimeMsSelector(this.$store);
  }

  get isMobileDevice() {
    return getDeviceFlags().isMobile;
  }

  get isMobile() {
    return this.windowWidth < SCREEN_MIN_WIDTH_FOR_SHOWING_PLAYER || this.isMobileDevice;
  }

  get shouldHideOverlay() {
    return !this.isOverlayVisible && this.isPlayerPlaying && !this.hasPlayerError;
  }

  get playerError() {
    return selectors.player.errorSelector(this.$store);
  }

  get hasPlayerError() {
    return selectors.player.hasErrorSelector(this.$store);
  }

  get howManyTimesBodyScrollWasLocked() {
    return selectors.common.howManyTimesBodyScrollWasLockedSelector(this.$store);
  }

  get isQuickSubscribeModalOpen() {
    return selectors.QS.isModalOpenSelector(this.$store);
  }

  get playDurationMs() {
    return selectors.player.playDurationMsSelector(this.$store);
  }

  get isLive() {
    return selectors.player.isLiveSelector(this.$store);
  }

  get videoUrl() {
    let url = null;
    const playback = selectors.tvCurrentChannel.playbackInfoDetailsSelector(this.$store);

    if (!playback) {
      log.error('No playback info details');
      return url;
    }

    const rendition = selectors.tvCurrentChannel.currentRenditionSelector(this.$store);
    url = rendition?.url;

    if (url) {
      url = matchUrlProtocolWithLocationProtocol(url, this.$store.common.isHttps);

      if (!this.isLive && this.currentDelay > CHUNK_LENGTH) {
        url += `&delay=${this.currentDelay}`;
      }
    } else {
      log.error('no URL in current rendition', rendition);
    }
    return url;
  }

  get playingTimeMs() {
    return selectors.player.playingTimeMsSelector(this.$store);
  }

  get currentDelay() {
    let delay = 0;
    if (this.hasDvr) {
      delay = this.serverTimeMs - this.playingTimeMs;
    }
    return Math.max(0, Math.floor(convertToSeconds(delay, 'millisecond')));
  }

  get subtitleDisplay() {
    return selectors.player.subtitleDisplaySelector(this.$store);
  }

  get hasSubtitles() {
    return selectors.player.hasSubtitlesSelector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get currentChannelId() {
    return this.$route.params.channelId || this.currentChannel?.id || '';
  }

  get currentChannelAnyAvailableLanguageIndex() {
    return selectors.tvCurrentChannel.anyAvailableLanguageIndexSelector(this.$store);
  }

  get hasDvr() {
    return selectors.tvEpg.hasDvrSelector(this.$store);
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get programs() {
    return selectors.tvEpg.programsSelector(this.$store);
  }

  get currentProgram() {
    return selectors.tvEpg.currentProgramSelector(this.$store);
  }

  get currentProgramId() {
    return selectors.tvEpg.currentProgramSelector(this.$store)?.id || null;
  }

  get currentProgramImage() {
    if (this.currentProgram?.image) {
      return getResizedImageUrl(this.currentProgram.image, 880, 494);
    }
    return '';
  }

  get isPlayerPlaying() {
    return selectors.player.isPlayingSelector(this.$store);
  }

  get isPlayerLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get isPlayerLoaded() {
    return selectors.player.isLoadedSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isFullscreenMenuVisible() {
    return selectors.player.isFullscreenMenuVisibleSelector(this.$store);
  }

  get isOverlayVisible() {
    return selectors.player.isOverlayVisibleSelector(this.$store);
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get shouldAddToRecentlyWatched() {
    return this.currentGenre !== TV_SPECIAL_GENRES.recentlyWatched;
  }

  get useHls() {
    return !getOsFlags().isIos;
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get isCurrentChannelWithVitrina() {
    return selectors.tvCurrentChannel.isCurrentChannelWithVitrinaSelector(this.$store);
  }

  get currentChannelVitrinaUrl() {
    return selectors.tvCurrentChannel.currentChannelVitrinaUrlSelector(this.$store);
  }

  get shouldStartFromPause() {
    return selectors.player.shouldStartFromPauseSelector(this.$store);
  }

  get dvrRestrictionMessage() {
    return selectors.tvCurrentChannel.dvrRestrictionMessageSelector(this.$store);
  }

  serverPrefetch() {
    actions.player.setPlayerType(this.$store, 'tv');
  }

  async mounted() {
    log.info('TV player was mounted');
    actions.player.setPlayerType(this.$store, 'tv');
    actions.player.setHasSubtitles(this.$store, false);

    if (this.isFullscreen) {
      this.toggleFullscreen();
    }

    document.onfullscreenchange =
      document.onwebkitfullscreenchange =
      document.onmozfullscreenchange =
        this.actualizeFullscreen.bind(this); // this could be triggered when user exits from the fullscreen mode by pressing esc

    this.showPoster = true;

    // bind events
    this.$events.on(EVENTS.channelSelect, this.onChannelSelect);
    this.$events.on(EVENTS.player.play, this.onPlay);
    this.$events.on(EVENTS.player.pause, this.onPause);
    this.$events.on(EVENTS.player.reloadStream, this.reloadStream);
    window.addEventListener('online', this.onGoOnline);
    window.addEventListener('offline', this.onGoOffline);
    // TODO waiting for mobile player's design update
    // window.addEventListener('orientationchange', this.onOrientationchange);
    window.addEventListener('keydown', this.onKeydown);
    window.addEventListener('keyup', this.onKeyup);
    window.addEventListener('visibilitychange', this.sendStatOnSessionEnd); // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session

    actions.player.setReady(this.$store, true);

    this.refVideo?.addEventListener('waiting', this.onWaiting);
    this.refVideo?.addEventListener('loadedmetadata', this.onLoadedMetadata);
    this.refVideo?.addEventListener('webkitendfullscreen', this.actualizeFullscreen.bind(this));

    this.actualizeVolume();
    this.everySecondIntervalId = setInterval(this.everySecond, 1000);

    if (this.isAnonymous && this.$route.fullPath.search('watch') >= 0) {
      this.setShowNotificationAuthAndReg();
    }

    if (this.currentChannelId) {
      await loadEpgForChannel(this.$store, this.currentChannelId).catch((err) => {
        log.error('loadEpgForChannel', err);
      });
      await actions.pauses.loadTvPauses(this.$store, this.currentChannelId);
      await actions.tvChannels.selectChannel(
        this.$store,
        this.currentChannelId,
        this.$events,
        this.shouldAddToRecentlyWatched,
        false,
        this.shouldStartFromPause ? this.lastPause.time : this.playingTimeMs || 0
      );
    }

    this.iosFullscreenAvailable = typeof this.refVideo?.webkitEnterFullScreen === 'function';

    this.gaEvent({
      category: 'player_controls',
      action: 'Запуск контента',
      channel_name: this.channelId,
    });
  }

  async beforeDestroy() {
    log.info('tv-player beforeDestroy');
    await this.pause();

    if (this.everySecondIntervalId) {
      clearInterval(this.everySecondIntervalId);
    }

    this.$events.off(EVENTS.channelSelect, this.onChannelSelect);
    this.$events.off(EVENTS.player.play, this.onPlay);
    this.$events.off(EVENTS.player.pause, this.onPause);
    this.$events.off(EVENTS.player.reloadStream, this.reloadStream);

    document.onfullscreenchange =
      document.onwebkitfullscreenchange =
      document.onmozfullscreenchange =
        null;

    window.removeEventListener('online', this.onGoOnline);
    window.removeEventListener('offline', this.onGoOffline);
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('keyup', this.onKeyup);
    window.removeEventListener('visibilitychange', this.sendStatOnSessionEnd);
    // TODO waiting for mobile player's design update
    // window.removeEventListener('orientationchange', this.onOrientationchange);

    await this.closePlayer();
  }

  setShowNotificationAuthAndReg() {
    actions.common.showNotificationAuthAndReg(this.$store);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  getChannelNumber(channel: TChannelEnhanced) {
    return getChannelNumber(channel);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getResizedImageUrl(getChannelLogo(channel), 880, 494);
  }

  async savePause() {
    if (!this.channelId || this.isDvrDisabled) {
      return;
    }
    const data = {
      contentId: this.channelId,
      time: Math.floor(convertToSeconds(this.playingTimeMs, 'millisecond')),
      channelId: this.channelId,
    };
    try {
      await api.pauses.saveTvPause({
        jsonData: true,
        data,
      });
      await actions.pauses.loadTvPauses(this.$store, this.channelId);
    } catch (err) {
      // do nothing
    }
  }

  async onLoadedMetadata() {
    if (this.hls && this.hasSubtitles) {
      this.hls.subtitleDisplay = this.subtitleDisplay;
      this.hls.subtitleTrack = this.subtitleDisplay ? 0 : -1;
    }
  }

  async initHls() {
    if (!HLS) {
      HLS = (await import(/* webpackChunkName: "hls.js" */ 'hls.js')).default;
    }

    this.destroyHls();

    log.info('initHls');

    actions.player.clearError(this.$store);

    const config = {
      debug: storage.get('__debug_hls__') === 1 || this.hlsDebug,
    };

    let videoFragmentNumber = 1;
    const renditionId = selectors.tvCurrentChannel.currentRenditionSelector(this.$store)?.id;
    const isMaxQuality = typeof renditionId === 'string' && renditionId.toLowerCase() === 'max';

    this.hls = new HLS(config);

    if (this.hls) {
      this.hls.subtitleDisplay = this.subtitleDisplay;
    }

    this.hls?.on(HLS.Events.MANIFEST_LOADED, (event, data) => {
      const subtitles = data?.subtitles || [];
      const subtitlesLength = subtitles?.length;

      log.info(
        `HLS EVENT: MANIFEST_LOADED. Subtitle track(s) length: ${subtitlesLength}`,
        'subtitleTracks:',
        subtitles
      );
      actions.player.setHasSubtitles(this.$store, !!this.hls && !!subtitlesLength);
    });

    this.hls?.on(HLS.Events.MEDIA_ATTACHED, () => {
      // fired when video html element has been successfully attached to hls instance
      log.info('HLS EVENT: MEDIA_ATTACHED. Video html-element was attached to hls');
      this.attachUrl();
    });

    this.hls?.on(HLS.Events.BUFFER_APPENDING, (event, data) => {
      if (data.type === 'video') {
        const isChromiumBasedBrowser = navigator.userAgent.includes(BROWSERS.chrome);
        if (videoFragmentNumber === 2 && data.data && isMaxQuality && isChromiumBasedBrowser) {
          // if it's the second fragment and type of the first frame of the fragment is not IDR (Instantaneous Decoder Refresh) - Chromium-based browsers will not be able to play this stream
          // see https://dev.tightvideo.com/issues/39749#note-9
          const mdat = mp4toJSON(data.data as any)?.[1];
          const showMaxQualityError = mdat && !mdat.nals?.includes('IDR');
          if (showMaxQualityError) {
            this.showMaxQualityError();
          }
        }
        videoFragmentNumber++;
      }
    });

    this.hls?.on(HLS.Events.FRAG_LOADED, () => {
      if (this.isPlayerLoading) {
        actions.player.stopLoading(this.$store);
      }
    });

    this.hls?.on(HLS.Events.ERROR, this.handleHlsError);
    this.hls?.attachMedia(this.refVideo); // attach video HTML element
  }

  async handleHlsError(event: Events.ERROR, data: ErrorData) {
    let isFatalError = data.fatal; // fatal should also be when stream returns 400, 404 or 500

    const response = data.response as LoaderResponseFixed;
    const responseCode = response?.code;
    const isBadResponse = HTTP_PLAYER_BAD_RESPONSE_CODES.includes(responseCode);
    const isNetworkError = data.type === HLS.ErrorTypes.NETWORK_ERROR;
    const isError404 = isNetworkError && responseCode === HTTP_CODES.NOT_FOUND;
    const isManifestParsingError = data.details === HLS.ErrorDetails.MANIFEST_PARSING_ERROR; // responseStatus could be 200, but it should be considered as fatal as well
    const isFatalStatus = isNetworkError && (isBadResponse || isManifestParsingError);
    isFatalError = isFatalStatus || isFatalError;

    if (
      isNetworkError &&
      responseCode === 403 &&
      this.networkChange.reloadAttempt <= this.networkChange.maxAttempts
    ) {
      // probably network has been changed (e.g. VPN has been turned on)
      try {
        log.info(
          'Chunk load returns 403, maybe network has been changed... Reloading stream. Attempt',
          this.networkChange.reloadAttempt
        );
        this.networkChange.reloadAttempt++;
        this.refVideo?.pause();
        this.destroyHls();
        await actions.pauses.loadTvPauses(this.$store, this.currentChannelId);
        if (this.currentChannel) {
          await this.loadPlaybackInfoDetails(this.currentChannel);
          await this.play();
        }
        return;
      } catch {
        // do nothing
      }
    }

    if (isFatalError) {
      // fatal error – when stream is unavailable
      log.error('HLS ERROR ', event, data);
      this.showPlayerNetworkError();
      actions.player.stopLoading(this.$store);
    } else {
      // just a warning when buffering is in progress
      log.warning('HLS WARNING ', event, data);
    }

    switch (data.details) {
      case HLS.ErrorDetails.BUFFER_STALLED_ERROR:
        // when buffering is started, see https://github.com/video-dev/hls.js/issues/2113#issuecomment-460682147
        actions.player.startLoading(this.$store);
        this.isVideoActuallyPlaying = false;
        break;
      // в сафари не срабатывает BUFFER_NUDGE_ON_STALL
      case HLS.ErrorDetails.BUFFER_NUDGE_ON_STALL:
        // when buffering is finished, see https://github.com/video-dev/hls.js/issues/2113#issuecomment-460682147
        actions.player.stopLoading(this.$store);
        this.isVideoActuallyPlaying = true;
        break;
    }

    if (isFatalStatus) {
      // mark language as unavailable,
      // this could also be just an unavailable program, see https://lifestream.atlassian.net/browse/SEQ-1319
      // unavailable flag should be removed, on program select
      actions.tvCurrentChannel.markCurrentLanguageAsUnavailable(this.$store);

      if (this.currentChannelAnyAvailableLanguageIndex > -1) {
        // if there are more than one language, and there are available languages -
        // switch to another one, otherwise - stop and show error
        log.warning(
          'language is unavailable, switching to language index = ',
          this.currentChannelAnyAvailableLanguageIndex
        );

        actions.tvCurrentChannel.setLanguageIndex(
          this.$store,
          this.currentChannelAnyAvailableLanguageIndex
        );

        if (this.$store.player.video.wasLanguageChanged) {
          actions.player.setAlert(this.$store, this.getTranslation('language_unavailable'));
          this.$store.player.video.wasLanguageChanged = false;
        }

        this.attachUrl();
      } else {
        actions.player.setError(
          this.$store,
          PLAYER_ERROR_CODES.COMMON,
          this.getNetworkErrorMessage(isError404)
        );
        await this.pause();
        actions.player.stopLoading(this.$store);
      }
    }
  }

  clearAllErrors() {
    actions.player.clearError(this.$store);
  }

  destroyHls() {
    if (this.hls) {
      log.info('destroyHls');
      this.hls.destroy();
    }
    this.hls = null;
  }

  // when video is buffering
  onWaiting() {
    actions.player.startLoading(this.$store);
    this.isVideoActuallyPlaying = false;
  }

  async onKeydown(event: KeyboardEvent) {
    if ((event.target as HTMLInputElement)?.tagName?.toLowerCase() === 'input') {
      return;
    }

    const code = (event.code === 'KeyF' ? event.code : event.key || event.code)?.toLowerCase();

    // when cmnd/ctrl is pressed in conjunction with other keys -> do nothing
    if (!code || event.metaKey || event.ctrlKey) {
      return;
    }

    switch (code) {
      case 'space':
      case ' ':
        event.preventDefault();
        if (this.isPlayerPlaying) {
          actions.player.setIsLive(this.$store, false);
          await this.pause();
        } else {
          await this.play();
        }

        if (this.isDvrRestricted) {
          actions.player.setAlert(this.$store, this.dvrRestrictionMessage);
        }

        // TODO refactor getTranslation in Global.ts in order to use it in actions
        // TODO once getTranslation is refactored move DVR setAlert call to actions.player.pause
        if (this.isDvrDisabled) {
          actions.player.setAlert(this.$store, this.getTranslation('dvr_disabled_error'));
        }

        this.gaEvent({
          category: 'player_controls',
          action: this.isPlayerPlaying ? 'Pause' : 'Play',
          control_type: 'keyboard',
        });
        break;

      case 'arrowleft':
        event.preventDefault();
        this.refPlayerControls.startRewind(event);
        this.gaEvent({
          category: 'player_controls',
          action: 'Rewind',
          control_type: 'keyboard',
        });
        break;

      case 'arrowright':
        event.preventDefault();
        this.refPlayerControls.startFastForward(event);
        break;

      case 'pageup':
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (this.howManyTimesBodyScrollWasLocked > 1) {
          return;
        }
        await actions.tvChannels.selectPrevChannel(
          this.$store,
          this.$events,
          this.shouldAddToRecentlyWatched
        );
        this.gaEvent({
          category: 'player_controls',
          action: 'Предыдущий канал',
          control_type: 'keyboard',
        });
        if (this.currentChannel) {
          await actions.QS.handleForTv(this.$store, this.currentChannel);

          if (
            this.isFullscreen &&
            (this.isQuickSubscribeModalOpen || this.isCurrentChannelWithVitrina)
          ) {
            this.toggleFullscreen();
          }

          this.setMetaTitleForPlayingChannel();
        }
        break;

      case 'pagedown':
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (this.howManyTimesBodyScrollWasLocked > 1) {
          return;
        }
        await actions.tvChannels.selectNextChannel(
          this.$store,
          this.$events,
          this.shouldAddToRecentlyWatched
        );
        this.gaEvent({
          category: 'player_controls',
          action: 'Следующий канал',
          control_type: 'keyboard',
        });
        if (this.currentChannel) {
          await actions.QS.handleForTv(this.$store, this.currentChannel);
          if (
            this.isFullscreen &&
            (this.isQuickSubscribeModalOpen || this.isCurrentChannelWithVitrina)
          ) {
            this.toggleFullscreen();
          }

          this.setMetaTitleForPlayingChannel();
        }
        break;

      case 'escape':
        event.preventDefault();
        actions.player.hideFullscreenMenu(this.$store);
        break;

      case 'keyf':
        event.preventDefault();
        if (this.howManyTimesBodyScrollWasLocked > 1) {
          return;
        }
        this.toggleFullscreen();
        break;
    }
  }

  onKeyup(event: KeyboardEvent) {
    const code = (event.key || event.code)?.toLowerCase();
    if (!code) {
      return;
    }

    switch (code) {
      case 'arrowleft':
        this.refPlayerControls.stopRewind();
        break;
      case 'arrowright':
        this.refPlayerControls.stopFastForward();
        break;
    }
  }

  // TODO waiting for mobile player's design update
  // onOrientationchange() {
  //   if (typeof window !== 'undefined' && window.innerHeight < window.innerWidth) {
  //     this.pause();
  //   } else {
  //     this.play();
  //   }
  //   this.exitFullScreenOnAndroid();
  // }

  sendStatOnSessionEnd() {
    if (this.isPlayerPlaying && document.visibilityState === 'hidden') {
      this.sendStatistics();
    }
  }

  actualizeVolume() {
    actions.player.actualizeVolume(this.$store, this.refVideo);
  }

  // -----------------------------------------
  // Fullscreen
  // -----------------------------------------
  toggleFullscreen() {
    if (!this.refPlayer) {
      return;
    }

    if (!getIsFullscreen()) {
      if (this.isMobile && this.refVideo?.webkitEnterFullScreen) {
        this.refVideo?.webkitEnterFullScreen();
        actions.player.toggleFullscreen(this.$store);
      } else {
        actions.player.goFullscreen(this.$store, this.refPlayer);
      }

      this.scrollPosition = window.pageYOffset;
      this.gaEvent({ category: 'player_controls', action: 'Включить fullscreen' });
    } else {
      actions.player.exitFullscreen(this.$store);
      this.gaEvent({ category: 'player_controls', action: 'Выйти из fullscreen' });
    }
  }

  /**
   * This method should be called on document.onfullscreenchange event
   */
  actualizeFullscreen() {
    const isFullscreen = getIsFullscreen();
    this.$store.player.video.isFullscreen = isFullscreen;
    this.videoWidth = this.refVideo?.offsetWidth || 0;

    if (isFullscreen) {
      actions.tvEpg.resetSearchQuery(this.$store); // https://lifestream.atlassian.net/browse/SEQ-1248
    } else {
      actions.player.hideFullscreenMenu(this.$store);
      window.scrollTo(0, this.scrollPosition);
    }
  }

  exitFullScreenOnAndroid() {
    actions.player.exitFullscreenOnAndroid(this.$store, this.isFullscreen);
  }

  // -----------------------------------------
  // -----------------------------------------

  showOverlay() {
    actions.player.showOverlay(this.$store);
  }

  sendStatistics() {
    const secondsPlayed = convertToSeconds(this.playDurationMs, 'millisecond');
    if (secondsPlayed < CHANNEL_PLAYING_STATISTIC_SAFE_TIME_IN_SECONDS) {
      return;
    }

    actions.player.sendStats(this.$store);
  }

  /**
   * https://lifestream.atlassian.net/browse/SEQ-356
   */
  async showMaxQualityError() {
    if (getBrowserName() === BROWSERS.firefox) {
      // firefox seems to be working fine
      return;
    }
    // select first (auto) rendition
    this.showOverlay();
    actions.player.setAlert(this.$store, this.getTranslation('player_tv_max_quality_error'));
    actions.tvCurrentChannel.setRenditionIndex(this.$store, 0);
    await this.pause();
    await this.reloadStream();
    log.error('max quality error');
  }

  everySecond() {
    if (this.isVideoActuallyPlaying) {
      actions.player.updatePlayingTime(this.$store, this.playingTimeMs + 1000);
    }
  }

  attachUrl() {
    if (!this.videoUrl) {
      log.error('attachUrl: invalid videoUrl');
      actions.player.stopLoading(this.$store);
      return;
    }

    log.info('attaching stream', this.videoUrl);

    if (this.videoUrl.indexOf('liveorigin=m9-50fps') >= 0) {
      this.$store.player.video.fps50 = true;
      this.$store.player.video.hasFps50 = true;
    }

    if (this.hls) {
      this.hls.loadSource(this.videoUrl);
    } else if (!this.hls && this.refVideo) {
      this.refVideo.src = this.videoUrl;
    }
  }

  async onChannelSelect(epgChannel: EpgChannel) {
    if (this.isCurrentChannelWithVitrina) {
      log.info('Current channel is with vitrina, stopping player');
      await this.pause();
      return;
    }

    const { channelId, channelChanged, timestamp, forced } = epgChannel;

    if (!(channelChanged || forced)) {
      log.warning('channel was not changed', { channelChanged, forced });
      return;
    }

    if (channelChanged && timestamp && timestamp > 0) {
      actions.player.setIsLive(this.$store, false);
    }

    this.channelId = this.currentChannel?.id;
    log.info('onChannelSelect', epgChannel);
    this.showOverlay();

    if (this.currentChannel) {
      log.info(
        'selected channel',
        this.currentChannel.id,
        this.getChannelNumber(this.currentChannel),
        this.getChannelTitle(this.currentChannel)
      );
    } else {
      log.error('unable to load a channel by id', channelId);
      return;
    }

    try {
      actions.player.startLoading(this.$store);
      actions.player.updatePlayingTime(
        this.$store,
        timestamp && timestamp > 0 ? timestamp : this.serverTimeMs
      );

      this.clearAllErrors();

      await this.loadPlaybackInfoDetails(this.currentChannel);

      log.info('Channel is ready');

      if (selectors.player.autoplaySelector(this.$store)) {
        await this.play();
        actions.player.setAutoplay(this.$store, false);
      }

      if (this.currentChannelId) {
        try {
          await actions.pauses.loadTvPauses(this.$store, this.currentChannelId);
        } catch (err) {
          log.error('Load TV pauses error:', err);
        }
      } else {
        log.warning('Could not Load TV pauses: currentChannel.id is not available');
      }
    } catch (err) {
      this.destroyHls();
      this.showPoster = true;
      log.error('Channel is NOT ready:', err);
    } finally {
      actions.player.stopLoading(this.$store);
      actions.player.setLoaded(this.$store, true);
    }
  }

  /**
   * On window online event
   */
  async onGoOnline() {
    log.warning('Connection is back online, reloading the stream...');
    await actions.appInfo.updateServerTime(this.$store);
    actions.common.setLocalTime(this.$store, Date.now());
    actions.common.setIsOffline(this.$store, false);
    actions.player.setIsPlaying(this.$store, this.wasPlayingBeforeOffline);
    this.clearAllErrors();

    if (this.isPlayerPlaying) {
      // if video was playing before disconnection - start playing it again
      await this.play();
    }
  }

  /**
   * On window offline event
   *
   * Video should be paused and HLS instance should be destroyed to free up resources
   * Note: this.isPlaying flag should not be changed here in order to detect whether video was playing before disconnection
   * and resume playing in onGoOnline event handler
   */
  onGoOffline() {
    log.warning('connection is offline');
    actions.common.setIsOffline(this.$store, true);
    actions.player.setWasPlayingBeforeOffline(this.$store, this.isPlayerPlaying);
    actions.player.stopLoading(this.$store);
    actions.player.setError(this.$store, PLAYER_ERROR_CODES.COMMON, this.getNetworkErrorMessage());

    this.refVideo?.pause();
    this.destroyHls();
  }

  async onPlay() {
    log.info('$event - play');
    await this.play();
  }

  async onPause() {
    log.info('$event - pause');
    await this.pause();
  }

  async reloadStream() {
    log.info('reloadStream', { isPlayerPlaying: this.isPlayerPlaying });
    this.$store.player.video.wasLanguageChanged = false;
    if (this.isPlayerPlaying) {
      await this.play();
    } else {
      await this.pause();
    }
  }

  getNetworkErrorMessage(isError404 = false) {
    return [
      this.getTranslation('network_error_1'),
      this.getTranslation(isError404 ? 'stream' : 'playlist'),
      this.getTranslation('network_error_2'),
    ].join(' ');
  }

  async loadPlaybackInfoDetails(channel: TChannelEnhanced) {
    await actions.tvChannels.loadPlaybackInfoDetails(this.$store, channel);
  }

  togglePlayPause() {
    if (this.isPlayerPlaying) {
      actions.player.setIsLive(this.$store, false);
      this.pause();

      if (this.isDvrRestricted) {
        actions.player.setAlert(this.$store, this.dvrRestrictionMessage);
      }

      if (this.isDvrDisabled) {
        actions.player.setAlert(this.$store, this.getTranslation('dvr_disabled_error'));
        this.gaEvent({ category: 'player_controls', action: 'Stop', control_type: 'mouse' });
      }
    } else {
      this.play();
    }
    this.gaEvent({
      category: 'player_controls',
      action: this.isPlayerPlaying ? 'Pause' : 'Play',
      control_type: 'mouse',
      channel_name: videoDataSelector(this.$store).channelId,
    });
  }

  async play() {
    this.showPoster = false;

    if (!this.refVideo) {
      return;
    }

    try {
      // since stream is changing on almost every this.play() call and as it is recommended in hls.js docs - we should re-initialize hls on stream change to release hls resources
      if (this.useHls) {
        // when hls enabled - stream will be attached once DOM will be ready on MEDIA_ATTACHED event
        await this.initHls();
      } else {
        // for non-hls version we attach stream to DOM video element straight ahead
        await this.refVideo.pause();
        this.attachUrl();
      }

      if (!this.hasPlayerError) {
        actions.player.startLoading(this.$store);
      }

      actions.player.actualizeStartTime(this.$store);
      log.info('start playing...');

      try {
        await this.refVideo?.play(); // stream is loading only when play actually has been started
        log.info('play');
        actions.player.setIsPlaying(this.$store, true);
      } catch (err) {
        // to avoid: "the play() request was interrupted..."
        log.error('play:', err);
        actions.player.setIsPlaying(this.$store, false);
      }

      if (this.isPlayerMinimized) {
        actions.player.expandPlayer(this.$store);
        actions.player.lockScroll(this.$store);
      }

      actions.player.stopLoading(this.$store);
      this.isVideoActuallyPlaying = true;
      actions.player.hideOverlay(this.$store);
    } catch (err) {
      log.error(err);
      actions.player.setError(
        this.$store,
        PLAYER_ERROR_CODES.COMMON as TPlayerErrorCodes,
        this.getTranslation('something_went_wrong')
      );
      this.showPoster = false;
    } finally {
      actions.player.stopLoading(this.$store);
    }
  }

  async pause() {
    if (!this.refVideo) {
      return;
    }

    this.refVideo.pause();
    actions.player.setIsPlaying(this.$store, false);
    this.isVideoActuallyPlaying = false;
    actions.player.stopLoading(this.$store);

    if (this.hls) {
      this.hls.stopLoad();
    }

    log.info('pause');
    if (!this.hasPlayerError) {
      await this.savePause();
    }
    this.sendStatistics();
  }

  resetPlayer() {
    this.clearAllErrors();
    actions.tvCurrentChannel.reset(this.$store);
    actions.player.stopLoading(this.$store);
    actions.player.setLoaded(this.$store, false);
    actions.player.setReady(this.$store, false);
    actions.player.setIsPlaying(this.$store, false);
    actions.player.hidePlayer(this.$store);
    actions.player.setOverlayVisibility(this.$store, false);
    actions.player.releaseOverlay(this.$store);
    actions.player.setPushHistoryStateForChannel(this.$store, false);
    actions.common.unlockBodyScroll(this.$store);
    this.$store.player.video.fps50 = false;
    this.$store.player.video.hasFps50 = false;
    this.isVideoActuallyPlaying = false;
  }

  async closePlayer() {
    if (this.refVideo) {
      this.refVideo.src = '';
    }

    await this.pause();

    this.destroyHls();

    this.resetPlayer();

    if (this.$route.name?.includes('channels')) {
      const url = `/channels/${this.pageRoute}`;
      history.pushState({}, '', makePath(url));
      actions.seo.setMetaTitle(this.$store, this.getTranslation(`tv_${this.pageRoute}_meta_title`));
    }
  }

  tryFocusOnPlayerContainer() {
    if (typeof this.refOverlay?.focus === 'function') {
      this.refOverlay.focus();
    }
  }

  closeNotificationWithDetails() {
    actions.common.setShowNotificationWithDetails(this.$store, false);
  }

  setMetaTitleForPlayingChannel() {
    actions.seo.setMetaTitle(
      this.$store,
      this.getTranslation('channel_watch_meta_title').replace(
        /%channelName%/g,
        getChannelTitle(channelByIdSelector(this.$store, this.channelId || ''))
      )
    );
  }

  showPlayerNetworkError() {
    actions.player.setError(
      this.$store,
      PLAYER_ERROR_CODES.INTERNAL,
      this.getTranslation('tv_network_error')
    );
    this.refVideo?.pause();
    this.destroyHls();
  }
}
</script>

<style lang="scss">
@import 'player';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-tv {
  .timing {
    @include mobile {
      color: var(--c-dark-font-secondary);
    }
  }

  &.vitrina-tv {
    position: relative;
    top: -64px;

    @include mobile {
      top: 0;
    }

    .vitrina-iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  &.minimized {
    &::v-deep .player-progress-programs {
      max-width: calc(100% - 340px);

      @include mobile-and-tablet {
        max-width: calc(100% - 234px);
      }
    }
  }

  &::v-deep .player-progress-programs {
    display: flex;

    @include mobile {
      display: none;
    }
  }

  .poster {
    position: absolute;
    top: 0;
    z-index: var(--z-1);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.fullscreen {
      background-size: contain;
    }
  }
}
</style>
