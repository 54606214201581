<template>
  <label
    class="button-switch player-settings-button flex-center-vertical body2"
    :for="uuid"
    tabindex="0"
    @keyup.enter="onChange"
  >
    <span class="left" v-html="title" />
    <input :id="uuid" ref="checkbox" type="checkbox" :checked="value" hidden @change="onChange" />
    <span class="right flex-center-vertical container">
      <span class="circle" />
    </span>
  </label>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import { v4 as uuidV4 } from 'uuid';

@Component({
  components: { IconSVG },
})
export default class PlayerSettingsButtonSwitch extends Vue {
  uuid = uuidV4();

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  value!: boolean;

  @Ref('checkbox')
  checkbox?: HTMLInputElement;

  onChange(event: { type: string; target: { checked: any } }) {
    if (event.type === 'keyup' && this.checkbox?.checked) {
      this.checkbox.checked = !this.checkbox.checked;
    }
    this.$emit('input', event.type === 'keyup' ? this.checkbox?.checked : event.target.checked);
  }
}
</script>

<style lang="scss">
@import 'player-settings-button';
</style>

<style lang="scss" scoped>
.button-switch {
  cursor: pointer;

  .container {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 14px;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-radius: 100px;
    transition: border-color 0.2s var(--ease-in-out);

    .circle {
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      margin-top: -3px;
      background-color: var(--c-dark-font-secondary);
      border-radius: 50%;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
      transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
      transform: translateX(1px);
    }
  }

  input:checked + .container {
    border-color: var(--c-dark-contrast);

    .circle {
      background-color: var(--c-light-brand);
      transform: translateX(9px);
    }
  }
}
</style>
