<template>
  <button
    class="button-section player-settings-button flex-center-vertical body2"
    type="button"
    @click="$emit('click')"
  >
    <span class="left" v-html="title" />
    <span class="right flex-center-vertical">
      <span v-html="value" />
      <IconSVG :svg="IconAngleRight" :size="16" />
    </span>
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleRight from 'src/svg/angle-right.svg';

@Component({
  components: { IconSVG },
})
export default class PlayerSettingsButtonSection extends Vue {
  IconAngleRight = IconAngleRight;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  value!: string;
}
</script>

<style lang="scss">
@import 'player-settings-button';
</style>
