<template>
  <div
    v-if="!isLoading"
    class="clickable-underlay"
    :style="{ cursor: isLoading ? 'default' : isOverlayVisible ? 'auto' : 'none' }"
    @click="clickOnVideo"
  />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import logger from 'src/utils/logger';

const log = logger('clickable-underlay');

@Component
export default class ClickableUnderlay extends SequoiaComponent {
  doubleClickTimeoutId?: NodeJS.Timeout;
  doubleClickTimeoutMs = 400;

  get isLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isOverlayVisible() {
    return selectors.player.isOverlayVisibleSelector(this.$store);
  }

  get isSettingsMenuVisible() {
    return selectors.player.isSettingsMenuVisibleSelector(this.$store);
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get isPlayerPlaying() {
    return selectors.player.isPlayingSelector(this.$store);
  }

  get dvrRestrictionMessage() {
    return selectors.tvCurrentChannel.dvrRestrictionMessageSelector(this.$store);
  }

  clearHideOverlayTimeout() {
    if (this.doubleClickTimeoutId) {
      clearTimeout(this.doubleClickTimeoutId);
      this.doubleClickTimeoutId = undefined;
    }
  }

  isTouchDevice() {
    return (
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    );
  }

  clickOnVideo() {
    if (this.isSettingsMenuVisible) {
      actions.player.hideSettingsMenu(this.$store);
      return false;
    }

    if (this.doubleClickTimeoutId) {
      this.clearHideOverlayTimeout();
      this.doubleClickOnVideo();
    } else {
      this.doubleClickTimeoutId = setTimeout(() => {
        this.clearHideOverlayTimeout();

        if (this.isTouchDevice() && this.$store.player.touchedTimes === 0) {
          this.$store.player.touchedTimes++;
          return;
        }

        if (this.isPlayerPlaying) {
          this.gaEvent({ category: 'player_controls', action: 'pause', control_type: 'mouse' });
          this.$emit('pause');

          if (this.playerType === 'tv') {
            if (this.isDvrRestricted) {
              actions.player.setAlert(this.$store, this.dvrRestrictionMessage);
            }

            if (this.isDvrDisabled) {
              actions.player.setAlert(this.$store, this.getTranslation('dvr_disabled_error'));
            }
          }
        } else {
          this.gaEvent({ category: 'player_controls', action: 'play', control_type: 'mouse' });
          this.$emit('play');
        }
      }, this.doubleClickTimeoutMs);
    }
  }

  doubleClickOnVideo() {
    this.$emit('toggleFullscreen');
    log.info('on double-click somewhere on video');
  }
}
</script>
