<template>
  <PlayerButton @click="$emit('toggleFullscreen')">
    <IconSVG v-if="isFullscreen" :svg="IconFullscreenOff" />
    <IconSVG v-else :svg="IconFullscreenOn" />
  </PlayerButton>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconFullscreenOn from 'src/svg/player/fullscreen-on.svg';
import IconFullscreenOff from 'src/svg/player/fullscreen-off.svg';

@Component({
  components: {
    PlayerButton,
    IconSVG,
  },
})
export default class PlayerButtonFullscreen extends Vue {
  IconFullscreenOn = IconFullscreenOn;
  IconFullscreenOff = IconFullscreenOff;

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }
}
</script>
