<template>
  <button
    v-if="title"
    class="button-selector player-settings-button flex-center-vertical body2"
    type="button"
    @click="$emit('click')"
  >
    <IconSVG v-if="active" class="left" :svg="IconSubmit" :size="16" />
    <span class="right" v-html="title" />
  </button>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconSubmit from 'src/svg/submit.svg';

@Component({
  components: { IconSVG },
})
export default class PlayerSettingsButtonSelector extends Vue {
  IconSubmit = IconSubmit;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true, default: false })
  active!: boolean;
}
</script>

<style lang="scss">
@import 'player-settings-button';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button-selector {
  &:hover {
    .left {
      color: var(--c-dark-brand);
    }
  }

  .left {
    margin-right: -16px;
    color: var(--c-dark-brand);
  }

  .right {
    margin-left: 24px;
    color: var(--c-dark-font-primary);
  }
}
</style>
