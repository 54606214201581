<template>
  <transition appear name="channels-transition">
    <div
      ref="channels"
      class="channels"
      :class="{ 'epg-open': isEpgOpen && !isChannelInfoVisible }"
    >
      <div ref="scrollWrap" class="scroll-wrap">
        <template v-if="channels.length > 0">
          <div
            v-for="channel in channels"
            :key="channel.id"
            :ref="selectedChannelId === channel.id ? 'activeChannel' : false"
            class="channel"
            :class="{
              selected: selectedChannelId === channel.id,
              active: currentChannelId === channel.id,
            }"
          >
            <div class="logo button-with-hover-overlay">
              <LoaderSpinner
                v-if="openChannelEpgIsPending && selectedChannelId === channel.id"
                class="loader"
              />
              <LazyImage
                v-if="getChannelLogo(channel)"
                :src="getChannelLogo(channel)"
                :width="136"
                :height="78"
                :with-icon="true"
                theme-forced="dark"
                @click="
                  clickOnChannelLogo(channel);
                  gaEvent({
                    category: 'player_controls',
                    action: 'Запуск канала (полноэкранный плеер)',
                  });
                "
              />
              <button
                type="button"
                class="overlay-with-icon"
                :class="{ disabled: isEpgOpen }"
                @click="selectChannel(channel)"
              >
                <IconSVG v-if="!isEpgOpen" :svg="IconPlay" />
              </button>
              <ButtonBookmark
                v-if="!isAnonymous"
                size="small"
                :active="isChannelInFavorites(channel)"
                @click.native="toggleChannelFavorite(channel)"
                @mouseleave.native="$event.target.blur()"
              />
            </div>

            <button
              v-if="!isEpgOpen && isChannelInfoVisible"
              type="button"
              class="channel-epg color-dark-font-primary"
              :data-channelId="channel.id"
              @click="openChannelEpg(channel)"
            >
              <span class="channel-title h5">
                <span class="number" v-text="getChannelNumber(channel)" />
                <span class="name" v-text="getChannelTitle(channel)" />
                <IconSVG v-if="isChannelLocked(channel)" :svg="IconLockOn" :size="16" />
              </span>

              <span v-if="getCurrentProgram(channel.id)" class="program">
                <span
                  class="program-start-time"
                  v-text="getCurrentProgram(channel.id).startTimeHM"
                />
                <span class="program-title" v-text="getCurrentProgram(channel.id).title" />
              </span>
            </button>
          </div>
        </template>

        <div
          v-if="channels.length <= 0 && !isCurrentGenreFavorite"
          class="not-found pr-16"
          v-html="getTranslation('channels_were_not_found')"
        />
        <div
          v-else-if="favoriteChannelsArray.length <= 0 && isCurrentGenreFavorite"
          class="not-found pr-16"
        >
          <IconSVG class="mb-8" :svg="IconBookmarkOff" :size="48" />
          <h6 class="mb-8" v-html="getTranslation('favorites_empty')" />
          <div class="mb-8" v-html="getTranslation('favorites_guide')" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import Vue from 'vue';
import { Prop, Ref } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import { TChannelEnhanced } from 'src/api/channels/types';
import { EPG_TOP_MARGIN, TV_SPECIAL_GENRES } from 'src/constants';
import { DateTime } from 'src/utils/time/date-time';
import IconSVG from 'src/components/IconSVG.vue';
import IconLockOn from 'src/svg/lock-on.svg';
import IconPlay from 'src/svg/player/play.svg';
import IconBookmarkOff from 'src/svg/bookmark-off.svg';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import {
  getChannelIsLocked,
  getChannelTitle,
  getChannelLogo,
  getChannelNumber,
} from 'src/utils/channel';
import ButtonBookmark from 'src/components/ui/buttons/ButtonBookmark.vue';
import LazyImage from 'src/components/LazyImage.vue';
import logger from 'src/utils/logger';

const log = logger('player-fullscreen-channels');

@Component({
  components: {
    IconSVG,
    LoaderSpinner,
    ButtonBookmark,
    LazyImage,
  },
})
export default class PlayerFullscreenChannels extends SequoiaComponent {
  IconLockOn = IconLockOn;
  IconPlay = IconPlay;
  IconBookmarkOff = IconBookmarkOff;

  todayEpochDay = DateTime.toEpochDay(new Date());
  openChannelEpgIsPending = false;
  shouldScrollOnNextUpdate = true;

  @Prop({ required: true })
  isEpgOpen!: boolean;

  @Prop({ required: true })
  isChannelInfoVisible!: boolean;

  @Prop({ required: true })
  selectedChannelId!: string;

  @Ref('channels')
  refChannels?: HTMLDivElement;

  @Ref('scrollWrap')
  refScrollWrap?: HTMLDivElement;

  @Ref('activeChannel')
  refActiveChannel?: HTMLDivElement;

  get channels() {
    return Object.values(selectors.tvChannels.filteredChannelsSelector(this.$store));
  }

  get favoriteChannels() {
    return selectors.tvChannels.favoritesSelector(this.$store);
  }

  get favoriteChannelsArray() {
    return selectors.tvChannels.favoritesArraySelector(this.$store);
  }

  get currentChannelId() {
    return selectors.tvCurrentChannel.currentChannelIdSelector(this.$store);
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get currentGenre() {
    return selectors.tvChannels.currentGenreSelector(this.$store);
  }

  get isCurrentGenreFavorite() {
    return this.currentGenre === TV_SPECIAL_GENRES.favourite;
  }

  get playingTimeMs() {
    return selectors.player.playingTimeMsSelector(this.$store);
  }

  mounted() {
    this.scrollToActiveChannel();
  }

  updated() {
    if (this.shouldScrollOnNextUpdate) {
      if (this.refActiveChannel) {
        this.scrollToActiveChannel();
      }
    }
  }

  scrollToActiveChannel() {
    if (!this.refScrollWrap || !this.refChannels) {
      return false;
    }
    const activeChannel = this.refChannels.querySelector('.active');

    if (activeChannel) {
      this.refScrollWrap.scrollTop = activeChannel?.getBoundingClientRect().top - EPG_TOP_MARGIN;
    }
  }

  isChannelLocked(channel: TChannelEnhanced) {
    return selectors.tvChannels.isChannelLockedSelector(this.$store, channel);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getChannelNumber(channel: TChannelEnhanced) {
    return getChannelNumber(channel);
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  getChannelIsLocked(channel: TChannelEnhanced) {
    return getChannelIsLocked(channel);
  }

  getCurrentProgram(channelId: string) {
    return selectors.tvChannels.currentProgramSelector(this.$store, channelId);
  }

  setPickedEpochDay(day: number) {
    actions.player.setPickedEpochDay(this.$store, day);
  }

  isChannelInFavorites(channel: TChannelEnhanced) {
    return !!this.favoriteChannels[channel?.id];
  }

  async toggleChannelFavorite(channel: TChannelEnhanced) {
    if (channel) {
      actions.metrics.setGaParams(this.$store, {
        category: 'player_controls',
        control_type: 'mouse',
        fullscreen: 'true',
      });
      this.shouldScrollOnNextUpdate = false;
      await actions.tvChannels.toggleChannelFavorite(this.$store, channel.id);
    }

    const { favouriteChannels } = await api.channels.getRecentAndFavChannels();
    Vue.set(this.$store.tvChannels, 'favorites', favouriteChannels || []);
  }

  clickOnChannelLogo(channel: TChannelEnhanced) {
    if (!this.isEpgOpen) {
      this.shouldScrollOnNextUpdate = false;
      this.startChannel(channel.id);
    } else {
      this.shouldScrollOnNextUpdate = false;
      this.$emit('setSelectedChannelId', channel.id);
      this.openChannelEpg(channel);
    }
  }

  async openChannelEpg(channel: TChannelEnhanced) {
    this.openChannelEpgIsPending = true;
    this.shouldScrollOnNextUpdate = false;
    this.$emit('setIsProgramInfoOpen', false);
    this.$emit('setSelectedChannel', channel);
    this.$emit('setSelectedChannelId', channel.id);

    try {
      await actions.tvEpg.loadEpgForChannel(this.$store, channel.id);
    } catch (err) {
      log.error(err);
    }

    if (channel.id === this.currentChannelId) {
      this.setPickedEpochDay(DateTime.toEpochDay(new Date(this.playingTimeMs)));
    } else {
      this.setPickedEpochDay(this.todayEpochDay);
    }

    this.$emit('setEpgChannel', channel);
    this.$emit('setIsChannelInfoVisible', false);
    this.$emit('setIsEpgOpen', true);
    this.openChannelEpgIsPending = false;
  }

  selectChannel(channel: TChannelEnhanced) {
    if (
      this.$store.QS.wasChannelSelected &&
      this.currentChannel &&
      this.getChannelIsLocked(this.currentChannel)
    ) {
      actions.QS.handleForTv(this.$store, channel);
      log.info('quickSubscribe.handleForTv', this.currentChannel);
    }
    this.startChannel(channel.id);
    this.gaEvent({
      category: 'player_controls',
      action: 'Запуск канала (полноэкранный плеер)',
      channel_name: channel.id,
    });
  }

  async startChannel(channelId: string) {
    this.$emit('setIsEpgOpen', false);
    actions.player.hideFullscreenMenu(this.$store);
    if (this.currentChannelId !== channelId) {
      await actions.tvChannels.selectChannel(this.$store, channelId, this.$events);
    } else {
      actions.player.goLive(this.$store, this.$events);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/bookmark-indicator';

.channels {
  position: relative;
  max-width: 656px;
  height: 100%;
  padding-right: 8px;
  margin-top: 0;

  @include devices-with-hover {
    &:hover {
      .channel {
        opacity: 1;
      }
    }
  }

  @media (max-width: #{$desktop-s-max}) {
    max-width: 480px;
  }

  .scroll-wrap {
    width: 100%;
    height: 100%;

    @media (max-width: #{$desktop-s-max}) {
      max-width: 480px;
    }
  }

  .not-found {
    display: flex;
    width: 100%;
    height: 100%;
    color: var(--c-dark-font-secondary);
    pointer-events: none;
  }

  .channel {
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 6px 5px;
    margin-bottom: 8px;
    color: var(--c-dark-font-primary);
    border-radius: 8px;
    opacity: 0.5;

    &.selected {
      opacity: 1;
    }

    &.active {
      font-weight: bold;

      .channel-title {
        color: var(--c-light-brand);
      }
    }

    @include devices-with-hover {
      &:hover {
        background-color: var(--alpha-dark-3);
      }
    }

    .logo {
      position: relative;
      flex-shrink: 0;
      width: 136px;
      height: 100%;
      cursor: pointer;
      aspect-ratio: 16 / 9;

      @include devices-with-hover {
        &:hover {
          @include bookmark-indicator;
        }
      }

      .bookmark {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: var(--z-4);
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }

      .loader {
        position: absolute;
        right: 0;
        left: 0;
        width: 48px;
        height: 48px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .channel-epg {
      width: 100%;
      margin-left: 16px;

      .name {
        overflow: hidden;
        overflow-wrap: break-word;
      }

      .channel-title {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 4px;

        .number {
          padding-right: 6px;
          color: var(--c-dark-font-secondary);
        }

        .icon {
          margin-left: 4px;
          color: var(--alpha-dark-6);
        }
      }

      .program {
        display: flex;
      }

      .program-start-time {
        margin-right: 8px;
      }

      .program-title {
        text-align: left;
      }
    }
  }
}

// --------------------------------------------
// Animation
// --------------------------------------------
.channels-transition-enter-active,
.channels-transition-leave-active {
  transition: transform 0.25s, opacity 0.25s;
}

.channels-transition-enter,
.channels-transition-leave-to {
  opacity: 0;
  transform: translateX(-64px);
}

.channels-transition-enter-to,
.channels-transition-leave {
  transform: translateX(0);
}
</style>
