<template>
  <transition name="fade-ease-out-fast" mode="out-in">
    <div class="smoking-warning">
      <div class="smoking-warning-age mb-12">18+</div>
      <div class="smoking-warning-text" v-html="getTranslation('smoking_warning_text')" />
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class SmokingWarning extends SequoiaComponent {}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/media-queries';

.smoking-warning {
  position: absolute;
  z-index: var(--z-7);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 608px;
  padding: 48px 64px 0;

  @include tablet {
    max-width: 448px;
  }

  .smoking-warning-age {
    @include heading4;

    padding: 3px 7px;
    border: 1px solid #ebebeb;
    border-radius: 8px;

    @include tablet {
      @include heading5;
    }
  }

  .smoking-warning-text {
    @include body1;

    width: 100%;

    @include tablet {
      @include body2;
    }
  }
}
</style>
