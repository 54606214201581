<template>
  <div
    ref="container"
    class="epg-info-full"
    @mouseenter="freezeOverlay()"
    @mouseleave="releaseOverlay()"
  >
    <PlayerButton class="close" @click="hideEpgInfo">
      <IconSVG :svg="IconAngleRight" />
    </PlayerButton>

    <div
      v-for="program of programs"
      :key="program.id"
      class="program"
      :class="{
        active: selectedProgramId === program.id,
        unavailable: isDvrDisabledForChannel || program.startMs > new Date(Date.now()),
      }"
      @click="selectProgram(program.id)"
    >
      <div class="inner">
        <div class="time" v-text="program.startTimeHM" />

        <div class="info">
          <div class="title h5" v-text="program.title" />
          <div v-if="selectedProgramId === program.id" class="details">
            <button class="thumbnail" type="button" @click="startPlayingProgram(program)">
              <img v-if="program.image" :src="getProgramImage(program.image)" alt="" />
              <span class="thumbnail-overlay">
                <span class="button-play">
                  <IconSVG :svg="IconPlay" />
                </span>
              </span>
            </button>

            <TextWithTruncation
              v-if="program.description"
              :source-text="program.description"
              theme-forced="dark"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleRight from 'src/svg/angle-right.svg';
import IconPlay from 'src/svg/player/play.svg';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import ButtonCircleConnected from 'src/components/ui/buttons/ButtonCircleConnected.vue';
import { getDayProgramsByChannelId } from 'src/utils/epg';
import TextWithTruncation from 'src/components/ui/TextWithTruncation.vue';
import { TProgramEnhanced } from 'src/api/channels/types';
import { getResizedImageUrl } from 'src/utils/images';
import logger from 'src/utils/logger';

const log = logger('player-epg-info-full');

@Component({
  components: {
    ButtonCircleConnected,
    PlayerButton,
    TextWithTruncation,
    IconSVG,
  },
})
export default class PlayerEpgInfoFull extends SequoiaComponent {
  IconAngleRight = IconAngleRight;
  IconPlay = IconPlay;

  selectedProgramId: string | null = null;

  @Ref('container')
  readonly refContainer!: HTMLDivElement;

  @Watch('currentProgramId')
  onCurrentProgramIdChange() {
    this.selectedProgramId = this.currentProgramId;
    this.$nextTick(() => this.scrollToActive());
  }

  get currentChannel() {
    return selectors.tvCurrentChannel.currentChannelSelector(this.$store);
  }

  get currentChannelId() {
    return this.currentChannel?.id;
  }

  get programs() {
    if (!this.currentChannelId) {
      return [];
    }
    return getDayProgramsByChannelId(
      this.$store,
      this.currentChannelId,
      selectors.player.pickedEpochDaySelector(this.$store)
    );
  }

  get currentProgramId() {
    return selectors.tvEpg.currentProgramSelector(this.$store)?.id || null;
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get isDvrDisabledForChannel() {
    if (!this.currentChannelId) {
      return false;
    }
    return selectors.tvEpg.isDvrDisabledSelector(this.$store, this.currentChannel);
  }

  mounted() {
    this.selectedProgramId = this.currentProgramId;
    this.$nextTick(() => this.scrollToActive());
  }

  freezeOverlay() {
    actions.player.freezeOverlay(this.$store);
  }

  releaseOverlay() {
    actions.player.releaseOverlay(this.$store);
  }

  hideEpgInfo() {
    actions.player.hideEpgInfo(this.$store);
  }

  scrollToActive(smooth = false) {
    const active = this.refContainer?.querySelector('.active') as HTMLDivElement;
    if (active) {
      const offsetY = -100;
      setTimeout(() => {
        this.refContainer.scrollTo({
          top: active.offsetTop + offsetY,
          behavior: smooth ? 'smooth' : 'auto',
        });
      }, 100);
    }
  }

  setPickedEpochDay(day: number) {
    actions.player.setPickedEpochDay(this.$store, day);
  }

  selectProgram(programId: string) {
    this.selectedProgramId = programId;
    this.$nextTick(() => this.scrollToActive(true));
  }

  async startPlayingProgram(program: TProgramEnhanced) {
    this.hideEpgInfo();

    if (this.isDvrRestricted) {
      log.info('DVR is restricted for current channel');
    }

    actions.tvCurrentChannel.selectProgram(this.$store, program);
  }

  getProgramImage(src: string) {
    return getResizedImageUrl(src, 315, 158);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.epg-info-full {
  position: fixed;
  top: 50px;
  right: 32px;
  z-index: var(--z-2);
  max-width: 440px;
  max-height: calc(100vh - 320px);
  padding-top: 50px;
  padding-bottom: 70px;
  //noinspection CssInvalidPropertyValue
  overflow: -moz-scrollbars-none;
  overflow-y: auto;
  color: var(--c-dark-font-primary);
  text-align: left;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .program {
    position: relative;
    padding-top: 16px;
    padding-left: 5px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: opacity 0.5s var(--ease-out);

    @include devices-with-hover {
      &:hover {
        opacity: 1;
      }
    }

    &.unavailable {
      .thumbnail {
        pointer-events: none;
      }
    }

    &.active {
      .time,
      .title {
        opacity: 1;
      }
    }
  }

  .inner {
    display: flex;
    width: 100%;
  }

  .time {
    flex-shrink: 0;
    width: 72px;
    text-align: center;
  }

  .info {
    flex: 1;
    margin-right: 48px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 20px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 2%,
        rgba(212, 205, 205, 1) 33%,
        rgba(214, 207, 207, 1) 66%,
        rgba(255, 255, 255, 0) 100%
      );
      background-color: rgb(255, 255, 255);
    }
  }

  .thumbnail {
    position: relative;
    margin: 20px 0 16px;
    cursor: pointer;
  }

  .thumbnail-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include devices-with-hover {
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0.7;
      }
    }

    &:hover .button-play {
      opacity: 1;
    }

    .button-play {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: var(--c-light-brand-darker);
      border-radius: 100%;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  .close {
    position: fixed;
    top: 150px;
    right: 32px;
    z-index: var(--z-3);
  }
}
</style>
