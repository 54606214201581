<template>
  <div
    v-if="showVolumeSlider"
    ref="container"
    class="volume-slider"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousedown="onMouseDown"
  >
    <div ref="bar" class="bar" :style="{ height: `${barHeight}px` }">
      <div class="highlight" :style="{ height: `${highlightHeight}px` }" />
    </div>
    <div ref="knob" class="knob" :style="{ bottom: `${knobPosition}px` }" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import throttle from 'lodash/throttle';
import { SequoiaComponent } from 'src/mixins';
import { boundMinMax } from 'src/utils/number';

@Component
export default class PlayerVolumeSlider extends SequoiaComponent {
  barHeight = 100;
  isKnobClicked = false;
  isMouseInside = false;
  onMouseMoveThrottle = throttle(this.onMouseMove, 15);
  showVolumeSlider = true;

  @Prop({ required: true })
  volume!: number;

  @Ref('container')
  readonly refContainer!: HTMLDivElement;

  @Ref('bar')
  readonly refBar!: HTMLDivElement;

  get knobPosition() {
    return this.volume * this.barHeight;
  }

  get highlightHeight() {
    return Math.max(this.knobPosition, 0);
  }

  mounted() {
    document.addEventListener('mousedown', this.onMouseDown);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mousemove', this.onMouseMoveThrottle);
    this.showVolumeSlider = !this.isTouchDevice();
  }

  beforeDestroy() {
    document.removeEventListener('mousedown', this.onMouseDown);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.onMouseMoveThrottle);
  }

  isTouchDevice() {
    return (
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    );
  }

  onMouseDown(e: MouseEvent) {
    e.stopPropagation();
    if (!this.refContainer?.contains(e.target as Node)) {
      return;
    }
    this.isKnobClicked = true;
    this.changeVolume(e);
    this.gaEvent({
      category: 'player_controls',
      action: 'Изменить громкость',
      control_type: 'mouse',
    });
  }

  onMouseUp() {
    this.isKnobClicked = false;
    if (!this.isMouseInside) {
      this.$emit('onmouseleave');
    }
  }

  onMouseMove(e: MouseEvent) {
    if (!this.isKnobClicked) {
      return;
    }
    this.changeVolume(e);
  }

  onMouseEnter() {
    this.isMouseInside = true;
    this.$emit('onmouseenter');
  }

  onMouseLeave() {
    this.isMouseInside = false;
    if (!this.isKnobClicked) {
      this.$emit('onmouseleave');
    }
  }

  changeVolume(e: MouseEvent) {
    const mouseY = e.y;
    this.$emit('changeVolume', this.calculateVolume(mouseY));
  }

  calculateVolume(mouseY: number) {
    const barMouseY = mouseY - this.getBarPositionY();
    return boundMinMax(0, this.barHeight - barMouseY, this.barHeight) / this.barHeight;
  }

  getBarPositionY() {
    const rect = this.refBar?.getBoundingClientRect();
    return rect?.y || rect?.top || 0;
  }
}
</script>

<style lang="scss" scoped>
$bar-border-radius: 2px;

.volume-slider {
  width: 24px;
  height: 124px;
  cursor: pointer;
  user-select: none;
  background-color: rgba(var(--c-dark-400-rgb), 0.8);
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

  .bar {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: $bar-border-radius;
  }

  .knob {
    position: absolute;
    left: 6px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-color: var(--c-light-100);
    border: 0;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
  }

  .highlight {
    position: absolute;
    bottom: 0;
    width: 4px;
    background-color: var(--c-light-100);
    border-radius: $bar-border-radius;
  }
}
</style>
