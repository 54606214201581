<template>
  <transition name="fade-ease-out-slow">
    <PlayerButtonsGroup
      v-show="(!isPlayerMinimized || (isPlayerMinimized && isFullscreen)) && !hasError"
      class="player-controls-mobile"
    >
      <!-- previous episode -->
      <PlayerButton
        v-if="nextEpisodeTitle"
        :class="{ disabled: isDisabled }"
        class="media-previous"
      >
        <IconSVG :svg="IconMediaNext" />
      </PlayerButton>

      <!-- rewind -->
      <!--suppress VueUnrecognizedDirective -->
      <PlayerButton
        v-if="isTv"
        v-show="!isDvrDisabled"
        v-mouseup-outside="stopRewind"
        :disabled="isDvrRestricted || isPlayerLoading"
        @mousedown.prevent="startRewind"
        @mouseup="stopRewind"
        @click="gaEvent({ category: 'player_controls', action: 'Перемотка назад на мобильном' })"
      >
        <IconSVG :svg="IconRewind10" />
      </PlayerButton>

      <PlayPauseStop :is-disabled="isDisabled" @click="togglePlayPause" />

      <!-- fast-forward -->
      <!--suppress VueUnrecognizedDirective -->
      <PlayerButton
        v-if="isTv"
        v-show="!isDvrDisabled"
        v-mouseup-outside="stopFastForward"
        :disabled="isLive || isDvrRestricted || isPlayerLoading"
        @mousedown.prevent="startFastForward"
        @mouseup="stopFastForward"
        @click="gaEvent({ category: 'player_controls', action: 'Перемотка вперёд на мобильном' })"
      >
        <IconSVG :svg="IconForward10" />
      </PlayerButton>

      <!-- next episode -->
      <PlayerButton
        v-if="nextEpisodeTitle"
        :class="{ disabled: isDisabled }"
        class="media-next"
        @click="playNextEpisode"
      >
        <IconSVG :svg="IconMediaNext" />
      </PlayerButton>
    </PlayerButtonsGroup>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EVENTS } from 'src/constants';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { convertToSeconds } from 'src/utils/time/convert-time';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';
import IconForward10 from 'src/svg/player/forward-10.svg';
import IconRewind10 from 'src/svg/player/rewind-10.svg';
import IconMediaNext from 'src/svg/player/media-next.svg';
import PlayerVolume from 'src/components/player/parts/common/player-controls/player-volume/PlayerVolume.vue';
import PlayerButton from 'src/components/player/parts/common/player-controls/PlayerButton.vue';
import PlayerButtonsGroup from 'src/components/player/parts/common/player-controls/PlayerButtonsGroup.vue';
import PlayerSettings from 'src/components/player/parts/common/player-controls/player-settings/PlayerSettings.vue';
import PlayerControlLive from 'src/components/player/parts/tv/PlayerControlLive.vue';
import PlayerControlBackToPause from 'src/components/player/parts/tv/PlayerControlBackToPause.vue';
import PlayerAlert from 'src/components/player/parts/tv/PlayerAlert.vue';
import PlayPauseStop from 'src/components/player/parts/common/player-controls/PlayPauseStop.vue';
import PlayerButtonFullscreen from 'src/components/player/parts/common/player-controls/PlayerButtonFullscreen.vue';
import TimingBlock from 'src/components/player/parts/common/TimingBlock.vue';
import ButtonScrollToCatalog from 'src/components/player/parts/common/ButtonScrollToCatalog.vue';
import PlayerVodArchive from 'src/components/player/PlayerVodArchive';

@Component({
  components: {
    ButtonScrollToCatalog,
    PlayerButtonFullscreen,
    PlayerVolume,
    PlayerButton,
    PlayerButtonsGroup,
    PlayerControlLive,
    PlayerControlBackToPause,
    PlayerAlert,
    PlayerSettings,
    PlayPauseStop,
    TimingBlock,
    IconSVG,
  },
})
export default class PlayerControlsMobile extends PlayerVodArchive {
  IconPlay = IconPlay;
  IconForward10 = IconForward10;
  IconRewind10 = IconRewind10;
  IconMediaNext = IconMediaNext;

  @Prop()
  isLanguageError!: boolean;

  @Prop({ default: false })
  isDisabled!: boolean;

  @Prop({ default: false })
  hasError!: boolean;

  get playerType() {
    return selectors.player.typeSelector(this.$store);
  }

  get isTv() {
    return this.playerType === 'tv';
  }

  get isPlayerLoading() {
    return selectors.player.isLoadingSelector(this.$store);
  }

  get isPlayerMinimized() {
    return selectors.player.isPlayerMinimizedSelector(this.$store);
  }

  get isFullscreen() {
    return selectors.player.isFullscreenSelector(this.$store);
  }

  get alert() {
    return selectors.player.alertSelector(this.$store);
  }

  get isLive() {
    return selectors.player.isLiveSelector(this.$store);
  }

  get isDvrDisabled() {
    return selectors.tvEpg.isDvrDisabledSelector(this.$store);
  }

  get isDvrRestricted() {
    return selectors.tvEpg.isDvrRestrictedSelector(this.$store);
  }

  get isPlayerPlaying() {
    return selectors.player.isPlayingSelector(this.$store);
  }

  get currentTimeForVod() {
    return selectors.vod.playingCurrentTimeSelector(this.$store);
  }

  get currentTimeForArchive() {
    return convertToSeconds(selectors.vod.playingCurrentTimeSelector(this.$store), 'millisecond');
  }

  get currentTime() {
    return this.playerType === 'vod' ? this.currentTimeForVod : this.currentTimeForArchive;
  }

  get videoData() {
    return selectors.vod.videoDataSelector(this.$store);
  }

  get durationForVod() {
    return this.videoData.duration;
  }

  get durationForArchive() {
    return convertToSeconds(
      selectors.archive.timelineDurationMsSelector(this.$store),
      'millisecond'
    );
  }

  get duration() {
    return this.playerType === 'vod' ? this.durationForVod : this.durationForArchive;
  }

  togglePlayPause() {
    if (this.isTv) {
      if (this.isPlayerPlaying) {
        actions.player.setIsLive(this.$store, false);
        this.$emit('pause');

        if (this.isDvrRestricted) {
          actions.player.setAlert(
            this.$store,
            selectors.tvCurrentChannel.dvrRestrictionMessageSelector(this.$store)
          );
        }

        if (this.isDvrDisabled) {
          actions.player.setAlert(this.$store, this.getTranslation('dvr_disabled_error'));
          this.gaEvent({ category: 'player_controls', action: 'timeline_stop_button' });
        }
        this.gaEvent({ category: 'player_controls', action: 'timeline_pause_button' });
      } else {
        this.$emit('play');
        this.gaEvent({ category: 'player_controls', action: 'timeline_play_button' });
      }
    } else {
      this.$emit('togglePlayPause');
    }
  }

  startFastForward(e: MouseEvent) {
    this.$events.emit(EVENTS.player.fastForward.start, e);
  }

  stopFastForward() {
    this.$events.emit(EVENTS.player.fastForward.stop);
  }

  startRewind(e: MouseEvent) {
    this.$events.emit(EVENTS.player.rewind.start, e);
  }

  stopRewind() {
    this.$events.emit(EVENTS.player.rewind.stop);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.player-controls-mobile {
  position: relative;
  z-index: var(--z-2);
  display: none;
  align-items: center;
  justify-content: center;

  @include mobile {
    display: flex;
  }

  .player-button {
    position: relative;
    z-index: var(--z-3);
  }

  .media-previous {
    z-index: var(--z-rock-bottom);
    visibility: hidden;
    opacity: 0;
    transform: rotate(180deg);
  }
}
</style>
